import FillButton from "components/Button/FillButton"
import ProductHelper from "helpers/ProductHelper"
import { ProductPriceString } from "./ProductPriceString"
import { useAppContext } from "contexts/AppContext"
import {  CATEGORY_NAME, MODEL_DATA, PLACEHOLDER_IMAGE, RESULT_MAX_PURCHASE_QTY, RESULT_MIN_PURCHASE_QTY, RESULT_PRDT_CATEGORY_ID, RESULT_PRDT_DESC, RESULT_PRDT_IS_QUOTE, RESULT_PRDT_NAME, RESULT_PRDT_OPTIONS, RESULT_PRDT_PRICE,  RESULT_PRDT_SKU,  RUNTIME_INSTANCE_INSTANCE_ID, STOCK_STATUSES, THEME_PLATFORMS } from "constants/constants"
import QuantityInput from "./QuantityInput"
import { TProductProps } from "./types"
import BorderlessButton from "components/Button/BorderlessButton"
import ProductStockStatus from "./ProductStockStatus"
import BorderedButton from "components/Button/BorderedButton"
import Utils from "Services/Utils"
import ReactSelect from "react-select"
import { useEffect, useState } from "react"
import { isArray } from "lodash"
import { TResultOption, TResultProduct } from "types"
import ProductDescriptionTooltip from "components/ProductDescriptionTooltip"
import CartIcon from "./CartIcon"

const ProductVertical = ({
    category,
    product,
    selected,
    instance,
    showQtyInput,
    quantity,
    maxQty,
    buttonLabel,
    type,
    handleProductClick,
    handleQtyChange,
    showImage
}: TProductProps) => { 
    const {
        addProductToCart,
        removeProductFromCart,
        dataLoading,
        theme,
        setImagePreview,
        platform
    } = useAppContext()

    const [selectedVariant, setSelectedVariant] = useState<TResultProduct|undefined>()

    useEffect(() => {
        if (isArray(product[RESULT_PRDT_OPTIONS]) 
            && product[RESULT_PRDT_OPTIONS]?.length > 0 ){
            setSelectedVariant(product[RESULT_PRDT_OPTIONS][0])
        }
    }, [])
    
    const options = product[RESULT_PRDT_OPTIONS]
        // ?.sort(props.sort ? Utils.sortChoices(true, SORT.SEPERATE_ASC, (item: any) => item[CHOICE_NAME], (item: any) => item[CHOICE_IS_DISABLED]) as any : undefined)
        ?.reduce((acc: {value: string, label: string}[], choice) => {
            acc.push({value: choice[RESULT_PRDT_SKU], label: choice.variantTitle ?? choice[RESULT_PRDT_NAME]})
            return acc
        }
    , [])

    const hasOptions = isArray(product[RESULT_PRDT_OPTIONS]) 
        && product[RESULT_PRDT_OPTIONS]?.length > 1 

    const productImage = ProductHelper.getProductImage(product, selectedVariant?.[RESULT_PRDT_SKU], product[RESULT_PRDT_OPTIONS])
    
    return (
        <div className={`sb3-product-card sb3-product-grid sb3-w-full sb3-bg-white sb3-border sb3-border-solid sb3-p-2 sb3-h-full sb3-flex sb3-flex-col sb3-justify-between sb3-rounded sb3-py-4 ${ platform === THEME_PLATFORMS.BIG_COMMERCE ? "sb3-rounded-[24px] hover:sb3-shadow-lg" : "sb3-bg-white  "} `}>
            {
                showImage !== false && 
                <span className="sb3-product-grid-image-container ">
                    <img 
                    key={product[RESULT_PRDT_SKU]}
                    onClick={() => setImagePreview(!!productImage ? productImage : PLACEHOLDER_IMAGE)}  
                    src={!!productImage ? productImage : PLACEHOLDER_IMAGE} 
                    alt="Product" 
                    className={`sb3-product-grid-image sb3-aspect-square sb3-w-full sb3-max-w-full sb3-object-contain ${platform === THEME_PLATFORMS.BIG_COMMERCE ? 'sb3-rounded-[21px] sb3-overflow-hidden':'sb3-rounded-none' }`}
                    />
                </span>
            }
       
    
        <div className="sb3-product-grid-detals-container sb3-flex sb3-flex-col sb3-space-y-2 sb3-justify-between sb3-flex-grow sb3-h-full sb3-px-1">
            <div className={`sb3-product-grid-primary-details ${Utils.isLinear(theme) && "sb3-text-center"} sb3-flex-grow sb3-space-y-2 sb3-flex sb3-flex-col`}>
    
                {
                    product[RESULT_PRDT_OPTIONS] && hasOptions
                    && <span className="sb3-text-xs sb3-text-gray-extraDark">{product[RESULT_PRDT_OPTIONS].length} options</span>
                }
    
                {
                    !Utils.isShopify() && 
                    <button type="button" className={`sb3-product-title-grid sb3-text-sm sb3-mt-2 sb3-line-clamp-2 sb3-border-0 
                    ${ platform === THEME_PLATFORMS.BIG_COMMERCE ? "sb3-text-sm sb3-font-bold sb3-underline" : "sb3-text-gray-extraDark sb3-product-title-list"}
                    ${(Utils.isMagento() || Utils.isBigCommerce()) ? "sb3-break-words" : ""}
                    ${Utils.isLinear(theme) ? "sb3-text-center" : "sb3-text-left"} 
                    sb3-w-full hover:sb3-bg-[#0000] hover:sb3-border-0 focus:sb3-bg-0 focus:sb3-border-[#0000] focus:!sb3-outline-none`}
                    onClick={() => handleProductClick(selectedVariant ?? product)}>{ProductHelper.getProductPrimaryTitle(product, platform)}</button>
                }
    
                <p onClick={() => handleProductClick(selectedVariant ?? product)} className={`sb3-product-grid-subtitle  ${ platform === THEME_PLATFORMS.BIG_COMMERCE ? "sb3-text-sm sb3-font-bold" : "sb3-text-base sb3-text-gray-extraDark"} sb3-line-clamp-3 sb3-text-xs`}>{
                    ProductHelper.getProductSecondaryTitle(product, platform)
                }</p>
                
               {!Utils.isLinear(theme) && <p className=" sb3-product-grid-description sb3-text-sm sb3-text-gray-extraDark sb3-line-clamp-2">{ProductHelper.getProductTertiaryTitle(product, platform)}</p>}
                {
                    isArray(product[RESULT_PRDT_OPTIONS]) 
                    && product[RESULT_PRDT_OPTIONS]?.length > 1 
                    && <ReactSelect
                        value={options?.filter((item) => (selectedVariant)?.[RESULT_PRDT_SKU] === item.value)}
                        options={options}
                        onChange={(selection) => setSelectedVariant(product[RESULT_PRDT_OPTIONS]?.find(pr => pr[RESULT_PRDT_SKU] === selection?.value))}
                        menuPosition="fixed"
                        styles={{
                            control: (provided, state) => ({
                            ...provided,
                                borderRadius: 0,
                                minWidth: 100,
                                background: "#F9F9F9"
                            })
                        }}
                    />
                }
    
                {(selectedVariant?.[RESULT_PRDT_DESC] || product[RESULT_PRDT_DESC]) && <ProductDescriptionTooltip message={selectedVariant?.[RESULT_PRDT_DESC] ?? product[RESULT_PRDT_DESC]}/>}
            </div>
    
               
            <div className="sb3-product-grid-footer-container sb3-flex sb3-flex-col sb3-space-y-2 sb3-justify-center sb3-w-full ">
                <div>
                    {
                        platform !== THEME_PLATFORMS.BIG_COMMERCE &&
                        <p className="sb3-product-price-p sb3-product-grid-price sb3-text-sm sb3-font-semibold ">
                            <ProductPriceString
                                price={ProductHelper.getProductPrice(product, selectedVariant?.[RESULT_PRDT_SKU], product[RESULT_PRDT_OPTIONS])}
                                isQuote={product[RESULT_PRDT_IS_QUOTE]}
                                // compareAtPrice={product.compareAtPrice}
                                // currency={product.currency}
                            /></p>
                    }
                    {
                        !Utils.isLinear(theme) &&
                        <span className="sb3-product-status-span sb3-flex sb3-items-center sb3-max-w-full sb3-text-sm sb3-space-x-2">
                            <ProductStockStatus product={selectedVariant ?? product}/>
                        </span>
                    }
                </div>
                <div className="sb3-product-grid-controls sb3-flex sb3-justify-between sb3-items-center">
                {
                    platform === THEME_PLATFORMS.BIG_COMMERCE &&<p className="sb3-product-grid-price-p sb3-text-sm sb3-font-semibold">
                    <ProductPriceString
                        price={ProductHelper.getProductPrice(product, selectedVariant?.[RESULT_PRDT_SKU], product[RESULT_PRDT_OPTIONS])}
                        isQuote={product[RESULT_PRDT_IS_QUOTE]}
                        // compareAtPrice={product.compareAtPrice}
                        // currency={product.currency}
                    /></p>
                    
                }
                {
                    platform === THEME_PLATFORMS.BIG_COMMERCE &&selected &&
                    ((product[RESULT_MIN_PURCHASE_QTY] && product[RESULT_MIN_PURCHASE_QTY] > 1) || 
                    (maxQty && maxQty > 1)) && 
                    quantity &&
                    <QuantityInput
                        quantity={quantity}
                        handleChange={handleQtyChange}
                        disablePrev={(qty: number) => qty === 1}
                        disableNext={() => !showQtyInput}
                        maxPurchaseQty={product[RESULT_MAX_PURCHASE_QTY]}
                        minPurchaseQty={product[RESULT_MIN_PURCHASE_QTY]}
                    />
                }
                {
                    platform !== THEME_PLATFORMS.BIG_COMMERCE && !selected && 
                    <BorderedButton 
                    disabled={!showQtyInput || dataLoading} 
                    onClick={() => instance?.[RUNTIME_INSTANCE_INSTANCE_ID] 
                        && addProductToCart(selectedVariant ?? product, product[RESULT_MIN_PURCHASE_QTY] ?? 1, product[RESULT_PRDT_CATEGORY_ID], instance, category[MODEL_DATA][CATEGORY_NAME])
                    } className="sb3-product-grid-add-to-build !sb3-px-6 sb3-m-auto sb3-w-full">{
                        buttonLabel ?? "ADD TO BUILD"
                    }</BorderedButton>
                }
                
                {
                    platform === THEME_PLATFORMS.BIG_COMMERCE && !selected && 
                    <FillButton disabled={!showQtyInput || dataLoading} onClick={() => instance?.[RUNTIME_INSTANCE_INSTANCE_ID] && addProductToCart(selectedVariant ?? product, product[RESULT_MIN_PURCHASE_QTY] ?? 1, product[RESULT_PRDT_CATEGORY_ID], instance, category[MODEL_DATA][CATEGORY_NAME])}
                    className="sb3-product-grid-icon-addtocart !sb3-w-min !sb3-min-w-min !sb3-rounded-full">
                        <CartIcon className="sb3-product-grid-cart-icon"/>
                    </FillButton>
                }
                </div>
    
                
                {
                    selected &&
                    <div className={`sb3-product-grid-remove-container ${platform !== THEME_PLATFORMS.BIG_COMMERCE ? "sb3-flex sb3-items-center sb3-justify-center" : "sb3-text-center"}`}>
                    {
                        platform !== THEME_PLATFORMS.BIG_COMMERCE &&
                        ((product[RESULT_MIN_PURCHASE_QTY] && product[RESULT_MIN_PURCHASE_QTY] > 1) || 
                        (maxQty && maxQty > 1)) && 
                        quantity &&
                        <QuantityInput
                            quantity={quantity}
                            handleChange={handleQtyChange}
                            disablePrev={(qty: number) => qty === 1}
                            disableNext={() => !showQtyInput}
                            maxPurchaseQty={product[RESULT_MAX_PURCHASE_QTY]}
                            minPurchaseQty={product[RESULT_MIN_PURCHASE_QTY]}
                        />
                        }
                        
                        <BorderlessButton 
                        disabled={dataLoading} 
                        onClick={() => instance?.[RUNTIME_INSTANCE_INSTANCE_ID] 
                        && removeProductFromCart(selectedVariant ?? product, product[RESULT_PRDT_CATEGORY_ID], instance[RUNTIME_INSTANCE_INSTANCE_ID])} 
                        className="sb3-product-grid-remove sb3-remove-button !sb3-min-w-min sb3-text-sm sb3-text-danger">{
                            "Remove"
                        }</BorderlessButton> 
                        
                    </div>
                    
                }
            </div>
        </div>
    </div>
    )
}

export default ProductVertical