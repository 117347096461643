import AuthManager from "Services/AuthManager";
import ServiceWrapper from "Services/ServiceWrapper";
import React from "react";
import { useNavigate } from "react-router-dom";

import { FaSpinner } from "react-icons/fa";
import { useSearchParams } from "react-router-dom";

function CallbackLogin(props: any) {
  const navigate = useNavigate()
    const [searchParams, ] = useSearchParams()
  const [loading, setLoading] = React.useState(false);
  const [gmsLoginError, ] = React.useState(false);
  const [hasAccess, setHasAccess] = React.useState(false);
  const [userValidity, setUserValidity] = React.useState(false);
  const [verifyingAccess, setVerifyingAccess] = React.useState(true);
  const [loadingProfile, setLoadingProfile] = React.useState(false);
  const [profile, setProfile] = React.useState<any>({});
  const api_base_url = "https://sams-api.compat.io/";

  const get_user_name = () => {
    if (profile !== null && profile !== undefined) {
      if (profile.profile !== null && profile.profile !== undefined) {
        return profile.profile.first_name + " " + profile.profile.last_name;
      }
    }
    return "";
  };

  const check_if_user_belongs_to_party = (profileResponse: any) =>
    profileResponse.profile.parties.filter((party: any) => party.name === "GMS");

  const process_response = async () => {
    AuthManager.logoutUser();
    let gotAccess = false;
    setLoading(true);
    setLoadingProfile(true);
    let qstring = searchParams.get('t')
    if(!qstring) return setVerifyingAccess(false);
    AuthManager.setSamsToken(qstring);
    // await Utils.sleep(3000);

    let profileResponse = await ServiceWrapper.doPost(
      api_base_url + "v1/user/getprofile",
      {},
      null,
      qstring,
      false,
      false,
    );
    // console.log(profileResponse);
    setLoading(false);
    setProfile(profileResponse.data);
    setVerifyingAccess(true);
    // await Utils.sleep(1000);
    var profileobj: any = profileResponse.data;

    // if(profileobj){
    //   localStorage.setItem("merchantList", profileobj.profile?.merchantList ? JSON.stringify(profileobj.profile.merchantList) : []);
    // }
    
    setUserValidity(profileobj.valid);
    if (profileobj.valid) {
        if (check_if_user_belongs_to_party(profileResponse.data)) {
            gotAccess = true;
            setHasAccess(true);
            // console.log("Auth use set", typeof window)
            AuthManager.setAuthUser(profileResponse.data);
            // await Utils.sleep(1000);
        }
        setVerifyingAccess(false);
      
        if (gotAccess) {
            navigate((((AuthManager.getPathname() && AuthManager.getPathname() !== '/') ? AuthManager.getPathname() : '/configure') ?? "/configure") + (AuthManager.getQueryParams() ?? ""), {replace: true});
        }
    }
    setVerifyingAccess(false);
  };

  React.useEffect(() => {
    if(typeof window !== "undefined" && AuthManager.isLoggedIn()) {
        navigate((((AuthManager.getPathname() && AuthManager.getPathname() !== '/') ? AuthManager.getPathname() : '/configure') ?? '/configure') + (AuthManager.getQueryParams() ?? ""), {replace: true})
        return
    }
    async function do_process_response() {
      await process_response();
    }
    do_process_response();
  }, []);

  return (
    <React.Fragment>
  <div className="sb3-contain sb3-min-h-screen sb3-space-y-2 sb3-max-w-6xl sb3-p-4 sb3-m-auto sb3-justify-content-center sb3-align-items-center">
    <div className="sb3-row">&nbsp;</div>
    <div className="sb3-row">&nbsp;</div>
    {loading && (
      <React.Fragment>
        <div className="sb3-flex sb3-space-x-2 sb3-items-center sb3-border sb3-border-solid sb3-rounded sb3-p-2 sb3-border-gray-500 sb3-text-gray-500">
          <span><FaSpinner className="sb3-animate-spin"/></span>&nbsp;
          We are fetching your profile information. Please wait.....
        </div>
      </React.Fragment>
    )}
    {loadingProfile && !loading && (
      <React.Fragment>
        <div className="sb3-flex sb3-space-x-2 sb3-items-center sb3-border sb3-border-solid sb3-rounded sb3-p-2 sb3-border-gray-500 sb3-text-gray-500">
          <strong>Success!</strong> Welcome{" "}
          <strong>{get_user_name()}</strong>
        </div>
        {!gmsLoginError && (
          <div className="sb3-flex sb3-space-x-2 sb3-items-center sb3-border sb3-border-solid sb3-rounded sb3-p-2 sb3-bg-orange-50 sb3-text-orange-500">
            <span className="sb3-font-bold">Warning! </span> Please do not press{" "} <span className="sb3-font-bold sb3-mr-1"> Refresh </span> or <span className="sb3-font-bold sb3-mr-1"> Reload </span> the page.
          </div>
        )}
        {hasAccess && (
          <React.Fragment>
            {!gmsLoginError && (
              <div className="sb3-flex sb3-space-x-2 sb3-items-center sb3-border sb3-border-solid sb3-rounded sb3-p-2 sb3-bg-green-50 sb3-text-green-500">
                <strong>Info! </strong> Please wait while we take you back to
                your dashboard
              </div>
            )}
            {!gmsLoginError && (
              <div className="sb3-flex sb3-space-x-2 sb3-items-center sb3-border sb3-border-solid sb3-rounded sb3-p-2 sb3-border-black sb3-text-black">
                <span><FaSpinner className="sb3-animate-spin"/></span>&nbsp;
                &nbsp; You are being redirected. Please wait.....
              </div>
            )}
            {gmsLoginError && (
              <div className="sb3-flex sb3-space-x-2 sb3-items-center sb3-border sb3-border-solid sb3-rounded sb3-p-2 sb3-bg-red-50 sb3-text-red-500">
                <strong>Oops !</strong> Server Error: Please press{" "}
                <strong>Reload</strong>&nbsp;&nbsp;or&nbsp;
                <strong>Login</strong>&nbsp;button&nbsp;&nbsp;
                <button
                  type="button"
                  onClick={() => window.location.reload()}
                  className="sb3-bg-green-600 sb3-text-white sb3-rounded sb3-px-3 sb3-py-2  focus:!sb3-outline-none"
                >
                  Reload
                </button>
                &nbsp;
                <a
                  type="button"
                  href="/"
                  className="sb3-bg-primary sb3-text-white sb3-rounded sb3-px-3 sb3-py-2"
                >
                  Login
                </a>
              </div>
            )}
          </React.Fragment>
        )}
        {!hasAccess && verifyingAccess && (
          <div className="sb3-flex sb3-space-x-2 sb3-items-center sb3-border sb3-border-solid sb3-rounded sb3-p-2 sb3-bg-gray-50 sb3-text-gray-500">
            <span><FaSpinner className="sb3-animate-spin"/></span>&nbsp;
            &nbsp; We are verifying authorisation. Please wait.....
          </div>
        )}
        {!hasAccess && !verifyingAccess && (
          <React.Fragment>
            <div className="sb3-flex sb3-space-x-2 sb3-items-center sb3-border sb3-border-solid sb3-rounded sb3-p-2 sb3-bg-red-50 sb3-text-red-500">
              <strong>Oops!</strong> Sorry, you dont have rights to access
              this application
            </div>
            <div className="sb3-flex sb3-space-x-2 sb3-items-center sb3-border sb3-border-solid sb3-rounded sb3-p-2 sb3-border-black sb3-text-black">
              If you are lost , Please click&nbsp;
              <a href="/">here</a>&nbsp;to login back
            </div>
          </React.Fragment>
        )}
      </React.Fragment>
    )}
  </div>
</React.Fragment>
  );
}

export default CallbackLogin;