import { BUILDER_CONFIG, CONFIG_CUSTOMIZATION, CUSTOMIZATION_OUT_OF_STOCK_STATUS, CUSTOMIZATION_STOCK_CHECK_TEXT, CUSTOMIZATION_THEME_SETTINGS, THEME_FILL_BUTTON_BG, THEME_FILL_BUTTON_CLR, THEME_PLATFORMS, THEME_SECONDARY } from "constants/constants"
import { useAppContext } from "contexts/AppContext"
import React, { SetStateAction } from "react"
import { FaCheck } from "react-icons/fa"

type MyProps = {
    availabilityFlag: boolean
    setAvailabilityFlag: React.Dispatch<SetStateAction<boolean>>
}

const AvailabilityInput = ({
    availabilityFlag,
    setAvailabilityFlag
}: MyProps) => {
    const {
        // availabilityFlag,
        // setAvailabilityFlag,
        builderSpec,
        platform
    } = useAppContext()

    const styles: any = {}
    
    if(builderSpec?.[BUILDER_CONFIG]?.[CONFIG_CUSTOMIZATION]?.[CUSTOMIZATION_THEME_SETTINGS]?.[THEME_FILL_BUTTON_BG] && availabilityFlag) {
        styles.backgroundColor = builderSpec?.[BUILDER_CONFIG]?.[CONFIG_CUSTOMIZATION]?.[CUSTOMIZATION_THEME_SETTINGS]?.[THEME_FILL_BUTTON_BG]
        styles.borderColor = builderSpec?.[BUILDER_CONFIG]?.[CONFIG_CUSTOMIZATION]?.[CUSTOMIZATION_THEME_SETTINGS]?.[THEME_FILL_BUTTON_BG]
        styles.color = builderSpec?.[BUILDER_CONFIG]?.[CONFIG_CUSTOMIZATION]?.[CUSTOMIZATION_THEME_SETTINGS]?.[THEME_FILL_BUTTON_CLR]
        styles.fill = builderSpec?.[BUILDER_CONFIG]?.[CONFIG_CUSTOMIZATION]?.[CUSTOMIZATION_THEME_SETTINGS]?.[THEME_FILL_BUTTON_CLR]
    }

    return (
        <div>
            <label className="sb3-text-sm sb3-font-bold sb3-text-gray-extraDark sb3-flex sb3-items-center">
            <input 
                type="checkbox" 
                onChange={() => setAvailabilityFlag(prev => !prev)} 
                checked={!!availabilityFlag} 
                className="custom-checkbox sb3-hidden"
            />

            <span 
            tabIndex={0}
            className={`sb3-w-4 sb3-block sb3-mt-0.5 sb3-h-4 sb3-border sb3-border-solid sb3-bg-white ${platform === THEME_PLATFORMS.BIG_COMMERCE && "!sb3-border-2"} sb3-border-gray-300 sb3-rounded sb3-flex-shrink-0 sb3-relative checkmark`}
            style = {styles}
            >

                {!!availabilityFlag && (
                    <FaCheck className="sb3-text-sm sb3-text-white sb3-p-0.5"
                        style={{fill: styles.fill}}
                    />
                )}
            </span>
            <span className="sb3-ml-2">{!!builderSpec?.[BUILDER_CONFIG][CONFIG_CUSTOMIZATION]?.[CUSTOMIZATION_STOCK_CHECK_TEXT] ?
                            builderSpec?.[BUILDER_CONFIG][CONFIG_CUSTOMIZATION]?.[CUSTOMIZATION_STOCK_CHECK_TEXT]
                            : "Show In Stock Only"}</span>
            </label>
        </div>
    )
}

export default AvailabilityInput