import { CLASS_ID, CLASS_NAME, CONNECTOR_DATA, CONNECTOR_SUBMODELS, MODEL_DYNAMIC_FIELDS, RUNTIME_INSTANCES, RUNTIME_INSTANCE_BASE_ID, RUNTIME_INSTANCE_INSTANCE_ID } from "constants/constants";
import { useAppContext } from "contexts/AppContext";
import { TConnector, TModelData, TRuntimeInstance } from "types";

type MyProps = {
    connector: TConnector,
    instance?: TRuntimeInstance | TModelData
}

const useConnector = ({connector, instance}: MyProps) => {
    const {
        createConnection,
        dropConnection,
        runtimeSpec,
    } = useAppContext()

    const item_ids = connector[CONNECTOR_DATA][CONNECTOR_SUBMODELS].map((item) => item[CLASS_ID])
    const itemIdNameMap = connector[CONNECTOR_DATA][CONNECTOR_SUBMODELS].reduce((acc: {[x: string]: string}, item) => {
        if(item[CLASS_ID]) acc[item[CLASS_ID]] = item[CLASS_NAME]; 
        return acc
    }, {})
        
    const handleAddConnection = (connectionId: string) => {
        if(!connectionId || !instance?.[RUNTIME_INSTANCE_INSTANCE_ID]) return console.debug("Data Error, either connection id or instance id is missing", "x5")

        createConnection(connectionId, instance[RUNTIME_INSTANCE_INSTANCE_ID])
    }

    const handleDropConnection = (connectionId: string) => {
        if(!connectionId || !instance?.[RUNTIME_INSTANCE_INSTANCE_ID]) return console.debug("Data Error, either connection id or instance id is missing", "x5")

        dropConnection(connectionId, instance[RUNTIME_INSTANCE_INSTANCE_ID])
    }

    const valueInstances = runtimeSpec && Object.values(runtimeSpec).reduce((acc: {title: any, value: string}[], instance) => {
        if(item_ids.includes(instance[RUNTIME_INSTANCE_BASE_ID])) {
            let values = Object.values((instance as TRuntimeInstance)[MODEL_DYNAMIC_FIELDS]);
            let _title:string = `${values.length > 0 ? values[0].value ?? '' : ''}`;
            acc.push({
                title: _title !== "" ? _title : `${itemIdNameMap[instance[RUNTIME_INSTANCE_BASE_ID]]} #${acc.length + 1}`,
                value: instance[RUNTIME_INSTANCE_INSTANCE_ID]
            })
        }
        return acc
    }
    , [])

    const instanceIdMap = valueInstances?.reduce((acc: {[x: string]: string}, item) => {
        acc[item.value] = item.title; 
        return acc
    }, {})

    return {
        instanceIdMap,
        valueInstances,
        handleAddConnection,
        handleDropConnection
    }
}

export default useConnector