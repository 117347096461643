import AuthManager from 'Services/AuthManager'
import ServiceWrapper from 'Services/ServiceWrapper'
import Utils from 'Services/Utils'
import AppSettings from 'Settings/AppSettings'
import * as consts from 'constants/constants'
// import { DUMMY_MODEL_SPEC } from 'constants/dummy_data'
import { TResponseData } from 'types/Services/ServiceWrapper'
import { TModelSpecResponse, TResultProduct } from 'types'
import { isArray } from 'lodash'

const api_url = AppSettings.ISR_BUILDER_API_URL

/**
 * configure API
 * load initial UI defn
 * 
 * @param setSessionId - whether to set session id
 * @param iter - no of calls 
 */

export const fetchModelSpec: (merchantKey?: string, setSessionId?: boolean, projectId?: string|null, testData?: boolean, sessionId?: string|null, callAgain?: boolean, variantList?: string[]) => Promise<TModelSpecResponse> = (
        merchantKey,
        setSessionId = true,
        projectId = null,
        test_data = false,
        sessionId,
        callAgain = false,
        variantList
    ) => {
    // if(test_data) return Promise.resolve(DUMMY_MODEL_SPEC)

    return new Promise(async (resolve, reject) => {
        const _projectId =  projectId ?? window.compatioConfig?.magento?.modelId ?? undefined

        // if(!_projectId) return reject(new Error("Project Id not found"))

        const data: {[x:string]: string| string[]} = {
            // [consts.API_KEY_VERSION]: consts.API_VALUE_VERSION
        }

        if(merchantKey && Utils.checkIfClientWebsite()) data[consts.API_MERCHANT_KEY] = merchantKey

        if(setSessionId && AuthManager.getSessionId()){
            data[consts.API_SESSION_KEY] = sessionId ?? AuthManager.getSessionId()
        }

        if(isArray(variantList) && variantList.length > 0) {
            // data[consts.API_KEY_PROJECT_ID] = '3352f414e1434b88bd48a8d909ea691e'
            data[consts.API_VARIANT_LIST] = variantList
        }
        // else {
            if(_projectId) data[consts.API_KEY_PROJECT_ID] = _projectId
        // }

        const result = await ServiceWrapper.doPost(
            api_url + consts.API_CONFIGURE,
            data,
            null,
            null
        ) as TResponseData<TModelSpecResponse>
        
        if(result.data?.[consts.ERROR]) 
            return reject(new Error(result.data?.[consts.ERROR] ?? "Unexpected error"))

        // if session is expired
        if(result.data?.[consts.IS_ERROR] && setSessionId === true && callAgain)
            return fetchModelSpec(merchantKey, false, projectId, test_data)
        
        if(result.data?.[consts.SESSION_ID]) 
            AuthManager.setSessionId(result.data[consts.SESSION_ID] ?? '')

        if(!result.data?.[consts.MODEL_SPEC] || !result.data?.[consts.RUNTIME_SPEC]) 
            return reject(new Error("Unexpected Error"))
        
        resolve(result.data) 
    })
}

/**
 * Input API
 * create instance inputs
 *
 * @param subModelId - if of the field
 * @returns
 */
export const createInstanceApi = (
    subModelId: string,
    value?: (string|boolean|number)[], 
    ) => {
    return new Promise(async (resolve, reject) => {
        const url = `${api_url}${consts.API_CREATE_INSTANCE}`

        // send the selected value to api and set new UIdefn
        const result = await ServiceWrapper.doPost(
        url,
        {
            [consts.API_SESSION_KEY]: AuthManager.getSessionId(),
            [consts.API_SUB_MODEL_ID]: subModelId,
            // [consts.API_CHOICE_KEY]: value,
        },
        null,
        null
        )

        if(result.data?.[consts.ERROR])
            return reject(new Error(result.data[consts.ERROR]))
        if(result.data?.[consts.IS_ERROR])
            return reject(new Error(result.data[consts.ERROR_MESSAGE]))
        if(!result.data[consts.RUNTIME_SPEC])
            return reject(new Error("Unexpected Error"))
        if(result.data?.[consts.SESSION_ID])
            AuthManager.setSessionId(result.data[consts.SESSION_ID])

        resolve(result.data)
    })
}

/**
 * Input API
 * create instance inputs
 *
 * @param subModelId - if of the field
 * @returns
 */
export const deleteInstanceApi = (
    instanceId: string
    ) => {
    return new Promise(async (resolve, reject) => {

        const url = `${api_url}${consts.API_CREATE_INSTANCE}`

        // send the selected value to api and set new UIdefn
        const result = await ServiceWrapper.doDelete(
        url,
        {
            [consts.API_SESSION_KEY]: AuthManager.getSessionId(),
            [consts.API_INSTANCE_ID]: instanceId,
        },
        null,
        null
        )

        if(result.data?.[consts.ERROR])
            return reject(new Error(result.data[consts.ERROR]))
        if(result.data?.[consts.IS_ERROR])
            return reject(new Error(result.data[consts.ERROR_MESSAGE]))
        if(!result.data[consts.RUNTIME_SPEC])
            return reject(new Error("Unexpected Error"))
        if(result.data?.[consts.SESSION_ID])
            AuthManager.setSessionId(result.data[consts.SESSION_ID])

        resolve(result.data)
    })
}


export const createConnectionApi = (
    fromSub: string,
    toSub: string
    ) => {
    return new Promise(async (resolve, reject) => {

        const url = `${api_url}${consts.API_CREATE_CONNECTION}`

        // send the selected value to api and set new UIdefn
        const result = await ServiceWrapper.doPost(
        url,
        {
            [consts.API_SESSION_KEY]: AuthManager.getSessionId(),
            [consts.API_INSTANCE_FROM]: fromSub,
            [consts.API_INSTANCE_TO]: toSub,
        },
        null,
        null
        )

        if(result.data?.[consts.ERROR])
            return reject(new Error(result.data[consts.ERROR]))
        if(result.data?.[consts.IS_ERROR])
            return reject(new Error(result.data[consts.ERROR_MESSAGE]))
        if(!result.data[consts.RUNTIME_SPEC])
            return reject(new Error("Unexpected Error"))
        if(result.data?.[consts.SESSION_ID])
            AuthManager.setSessionId(result.data[consts.SESSION_ID])

        resolve(result.data)
    })
}

export const dropConnectionApi = (
    fromSub: string,
    toSub: string
    ) => {
    return new Promise(async (resolve, reject) => {

        const url = `${api_url}${consts.API_DROP_CONNECTION}`

        // send the selected value to api and set new UIdefn
        const result = await ServiceWrapper.doPost(
        url,
        {
            [consts.API_SESSION_KEY]: AuthManager.getSessionId(),
            [consts.API_INSTANCE_FROM]: fromSub,
            [consts.API_INSTANCE_TO]: toSub,
        },
        null,
        null
        )

        if(result.data?.[consts.ERROR])
            return reject(new Error(result.data[consts.ERROR]))
        if(result.data?.[consts.IS_ERROR])
            return reject(new Error(result.data[consts.ERROR_MESSAGE]))
        if(!result.data[consts.RUNTIME_SPEC])
            return reject(new Error("Unexpected Error"))
        if(result.data?.[consts.SESSION_ID])
            AuthManager.setSessionId(result.data[consts.SESSION_ID])

        resolve(result.data)
    })
}

export const setInstanceInput = (
    fieldId: string, 
    value: (string|boolean|number)[], 
    instanceId: string|undefined,
    forced: boolean = false, 
    disabled: boolean = false, 
    deselect: boolean = false
    ) => {
    return new Promise(async (resolve, reject) => {
        // generate params string for url
        const params = []
        if(forced) params.push(`${consts.API_IS_FORCED}=true`)
        if(disabled) params.push(`${consts.API_IS_DISABLED}=true`)
        if(deselect) params.push(`${consts.API_IS_DESELECT}=true`)
        const params_string = params.length > 0 ? '?' + params.join('&') : ''
    
        const url = `${api_url}${consts.API_INSTANCE_INPUT}${params_string}`
    
        // send the selected value to api and set new UIdefn
        const result = await ServiceWrapper.doPut(
        url,
        {
            [consts.API_SESSION_KEY]: AuthManager.getSessionId(),
            [consts.API_INSTANCE_ID]: instanceId,
            [consts.API_INSTANCE_DATA]: {
                [fieldId]: value
            },
        },
        null,
        null
        )
    
        if(result.data?.[consts.ERROR]) 
            return reject(new Error(result.data[consts.ERROR]))
        if(result.data?.[consts.IS_ERROR]) 
            return reject(new Error(result.data[consts.ERROR_MESSAGE]))
        if(!result.data?.[consts.RUNTIME_SPEC] && !result.data?.[consts.RES_CONFLICT_FLAG]) 
            return reject(new Error("Unexpected Error"))
        if(result.data?.[consts.SESSION_ID]) 
            AuthManager.setSessionId(result.data[consts.SESSION_ID])
        
        resolve(result.data)
    })
}

export const setInstanceInputs = (
    value: {[x: string]: (string|boolean|number)[]}, 
    instanceId: string|undefined,
    forced: boolean = false, 
    disabled: boolean = false, 
    deselect: boolean = false
    ) => {
    return new Promise(async (resolve, reject) => {
        // generate params string for url
        const params = []
        if(forced) params.push(`${consts.API_IS_FORCED}=true`)
        if(disabled) params.push(`${consts.API_IS_DISABLED}=true`)
        if(deselect) params.push(`${consts.API_IS_DESELECT}=true`)
        const params_string = params.length > 0 ? '?' + params.join('&') : ''
    
        const url = `${api_url}${consts.API_INSTANCE_INPUT}${params_string}`
    
        // send the selected value to api and set new UIdefn
        const result = await ServiceWrapper.doPut(
        url,
        {
            [consts.API_SESSION_KEY]: AuthManager.getSessionId(),
            [consts.API_INSTANCE_ID]: instanceId,
            [consts.API_INSTANCE_DATA]: value,
        },
        null,
        null
        )
    
        if(result.data?.[consts.ERROR]) 
            return reject(new Error(result.data[consts.ERROR]))
        if(result.data?.[consts.IS_ERROR]) 
            return reject(new Error(result.data[consts.ERROR_MESSAGE]))
        if(!result.data?.[consts.RUNTIME_SPEC]) 
            return reject(new Error("Unexpected Error"))
        if(result.data?.[consts.SESSION_ID]) 
            AuthManager.setSessionId(result.data[consts.SESSION_ID])
        
        resolve(result.data)
    })
}


export const fetchResultsApi: (
    categoryName: string|string[], 
    instanceId?: string,
    page?: number,
    limit?: number,
    extra?: number, // inorder to overcompansate products
    sort?: {s_key?: string, s_order?: string},
    signal?: AbortSignal,
    availabilityCheck?: boolean
) => Promise<{products: TResultProduct[], total_prod_count: number, total_prod_count_available: number}> = (
    categoryName, 
    instanceId,
    page,
    limit,
    extra,
    sort,
    signal,
    availabilityCheck
) => {
    return new Promise(async (resolve, reject) => {
        const {start, end} = Utils.getStartEndFromPageAndLimit(page, limit, undefined)

        const params = {start, end: (extra && end) ? end + ((limit ?? 1) * extra) : end, available: availabilityCheck ?? false,...sort}
        const urlParams = Utils.generateQueryParamsFromObject(params)

        const url = `${api_url}${consts.API_INSTANCE_RESULT}${urlParams ? "?" + urlParams : ""}`

        // send the selected value to api and set new UIdefn
        const result = await ServiceWrapper.doPost(
            url,
            {
                [consts.API_SESSION_KEY]: AuthManager.getSessionId(),
                [consts.API_CATEGORY_NAME]: categoryName,
                [consts.API_INSTANCE_ID]: instanceId,
            },
            null,
            null,
            false,
            true,
            signal
        )

        if(result.data?.[consts.ERROR])
            return reject(new Error(result.data[consts.ERROR]))

        resolve(result.data)
    })
}

export const fetchProductsByProductId: (
    products: {categoryName: string, productIds: (string|number)[]}[], 
) => Promise<TResultProduct[]> = (
    products
) => {
    return new Promise(async (resolve, reject) => {
        const url = `${api_url}${consts.API_PRODUCT_INFO}`

        // send the selected value to api and set new UIdefn
        const result = await ServiceWrapper.doPost(
            url,
            {
                [consts.API_SESSION_KEY]: AuthManager.getSessionId(),
                [consts.API_PRODUCTS]: products.map((product => ({[consts.API_CATEGORY_NAME]: product.categoryName,  [consts.API_PRODUCT_IDS]: product.productIds}))),
            },
            null,
            null
        )

        if(result.data?.[consts.ERROR])
            return reject(new Error(result.data[consts.ERROR]))

        resolve(result.data)
    })
}

export const checkVariantHasBuilder: (
    variantId: string, 
    merchantKey: string, 
) => Promise<{project_id: string, project_name: string}> = (
    variantId,
    merchantKey
) => {
    return new Promise(async (resolve, reject) => {
        const url = `${api_url}${consts.API_VARIANT_CHECK}`

        // send the selected value to api and set new UIdefn
        const result = await ServiceWrapper.doPost(
            url,
            {
                [consts.API_VARIANT_ID]: decodeURIComponent(variantId),
                [consts.API_MERCHANT_KEY]: merchantKey,
            },
            null,
            null
        )

        if(result.data?.[consts.ERROR])
            return reject(new Error(result.data[consts.ERROR]))

        resolve(result.data)
    })
}

export const checkVariantHasBuilders: (
    variantId: string, 
    merchantKey: string, 
) => Promise<{project_id: string, project_name: string}[]> = (
    variantId,
    merchantKey
) => {
    return new Promise(async (resolve, reject) => {
        const url = `${api_url}${consts.API_VARIANT_CHECK_MULTI}`

        // send the selected value to api and set new UIdefn
        const result = await ServiceWrapper.doPost(
            url,
            {
                [consts.API_VARIANT_ID]: decodeURIComponent(variantId),
                [consts.API_MERCHANT_KEY]: merchantKey,
            },
            null,
            null
        )

        if(result.data?.[consts.ERROR])
            return reject(new Error(result.data[consts.ERROR]))

        resolve(result.data)
    })
}