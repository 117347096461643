import { INFO_SIZES } from "constants/constants";
import { useEffect, useRef, useState, useCallback } from "react";

const PADDING = 20; // Padding from screen edges
const MIN_WIDTH = 200;
const MIN_HEIGHT = 100;

type Size = "small" | "medium" | "large" | "auto";
type Placement = "left" | "right";

interface SizeConfig {
  width?: number;
  height?: number;
}

// @todo change useeffect to useLayouteffect

const useResponsiveTooltip = (defaultSize: Size, iconRef: React.RefObject<HTMLElement>) => {
  const [size, setSize] = useState<SizeConfig>(INFO_SIZES[defaultSize]);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [placement, setPlacement] = useState<Placement>("right");
  const [isResizing, setIsResizing] = useState(false);
  const [autoSizeContent, setAutoSizeContent] = useState<{ width: number; height: number }>({ width: 0, height: 0 });
  const tooltipRef = useRef<HTMLDivElement>(null);

  // Calculate available space and determine placement
  const calculateSpace = useCallback(() => {
    if (!iconRef.current) return { maxWidth: window.innerWidth, maxHeight: window.innerHeight, placement: "right" };

    const iconRect = iconRef.current.getBoundingClientRect();
    const iconCenter = iconRect.left + iconRect.width / 2;
    const windowCenter = window.innerWidth / 2;
    
    // Calculate available space on each side
    const spaceLeft = iconRect.left - PADDING;
    const spaceRight = window.innerWidth - iconRect.right - PADDING;
    
    // Default to right placement unless specific conditions are met
    let newPlacement: Placement = "right";
    
    // Switch to left placement if:
    // 1. Icon is in the right half of the screen AND
    // 2. There's enough space on the left
    if (iconCenter > windowCenter && spaceLeft >= MIN_WIDTH) {
      newPlacement = "left";
    }
    
    // Override if one side has significantly more space
    if (spaceLeft > spaceRight * 1.5 && spaceLeft >= MIN_WIDTH) {
      newPlacement = "left";
    } else if (spaceRight > spaceLeft * 1.5 && spaceRight >= MIN_WIDTH) {
      newPlacement = "right";
    }

    // Check if the default placement has enough space for minimum width
    if (newPlacement === "right" && spaceRight < MIN_WIDTH && spaceLeft >= MIN_WIDTH) {
      newPlacement = "left";
    } else if (newPlacement === "left" && spaceLeft < MIN_WIDTH && spaceRight >= MIN_WIDTH) {
      newPlacement = "right";
    }

    const maxWidth = newPlacement === "right" ? spaceRight : spaceLeft;
    const maxHeight = window.innerHeight - PADDING * 2;

    return { maxWidth, maxHeight, placement: newPlacement };
  }, []);

  // Calculate content size for auto mode
  const calculateAutoSize = useCallback(() => {
    if (!tooltipRef.current) return;

    const content = tooltipRef.current.querySelector('.info-text');
    if (!content) return;

    const tempDiv = document.createElement('div');
    tempDiv.style.position = 'absolute';
    tempDiv.style.visibility = 'hidden';
    tempDiv.style.width = 'auto';
    tempDiv.style.height = 'auto';
    tempDiv.innerHTML = content.innerHTML;
    document.body.appendChild(tempDiv);

    const naturalWidth = tempDiv.offsetWidth;
    const naturalHeight = tempDiv.offsetHeight;
    
    document.body.removeChild(tempDiv);

    const { maxWidth, maxHeight } = calculateSpace();

    setAutoSizeContent({
      width: Math.min(Math.max(naturalWidth, MIN_WIDTH), maxWidth),
      height: Math.min(Math.max(naturalHeight, MIN_HEIGHT), maxHeight)
    });
  }, [calculateSpace]);

  // Update size and placement
  const updateSize = useCallback(() => {
    const { maxWidth, maxHeight, placement: newPlacement } = calculateSpace();
    setPlacement(newPlacement as Placement);

    if (defaultSize === 'auto') {
      const width = Math.min(autoSizeContent.width || maxWidth, maxWidth);
      const height = Math.min(autoSizeContent.height || maxHeight, maxHeight);
      setSize({ width, height });
    } else {
      const desiredSize = INFO_SIZES[defaultSize];
      setSize({
        width: Math.min(desiredSize.width || maxWidth, maxWidth),
        height: Math.min(desiredSize.height || maxHeight, maxHeight),
      });
    }
  }, [defaultSize, autoSizeContent, calculateSpace]);

  // Adjust position based on placement
  const adjustPosition = useCallback(() => {
    if (!tooltipRef.current || !iconRef.current) return;

    const tooltipRect = tooltipRef.current.getBoundingClientRect();
    const iconRect = iconRef.current.getBoundingClientRect();

    let x = 0;
    const iconCenter = iconRect.left + (iconRect.width / 2);
    
    if (placement === "left") {
      // For left placement, anchor to the icon's left edge
      const desiredRightEdge = iconRect.left - PADDING;
      x = desiredRightEdge - tooltipRect.width;
      
      // Prevent tooltip from going too far left
      const minX = PADDING;
      if (x < minX) {
        x = minX;
      }
    } else {
      // For right placement, start from icon's right edge
      x = iconRect.right + PADDING;
      
      // Prevent tooltip from going too far right
      const maxX = window.innerWidth - tooltipRect.width - PADDING;
      if (x > maxX) {
        x = maxX;
      }
    }

    // Calculate vertical position
    let y = iconRect.top - (tooltipRect.height / 3); // Start slightly above center
    
    // Prevent tooltip from going above screen
    if (y < PADDING) {
      y = PADDING;
    }
    
    // Prevent tooltip from going below screen
    const maxY = window.innerHeight - tooltipRect.height - PADDING;
    if (y > maxY) {
      y = maxY;
    }

    setPosition({ x, y });
  }, [placement]);

  useEffect(() => {
    const handleResize = () => {
      if (defaultSize === 'auto') {
        calculateAutoSize();
      }
      updateSize();
      adjustPosition();
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [updateSize, calculateAutoSize, adjustPosition, defaultSize]);

  useEffect(() => {
    if (!isResizing) return;

    const handleMouseMove = (e: MouseEvent) => {
      if (!tooltipRef.current || !iconRef.current) return;

      const tooltipRect = tooltipRef.current.getBoundingClientRect();
      const { maxWidth, maxHeight } = calculateSpace();

      const newWidth = Math.max(
        MIN_WIDTH,
        Math.min(e.clientX - tooltipRect.left, maxWidth)
      );
      const newHeight = Math.max(
        MIN_HEIGHT,
        Math.min(e.clientY - tooltipRect.top, maxHeight)
      );

      setSize({ width: newWidth + 60, height: newHeight + 30 });
      adjustPosition();
    };

    const handleMouseUp = () => {
      setIsResizing(false);
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isResizing, adjustPosition, calculateSpace]);

  // @todo add these 2 
  // useLayoutEffect(() => {
  //   if (defaultSize === 'auto') {
  //     calculateAutoSize();
  //   }
  //   updateSize();
  // }, [defaultSize, calculateAutoSize, autoSizeContent, updateSize]);

  // useLayoutEffect(() => {
  //   adjustPosition();
  // }, [placement, size, adjustPosition]);

  // remove these 2 use effect
  useEffect(() => {
    if (defaultSize === 'auto') {
      calculateAutoSize();
    }
  }, [defaultSize, calculateAutoSize]);

  useEffect(() => {
    updateSize();
  }, [autoSizeContent, updateSize]);
  // end

  const initializeTooltip = useCallback(() => {
    if (defaultSize === 'auto') {
      calculateAutoSize();
    }
    updateSize();
    adjustPosition();
  }, [defaultSize, calculateAutoSize, updateSize, adjustPosition]);

  return {
    size,
    position,
    placement,
    isResizing,
    setIsResizing,
    tooltipRef,
    initializeTooltip
  };
};

export default useResponsiveTooltip;