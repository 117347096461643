import BorderedButton from "components/Button/BorderedButton"
import { BUILDER_CONFIG, CONFIG_CUSTOMIZATION, CUSTOMIZATION_THEME_SETTINGS, THEME_ONLINE_BUTTON, THEME_PLATFORMS, THEME_SECONDARY } from "constants/constants"
import { useAppContext } from "contexts/AppContext"
import { debounce } from "lodash"
import { useCallback, useEffect, useState } from "react"
import { FaMinus, FaPlus } from "react-icons/fa"
import Utils from "Services/Utils"

type MyProps = {
    quantity?: number
    handleChange: (qty: number) => void
    disablePrev?: (qty: number) => boolean
    disableNext?: (qty: number) => boolean
    minPurchaseQty?: number | null
    maxPurchaseQty?: number | null
}

const QuantityInput = ({
    quantity,
    handleChange,
    disablePrev,
    disableNext,
    minPurchaseQty = null,
    maxPurchaseQty = null,
}: MyProps) => {
    const {theme, platform, builderSpec} = useAppContext()

    // Set initial quantity to minPurchaseQty if provided, otherwise use provided quantity or 1
    const [newQty, setNewQty] = useState(minPurchaseQty ?? quantity ?? 1)

    const handleInputChange = useCallback(debounce(handleChange, 300), []);

    useEffect(() => {
        // If quantity prop changes, update state but respect min/max bounds
        if (quantity !== undefined) {
            const boundedQuantity = getBoundedQuantity(quantity)
            setNewQty(boundedQuantity)
        }
    }, [quantity, minPurchaseQty, maxPurchaseQty])

    useEffect(() => {
        if(quantity === newQty) return
        handleInputChange(newQty)
    }, [newQty])

    // Helper function to ensure quantity stays within bounds
    const getBoundedQuantity = (qty: number): number => {
        if (minPurchaseQty !== null && qty < minPurchaseQty) return minPurchaseQty
        if (maxPurchaseQty !== null && qty > maxPurchaseQty) return maxPurchaseQty
        return qty
    }

    // Default disable handlers if none provided
    const defaultDisablePrev = (qty: number) => {
        if (minPurchaseQty !== null) return qty <= minPurchaseQty
        return qty <= 1
    }

    const defaultDisableNext = (qty: number) => {
        if (maxPurchaseQty !== null) return qty >= maxPurchaseQty
        return false
    }

    const buttonStyles: any = {}
      if(builderSpec?.[BUILDER_CONFIG]?.[CONFIG_CUSTOMIZATION]?.[CUSTOMIZATION_THEME_SETTINGS]) {
        const secondaryColor = platform === THEME_PLATFORMS.BIG_COMMERCE ? builderSpec?.[BUILDER_CONFIG]?.[CONFIG_CUSTOMIZATION]?.[CUSTOMIZATION_THEME_SETTINGS]?.[THEME_SECONDARY] : "#f7f8fa"
        const fillColor = builderSpec?.[BUILDER_CONFIG]?.[CONFIG_CUSTOMIZATION]?.[CUSTOMIZATION_THEME_SETTINGS]?.[THEME_ONLINE_BUTTON]
        buttonStyles.borderColor = secondaryColor
        buttonStyles.backgroundColor = secondaryColor
        buttonStyles.borderColor = secondaryColor
        buttonStyles.fill = fillColor
        buttonStyles.color = fillColor
      }
      

    let buttonClassName = `sb3-w-10 ${Utils.isLinear(theme) ? "!sb3-min-h-max !sb3-px-2" : "sb3-aspect-square"} sb3-border-x sb3-bg-gray-200 disabled:sb3-bg-[#EFF5F5] !sb3-p-0 sb3-min-w-min
    hover:disabled:!sb3-bg-white hover:disabled:!sb3-text-black focus:disabled:!sb3-bg-white focus:disabled:!sb3-border-white focus:disabled:!sb3-text-black !sb3-text-xs`

    return (
        <div className={`sb3-quantity-input sb3-flex ${(platform !== THEME_PLATFORMS.BIG_COMMERCE || Utils.isLinear(theme)) && "sb3-border"} sb3-justify-between sb3-items-center sb3-w-max`}>
            <BorderedButton
                onClick={() => {
                    setNewQty(prev => getBoundedQuantity(prev - 1))
                }} 
                disabled={ disablePrev?.(newQty) || defaultDisablePrev(newQty)} 
                active={!(disablePrev?.(newQty) || defaultDisablePrev(newQty))} 
                className={`sb3-quantity-input-plus ${buttonClassName} ${platform === THEME_PLATFORMS.BIG_COMMERCE && "!sb3-rounded-full !sb3-min-h-7 !sb3-min-w-7"}`}
                disabledClassName="!sb3-bg-[#EFF5F5] !sb3-border-[#EFF5F5] hover:sb3-[#EFF5F5] !sb3-fill-[#808080] !sb3-text-[#808080] hover:sb3-text-[#808080]"
                style={buttonStyles}
            >
                <span><FaMinus className="sb3-quantity-input-plus-icon"/></span>
            </BorderedButton>
            <span className="sb3-quantity-input-show sb3-w-10 sb3-font-bold sb3-text-center sb3-text-sm">{newQty}</span>
            <BorderedButton
                onClick={() => {
                    setNewQty(prev => getBoundedQuantity(prev + 1))
                }} 
                disabled={disableNext?.(newQty) || defaultDisableNext(newQty)} 
                active={!(disableNext?.(newQty) || defaultDisableNext(newQty))} 
                className={`sb3-quantity-input-minus ${buttonClassName} ${platform === THEME_PLATFORMS.BIG_COMMERCE && "!sb3-rounded-full !sb3-min-h-7 !sb3-min-w-7"}`}
                disabledClassName="!sb3-bg-[#EFF5F5] !sb3-border-[#EFF5F5] hover:sb3-[#EFF5F5] !sb3-fill-[#808080] !sb3-text-[#808080] hover:sb3-text-[#808080]"
                style={buttonStyles}
            >
                <span><FaPlus className="sb3-quantity-input-minus-icon"/></span>
            </BorderedButton>
        </div>
    )
}

export default QuantityInput