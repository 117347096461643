import BorderedButton from "components/Button/BorderedButton"
import * as consts from "constants/constants"
import { useAppContext } from "contexts/AppContext"
import NodeHelper from "helpers/NodeHelper"
import usePages from "hooks/usePages"
import { isArray } from "lodash"
import React from "react"
import { FaCheckCircle, FaChevronLeft, FaChevronRight } from "react-icons/fa"
import Utils from "Services/Utils"

/**
 * Pages navbar build from builder spec
 * will group pages based on pageLabels
 * 
 * @returns 
 */

export default function PagesNavbar() {
    const {
        // uiDefn,
        activePage,
        setPage,
        getInstances,
        builderSpec,
        specs,
        platform,
        dataLoading
    } = useAppContext()

    const {
        scrollIndicator,
        pagesNavRef,
        pageGroups,
        selectPrev,
        selectNext,
        startDragging,
        stopDragging,
        move,
        handleTouchStart,
        handleTouchMove,
        handleTouchEnd
    } = usePages()

    if(Object.entries(pageGroups).length === 0) return <></>

    return (
        <div className="sb3-relative">

        <div ref={pagesNavRef} 
        onMouseDown={startDragging}
        onMouseLeave={stopDragging}
        onMouseUp={stopDragging}
        onMouseMove={move}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
        className="sb3-no-scrollbar submodelNav sb3-flex lg:sb3-space-y-0 sb3-max-w-full sb3-overflow-x-auto sb3-scrollbar-gutter">
        {
            Object.entries(pageGroups).map(([groupName, pages], index) => {
            return  <React.Fragment key={index}>
                <div 
                className="sb3-space-y-2" 
                >
                    { (Object.keys(pageGroups).some((groupName => groupName!== "Other"))) && <p className="sb3-text-sm sb3-text-gray-extraDark">{groupName}</p> }

                    <div className={`sb3-flex sb3-items-center  ${(isArray(pages) && pages?.length > 0) && 'sb3-last:pr-1'}`}>
                        {
                            pages?.map((page, key) => {
                                // check if any of the submodel is required
                                const isRequired = page[consts.PAGE_FORM_DATA].some((builderSubmodel) => {
                                    const modelSubmodel = specs[builderSubmodel[consts.FRMDATA_SMID]]
                                    if(!modelSubmodel) return false

                                    const instances = getInstances(builderSubmodel[consts.FRMDATA_SMID])
                                    if(instances?.length === 0) return false
                                    return instances?.some(instance => NodeHelper.checkIfSubmodelIsRequiredByRuntimeSpec(
                                        instance
                                    ))
                                })

                                const isDone = page[consts.PAGE_FORM_DATA].every((builderSubmodel) => {
                                    const modelSubmodel = specs[builderSubmodel[consts.FRMDATA_SMID]]
                                    if(!modelSubmodel) return true

                                    const instances = getInstances(builderSubmodel[consts.FRMDATA_SMID])
                                    if(instances?.length === 0) return true

                                    return instances?.every(instance => NodeHelper.checkIfSubmodelIsDoneByRuntimeSpec(
                                        instance, 
                                        page[consts.PAGE_LAYOUT]
                                    ))
                                })
                                
                                return (
                                    <div 
                                        key={key}
                                        id={`step-submodel-${page?.[consts.PAGE_ID]}`}
                                        className={`sb3-pr-2 sb3-w-max`}>
                                            <BorderedButton 
                                                disabled={dataLoading}
                                                disabledClassName="sb3-page-disabled"
                                                active={activePage?.[consts.PAGE_ID] === page[consts.PAGE_ID]}
                                                onClick={() => setPage(page[consts.PAGE_ID])}
                                                className="sb3-space-x-2 sb3-w-auto sb3-font-semibold sb3-text-black !sb3-justify-center sb3-page-button"
                                                style={{
                                                    backgroundColor:builderSpec?.[consts.BUILDER_CONFIG]?.[consts.CONFIG_CUSTOMIZATION]?.[consts.CUSTOMIZATION_THEME_SETTINGS] && 
                                                    (platform === consts.THEME_PLATFORMS.BIG_COMMERCE || activePage?.[consts.PAGE_ID] === page[consts.PAGE_ID]) &&
                                                    (
                                                        activePage?.[consts.PAGE_ID] === page[consts.PAGE_ID] 
                                                        ? builderSpec[consts.BUILDER_CONFIG][consts.CONFIG_CUSTOMIZATION][consts.CUSTOMIZATION_THEME_SETTINGS][consts.THEME_FILL_BUTTON_BG] 
                                                        : builderSpec[consts.BUILDER_CONFIG][consts.CONFIG_CUSTOMIZATION][consts.CUSTOMIZATION_THEME_SETTINGS][consts.THEME_SECONDARY]
                                                    )}}
                                                >
                                                { isDone && <FaCheckCircle style={{
                                                    color: activePage?.[consts.PAGE_ID] === page[consts.PAGE_ID] ? "white" : builderSpec?.[consts.BUILDER_CONFIG]?.[consts.CONFIG_CUSTOMIZATION]?.[consts.CUSTOMIZATION_THEME_SETTINGS]?.[consts.THEME_ONLINE_BUTTON],
                                                    fill: activePage?.[consts.PAGE_ID] === page[consts.PAGE_ID] ? "white" : builderSpec?.[consts.BUILDER_CONFIG]?.[consts.CONFIG_CUSTOMIZATION]?.[consts.CUSTOMIZATION_THEME_SETTINGS]?.[consts.THEME_ONLINE_BUTTON]
                                                }} className={`sb3-text-base ${activePage?.[consts.PAGE_ID] === page[consts.PAGE_ID] ? "sb3-text-white" : ""}`}/>}
                                                <span style={{
                                                    color: activePage?.[consts.PAGE_ID] === page[consts.PAGE_ID] ? "white" : builderSpec?.[consts.BUILDER_CONFIG]?.[consts.CONFIG_CUSTOMIZATION]?.[consts.CUSTOMIZATION_THEME_SETTINGS]?.[consts.THEME_ONLINE_BUTTON]
                                                }} className="sb3-text-center">{page[consts.PAGE_NAME]} {(isRequired && !isDone) ? <span 
                                                style={{
                                                    color: activePage?.[consts.PAGE_ID] === page[consts.PAGE_ID] ? "white" : builderSpec?.[consts.BUILDER_CONFIG]?.[consts.CONFIG_CUSTOMIZATION]?.[consts.CUSTOMIZATION_THEME_SETTINGS]?.[consts.THEME_ONLINE_BUTTON]
                                                }}
                                                className="sb3-text-red-500">*</span> : ""}</span>
                                            </BorderedButton>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>

                

                <span className="sb3-pr-2"/>
                </React.Fragment>
            })
            
        }
    </div>

        {
        Utils.checkOverflow(pagesNavRef.current) && (scrollIndicator.left) && 
        (
            <div className="sb3-absolute  sb3-left-0 sb3-z-[1000] sb3-top-0 sb3-h-full sb3-flex sb3-items-end"
                style={{background: builderSpec?.[consts.BUILDER_CONFIG]?.[consts.CONFIG_CUSTOMIZATION]?.[consts.CUSTOMIZATION_THEME_SETTINGS]?.[consts.THEME_BACKGROUND]}}
            >
                <span className="sb3-absolute sb3-left-full sb3-w-20 sb3-h-full sb3-pointer-events-none" 
                style = {{backgroundImage: `linear-gradient(to right, ${builderSpec?.[consts.BUILDER_CONFIG]?.[consts.CONFIG_CUSTOMIZATION]?.[consts.CUSTOMIZATION_THEME_SETTINGS]?.[consts.THEME_BACKGROUND]}, rgba(255, 255, 255, 0))`}}
                />
                <button type="button" className="md:sb3-ml-3 sb3-text-black sb3-z-10 sb3-bg-white sb3-rounded-lg sb3-mb-1 sb3-border sb3-border-solid sb3-p-1.5  focus:!sb3-outline-none" 
                style={{
                    borderColor: builderSpec?.[consts.BUILDER_CONFIG]?.[consts.CONFIG_CUSTOMIZATION]?.[consts.CUSTOMIZATION_THEME_SETTINGS]?.[consts.THEME_SECONDARY]
                }}
                onClick={selectPrev}
                >
                    <FaChevronLeft />
                </button>
            </div>
        )}
        {Utils.checkOverflow(pagesNavRef.current) && (scrollIndicator.right) && (
            <div className="sb3-absolute sb3-right-0 sb3-z-[1000] sb3-top-0 sb3-h-full sb3-flex sb3-items-end"
                style={{background: builderSpec?.[consts.BUILDER_CONFIG]?.[consts.CONFIG_CUSTOMIZATION]?.[consts.CUSTOMIZATION_THEME_SETTINGS]?.[consts.THEME_BACKGROUND]}}
            >
                <span className="sb3-absolute sb3-right-full sb3-w-20 sb3-h-full sb3-pointer-events-none" 
                    style = {{backgroundImage: `linear-gradient(to right, rgba(255, 255, 255, 0), ${builderSpec?.[consts.BUILDER_CONFIG]?.[consts.CONFIG_CUSTOMIZATION]?.[consts.CUSTOMIZATION_THEME_SETTINGS]?.[consts.THEME_BACKGROUND]})`}}
                />
                <button type="button" className="sb3-text-black md:sb3-mr-3 sb3-z-10 sb3-bg-white sb3-rounded-lg sb3-mb-1 sb3-border sb3-border-solid sb3-p-1.5  focus:!sb3-outline-none" 
                style={{
                    borderColor: builderSpec?.[consts.BUILDER_CONFIG]?.[consts.CONFIG_CUSTOMIZATION]?.[consts.CUSTOMIZATION_THEME_SETTINGS]?.[consts.THEME_SECONDARY]
                }}
                onClick={selectNext}
                >
                    <FaChevronRight />
                </button>
            </div>
        )}
    </div>

    )
}
