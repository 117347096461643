import Utils from "Services/Utils"
import BorderedButton from "components/Button/BorderedButton"
import Title from "components/Header/Title"
import SvgLoader from "components/SVGLoader"
import TooltipInfo from "components/TooltipInfo"
import { BUILDER_CONFIG, CHOICE_DISPLAY_VALUE, CHOICE_ID, CHOICE_IMAGE, CHOICE_IS_EXCLUDED, CHOICE_NAME, CONFIG_CUSTOMIZATION, CUSTOMIZATION_THEME_SETTINGS, FRMDATA_SMID, MODEL_DYNAMIC_FIELDS, PAGE_FORM_DATA, EXCLUSION_OPTIONS, PLACEHOLDER_IMAGE, SORT, THEME_PLATFORMS, THEME_SECONDARY, THEME_PRIMARY_COLOR, trex_obj, CHOICE_ADDITIONAL_DATA, CHOICE_HELP_TEXT, INFO_DISPLAY, THEME_FILL_BUTTON_BG, CHOICE_PREFERENCE, ZIP_CODE_FIELD, TREX_LEVEL_FIELD_IDS } from "constants/constants"
import { useAppContext } from "contexts/AppContext"
import NodeHelper from "helpers/NodeHelper"
import { isArray } from "lodash"
import { FaCheck } from "react-icons/fa"
import { TChoice, TInfoText } from "types"
import DeckPricingCard from "./DeckPricingCard"
import { useSearchParams } from "react-router-dom"

type MyProps = {
    id: string,
    name: string,
    value?: TChoice[]
    onChange: (value?: string[]|string) => void
    disabled?: boolean
    required?: boolean
    items?: TChoice[]
    isFilter?: boolean
    isMulti?: boolean
    svg?: string
    type?: "big"|"small"|"extrabig",
    description?: string
    image?: string
    sort?: boolean
    info?: TInfoText
    exclusionBehaviour?: string
    dataLoading: boolean
    showLabel?: boolean
    isGrouped?: boolean
    imageFit?: "contain" | "cover"
    captionAlignment?: "left" | "center" | "right"
    imageLayout?: "Padded Frame" | "Full Bleed Frame"
}

const SmallGridChoice = (props: MyProps) => {
    const {
        theme, 
        selectedValues,
        builderSpec,
        platform,
    } = useAppContext()

    const [URLSearchParams, ] = useSearchParams()

    if(!props.items) return <>No values found</>

    const handleSelect = (choice: TChoice, isSelected: boolean) => {
        /**
         * if value is already selected remove it from the array
         * and pass it to the select function else vice versa
         */
        if(!props.onChange) return

        if(!props.isMulti) {
            props.onChange(isSelected ? [] : choice[CHOICE_ID])
            return
        }

        const newValues = isArray(props.value) ? [...NodeHelper.getChoicesIds(props.value)] : []
        if(isSelected){
            const index = newValues.indexOf(choice[CHOICE_ID])
            if(index !== -1) newValues.splice(index, 1)
        }
        else{
            newValues.push(choice[CHOICE_ID])
        }
        props.onChange(newValues)
    }

    const valuesIds = props.value ? NodeHelper.getChoicesIds(props.value) : []

    // const allDisabled = Utils.checkIfAllChoicesAreDisabled(props.items)

    if(props.items?.length === 0) return (
        <div className="sb3-space-y-2">
            {(!Utils.isConfigurator(theme) || props.type !== "small") && <Title title={props.name} required={props.required} showLabel={props.showLabel} description={props.description} info={props.info} id={props.id}/>}
            <p className="sb3-text-gray-extraDark sb3-text-center sb3-text-sm">No options</p>
        </div>
    )

    // const ifAnyVisibleCategory = activePage?.[PAGE_FORM_DATA]?.some((uiModel, modelKey) => {
    //     const _instances = getInstances(uiModel[FRMDATA_SMID]) ?? []
    //     return _instances.some((instance, key) => {
    //         const visibleNodes = NodeHelper.filterVisibleNodesUsingRuntime(instance[MODEL_DYNAMIC_FIELDS], uiModel)
    //         return visibleNodes.visibleCategoryIds.length > 0
    //     })
    // })

    /**
     * if atleast one value has tooltip on outside 
     * the sizes has to be consistent so placeholder
     * is kept to take up space
     */
    const atleastOneInfoOutside = props.items
        ?.filter((item: TChoice) => !item[CHOICE_IS_EXCLUDED] || props.exclusionBehaviour !== EXCLUSION_OPTIONS.HIDE)
        .some(item => item[CHOICE_HELP_TEXT]?.[INFO_DISPLAY] === "outside" && !!item[CHOICE_HELP_TEXT])


    const findZipCode = () => {
        const zipCodeKey = selectedValues && Object.keys(selectedValues).find(key => key.includes(ZIP_CODE_FIELD));
        return zipCodeKey ? selectedValues?.[zipCodeKey][0] : null;
    };

    const getPriorityIndex = (item: TChoice) => {
        const zipCode = findZipCode()
        if (!zipCode || !(CHOICE_PREFERENCE as any)[(zipCode as string)]) return -1;
        const index = (CHOICE_PREFERENCE as any)[(zipCode as string)].indexOf(item[CHOICE_NAME]);
        return index === -1 ? Number.MAX_VALUE : 0;
    };


    return (
        <>
        {(!Utils.isConfigurator(theme) || props.type !== "small") && <Title title={props.name} required={props.required} description={props.description} info={props.info} showLabel={props.showLabel} id={props.id}/>}

        <div className={TREX_LEVEL_FIELD_IDS.includes(props.id) ? "sb3-grid sb3-grid-cols-5 sb3-gap-3" : (props.type !== "small" && Utils.isConfigurator(theme) && (!props.isGrouped || props.type === "extrabig")) ? `sb3-grid sb3-grid-cols-[repeat(auto-fill,_minmax(160px,_1fr))] md:sb3-grid-cols-[repeat(auto-fill,_minmax(200px,_1fr))] sb3-gap-3` : `sb3-flex ${props.type === "small" ? "sb3-gap-1" : "sb3-gap-3" } sb3-flex-wrap sb3-items-center`}>
            {
                props.items
                ?.filter((item: TChoice) => !item[CHOICE_IS_EXCLUDED] || props.exclusionBehaviour !== EXCLUSION_OPTIONS.HIDE)
                .sort(
                    (props.sort || props.exclusionBehaviour === EXCLUSION_OPTIONS.DISABLE_WITH_SEPERATOR_ORDER) ? 
                    Utils.sortChoices(
                        true, 
                        (props.exclusionBehaviour === EXCLUSION_OPTIONS.DISABLE_WITH_SEPERATOR_ORDER && props.sort) ? SORT.SEPERATE_ASC :
                        (props.sort ?  SORT.ASC : undefined), (item: any) => !!item[CHOICE_DISPLAY_VALUE] ? item[CHOICE_DISPLAY_VALUE]: item[CHOICE_NAME], 
                        (item: any) => item[CHOICE_IS_EXCLUDED]
                    ) as any : undefined
                )
                .sort((a, b) => {
                    const zipCode = findZipCode();
                    // If we have a matching ZIP code with preferences
                    if (zipCode && (CHOICE_PREFERENCE as any)[(zipCode as string)]) {
                        const priorityA = getPriorityIndex(a);
                        const priorityB = getPriorityIndex(b);
                        
                        // If both items are in preferences, sort by their order
                        if (priorityA !== -1 && priorityB !== -1) {
                            return priorityA - priorityB;
                        }
                        // If only one item is in preferences, prioritize it
                        if (priorityA !== -1) return -1;
                        if (priorityB !== -1) return 1;

                        return 0
                    }
                    return 0
                })
                .map((item, key) => {
                    const _selected = valuesIds?.includes(item?.[CHOICE_ID])

                    return (
                        <div className={`sb3-flex sb3-items-start ${props.isGrouped && !props.isFilter && "sb3-p-1"}`} key={item?.[CHOICE_ID] + key}>
                            <BorderedButton 
                                onClick={() => !props.dataLoading && handleSelect(item, _selected)} 
                                key={item?.[CHOICE_ID] + key}
                                disabled={item[CHOICE_IS_EXCLUDED] || props.disabled} 
                                className={`${TREX_LEVEL_FIELD_IDS.includes(props.id) && (URLSearchParams.get('modelId') ?? URLSearchParams.get('projectId')) === "7a1daedcf9b011ef8c5c87d67c0a6251" ? "!sb3-p-0 sb3-h-full sb3-min-h-full" : props.type === "extrabig" ? "sb3-text-center" : "sb3-space-x-3 "} ${(props.type === "small" ? "!sb3-py-1 !sb3-text-xs !sb3-min-h-min !sb3-min-w-[58px] sb3-justify-center" : (
                                    (props.type !== "extrabig" && ((Utils.isConfigurator(theme) ? "sb3-py-2 sb3-px-1 md:sb3-px-3 " : "!sb3-py-2 !sb3-px-5 ")) + '!sb3-min-w-[160px] md:!sb3-min-w-[190px] !sb3-text-sm !sb3-text-left'))
                                    )} 
                                    ${(props.type !== "small" && "sb3-justify-between")}
                                    sb3-max-w-full sb3-rounded-none ${!_selected && (Utils.isConfigurator(theme) ? "!sb3-border-black" : "!sb3-border-gray")} sb3-min-w-min sb3-flex  sb3-space-x-3 !sb3-items-center sb3-relative
                                    hover:sb3-shadow-[0px_4px_12px_0px_rgba(0,0,0,0.12)] sb3-transition-shadow !sb3-font-semibold b3-max-w-full sb3-flex-grow sb3-h-full
                                    ${(platform === THEME_PLATFORMS.BIG_COMMERCE && props.type === "big") ? "!sb3-py-0.5" : ""}
                                    ${platform === THEME_PLATFORMS.BIG_COMMERCE ? "!sb3-rounded-lg" : "sb3-rounded-none"}
                                    ${props.imageLayout==="Full Bleed Frame" && props.type === "extrabig" && "!sb3-p-0"}
                                    sb3-h-max
                                `}
                                active={_selected}
                                isDisabledClickable={true}
                                // buttonStyle={"primary"}
                                opacity = {platform !== THEME_PLATFORMS.BIG_COMMERCE && 0.3}
                                style={{backgroundColor:builderSpec?.[BUILDER_CONFIG]?.[CONFIG_CUSTOMIZATION]?.[CUSTOMIZATION_THEME_SETTINGS]?.[THEME_SECONDARY] && platform === THEME_PLATFORMS.BIG_COMMERCE && !_selected &&
                                    builderSpec[BUILDER_CONFIG][CONFIG_CUSTOMIZATION][CUSTOMIZATION_THEME_SETTINGS][THEME_SECONDARY]}}
                            >
                            <span className={`${props.type === "extrabig" ? "sb3-mx-auto sb3-flex-grow sb3-flex-col sb3-justfify-between" : "sb3-space-x-2 sb3-items-center "} sb3-flex sb3-max-w-full ${(props.type !== "small" && props.type !== "extrabig") ? "sb3-h-12 sb3-text-left" : "sb3-text-center"}`}>
                                {/* section 1 - radio or checkbox */}
                                {
                                    // radio
                                    !props.isMulti && props.type === "extrabig" && <span className={`sb3-w-5 sb3-h-5 sb3-p-0.5 sb3-aspect-square sb3-border sb3-border-solid sb3-absolute sb3-left-2 sb3-top-2 sb3-rounded-full 
                                        ${!item[CHOICE_IS_EXCLUDED] ? "sb3-bg-white" : ''} sb3-border-black 
                                        ${(platform === THEME_PLATFORMS.BIG_COMMERCE) ? 'sb3-rounded':'sb3-rounded-none' }
                                        `}
                                        style = {{
                                            backgroundColor: _selected ? builderSpec?.[BUILDER_CONFIG]?.[CONFIG_CUSTOMIZATION]?.[CUSTOMIZATION_THEME_SETTINGS]?.[THEME_PRIMARY_COLOR] : undefined,
                                            borderColor: _selected ? builderSpec?.[BUILDER_CONFIG]?.[CONFIG_CUSTOMIZATION]?.[CUSTOMIZATION_THEME_SETTINGS]?.[THEME_PRIMARY_COLOR] : undefined,
                                        }}
                                    >
                                        {_selected && <span><FaCheck className="sb3-text-white"/></span>}
                                    </span>

                                }
                                {
                                    // checkbox
                                    props.isMulti && props.type !== "small" && <span className={`sb3-w-5 sb3-h-5 sb3-p-0.5 sb3-aspect-square sb3-border
                                        ${props.type === "extrabig" && "sb3-absolute sb3-left-2 sb3-top-2"} 
                                        ${!item[CHOICE_IS_EXCLUDED] ? "sb3-bg-white" : ''} sb3-border-black
                                        ${(platform === THEME_PLATFORMS.BIG_COMMERCE) ? 'sb3-rounded':'sb3-rounded-none' }
                                        `}
                                        style = {{
                                            backgroundColor: _selected ? builderSpec?.[BUILDER_CONFIG]?.[CONFIG_CUSTOMIZATION]?.[CUSTOMIZATION_THEME_SETTINGS]?.[THEME_PRIMARY_COLOR] : undefined,
                                            borderColor: _selected ? builderSpec?.[BUILDER_CONFIG]?.[CONFIG_CUSTOMIZATION]?.[CUSTOMIZATION_THEME_SETTINGS]?.[THEME_PRIMARY_COLOR] : undefined,
                                        }}
                                    >
                                        {_selected && <span><FaCheck className="sb3-text-white"/></span>}
                                    </span>
                                }

                                {/* section 2 - Image */}
                                {
                                ((!!item[CHOICE_IMAGE] && props.type !== "small") || (props.type === "extrabig")) && <div>
                                    {
                                        item[CHOICE_IMAGE]?.toLowerCase().endsWith('.svg') 
                                            ? <SvgLoader 
                                                url={item[CHOICE_IMAGE]} 
                                                className={`
                                                ${props.type !== "extrabig" ? "sb3-h-10 sb3-w-10 sb3-object-contain" : "sb3-w-full sb3-aspect-square sb3-object-cover"} sb3-min-w-10
                                                ${
                                                    props.imageLayout !== "Full Bleed Frame" && props.type === "extrabig"
                                                    ? (Utils.isConfigurator(theme) ? "sb3-pt-2 sb3-px-3 " : "!sb3-pt-2 !sb3-px-5 ") 
                                                    : ""
                                                }
                                                `}
                                                style={{
                                                objectFit: props.imageFit,
                                                color: _selected ? "white" : builderSpec?.[BUILDER_CONFIG]?.[CONFIG_CUSTOMIZATION]?.[CUSTOMIZATION_THEME_SETTINGS]?.[THEME_FILL_BUTTON_BG]
                                                }}
                                            />
                                            : <img 
                                            key={item[CHOICE_IMAGE] ?? "" + key} 
                                            src={item[CHOICE_IMAGE] ? item[CHOICE_IMAGE] : props.type === "extrabig" ? PLACEHOLDER_IMAGE : ""} 
                                            alt="" 
                                            className={`
                                                ${props.type !== "extrabig" ? "sb3-h-10 sb3-w-10 sb3-object-contain" : "sb3-w-full sb3-aspect-square sb3-object-cover"} sb3-min-w-10
                                                ${
                                                    props.imageLayout !== "Full Bleed Frame" && props.type === "extrabig"
                                                    ? (Utils.isConfigurator(theme) ? "sb3-pt-2 sb3-px-3 " : "!sb3-pt-2 !sb3-px-5 ") 
                                                    : ""
                                                }
                                            `}
                                            style={{
                                                objectFit: props.imageFit,
                                            }}
                                        />
                                    }
                                    
                                    </div>
                                }
                                {/* section 3 - title and tooltip */}
                                <span className={`sb3-flex sb3-items-center sb3-space-x-1 
                                    ${ props.type === "extrabig" && ("sb3-pt-3.5 sb3-justify-center")}
                                    ${props.imageLayout==="Full Bleed Frame" && props.type === "extrabig" && "sb3-pb-1"}
                                     `}>
                                    <p 
                                        style={{
                                            color:_selected ? "white" : builderSpec?.[BUILDER_CONFIG]?.[CONFIG_CUSTOMIZATION]?.[CUSTOMIZATION_THEME_SETTINGS]?.[THEME_FILL_BUTTON_BG],
                                            textAlign: props.captionAlignment ?? "left",
                                        }}
                                        data-tooltip-id="sb3-shared-tooltip"
                                        data-tooltip-content={!!item[CHOICE_DISPLAY_VALUE] ? item[CHOICE_DISPLAY_VALUE]: item[CHOICE_NAME]} 
                                        className={`sb3-overflow-hidden sb3-break-words sb3-whitespace-normal sb3-text-wrap sb3-m-0 sb3-space-x-1 sb3-font-normal sb3-text-sm
                                         ${ !TREX_LEVEL_FIELD_IDS.includes(props.id) && props.type === "extrabig" ? "sb3-flex-grow sb3-line-clamp-1 " : "sb3-line-clamp-2"} words-break
                                        ${
                                            props.type === "extrabig" ? 
                                            ((Utils.isConfigurator(theme) ? "sb3-pb-2 sb3-px-3 " : "!sb3-pb-2 !sb3-px-5 "))
                                            : ""
                                            }
                                        `}>
                                        {((!!item[CHOICE_DISPLAY_VALUE] ? item[CHOICE_DISPLAY_VALUE]: item[CHOICE_NAME]).replaceAll('&', '&\u200B').replaceAll('_', '_\u200B') )}
                                    </p>
                                    {
                                        item[CHOICE_HELP_TEXT]?.[INFO_DISPLAY] !== "outside" 
                                        && !!item[CHOICE_HELP_TEXT] 
                                        && <span ><TooltipInfo 
                                        iconStyle= {{ stroke: _selected ? "white" : builderSpec?.[BUILDER_CONFIG]?.[CONFIG_CUSTOMIZATION]?.[CUSTOMIZATION_THEME_SETTINGS]?.[THEME_FILL_BUTTON_BG]}}
                                        data={item[CHOICE_HELP_TEXT]}  id={`choice-${props.id}-${item[CHOICE_ID]}`} title={(!!item[CHOICE_DISPLAY_VALUE] ? item[CHOICE_DISPLAY_VALUE]: item[CHOICE_NAME])}/></span>
                                    }
                                </span>
                                {
                                TREX_LEVEL_FIELD_IDS.includes(props.id) &&
                                <DeckPricingCard
                                    budget={(trex_obj as any)[item[CHOICE_NAME]].budget}
                                    tier={(trex_obj as any)[item[CHOICE_NAME]].tier}
                                    warranty={(trex_obj as any)[item[CHOICE_NAME]].warranty}
                                    scratchResistance={(trex_obj as any)[item[CHOICE_NAME]].scratchResistance}
                                    costAfterYear={(trex_obj as any)[item[CHOICE_NAME]].costAfterYear}
                                    specialFeature={(trex_obj as any)[item[CHOICE_NAME]].specialFeature}
                                    img={(trex_obj as any)[item[CHOICE_NAME]].img}
                                    projectId={URLSearchParams.get('modelId') ?? URLSearchParams.get('projectId')}
                                />
                            }
                            </span>
                            
                            {/* section 4 - check mark (if size is big) */}
                            {
                            _selected && props.type !== "small" && props.type !== "extrabig" && <FaCheck 
                                className="sb3-aspect-square sb3-w-7"
                                style={{fill:_selected ? "white" : builderSpec?.[BUILDER_CONFIG]?.[CONFIG_CUSTOMIZATION]?.[CUSTOMIZATION_THEME_SETTINGS]?.[THEME_FILL_BUTTON_BG]}}
                                />
                            }
                            {!getPriorityIndex(item) && <span className="sb3-absolute sb3-top-0 sb3-right-0 sb3-text-white sb3-bg-danger sb3-px-2 sb3-text-xs">Most popular</span>}
                        </BorderedButton>
                        {/* tooltip outside */}
                        {   
                            atleastOneInfoOutside &&
                            <span className="sb3-w-5 sb3-min-w-5"
                            style= {{ color: builderSpec?.[BUILDER_CONFIG]?.[CONFIG_CUSTOMIZATION]?.[CUSTOMIZATION_THEME_SETTINGS]?.[THEME_FILL_BUTTON_BG]}}
                            >{
                                item[CHOICE_HELP_TEXT]?.[INFO_DISPLAY] === "outside" 
                                && !!item[CHOICE_HELP_TEXT] 
                                && <TooltipInfo 
                                iconStyle= {{ stroke: builderSpec?.[BUILDER_CONFIG]?.[CONFIG_CUSTOMIZATION]?.[CUSTOMIZATION_THEME_SETTINGS]?.[THEME_FILL_BUTTON_BG]}}
                                data={item[CHOICE_HELP_TEXT]}  id={`choice-${props.id}-${item[CHOICE_ID]}`} title={(!!item[CHOICE_DISPLAY_VALUE] ? item[CHOICE_DISPLAY_VALUE]: item[CHOICE_NAME])}/> 
                            } </span>
                        }
                    </div>
                )})
            }
        </div>
        </>
    )
}

export default SmallGridChoice