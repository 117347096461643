import React, { useState, useRef, useEffect } from 'react';
import { HiOutlineInformationCircle } from "react-icons/hi";
import { BsPinAngle, BsTextareaResize } from "react-icons/bs";
import { Tooltip } from "react-tooltip";
import Utils from "Services/Utils";
import { useAppContext } from 'contexts/AppContext';
import { BUILDER_CONFIG, CONFIG_CUSTOMIZATION, CUSTOMIZATION_THEME_SETTINGS, THEME_FILL_BUTTON_BG } from 'constants/constants';
import { TInfoText } from 'types';
import useResponsiveTooltip from 'hooks/useResizable';

type MyProps = {
  data?: TInfoText,
  id: string,
  title: string,
  iconStyle?: React.CSSProperties
}


const TooltipInfo = ({ data, id, title, iconStyle }: MyProps) => {
  const { builderSpec, createNewPopup } = useAppContext();
  const tooltipId = id+Utils.generate_file_name(5);
  const iconRef = useRef<HTMLSpanElement>(null);
  const [modifiedMessage, setModifiedMessage] = useState('');

  const {
    size,
    position,
    placement,
    setIsResizing,
    tooltipRef,
    initializeTooltip
  } = useResponsiveTooltip(data?.size ?? "auto", iconRef);

  useEffect(() => {
    if (!data || !data.info) return setModifiedMessage('');
    setModifiedMessage(Utils.modifyAnchors(data.info));
  }, [data?.info]);

  if (!data || !modifiedMessage) return <></>;

  const handleIconClick: React.MouseEventHandler<SVGElement> = (e) => {
    e.stopPropagation();
    if ((!!data?.openInNewWindow && data.trigger === "click") || (window.innerWidth < 640 && data)) {
      e.preventDefault();
      createNewPopup(title, modifiedMessage, id, data.size);
    }
    else if (!data.openInNewWindow && window.innerWidth > 640) {
      (document.querySelector('.sb3-tooltip-clickable') as HTMLSpanElement)?.click()
    }
  };

  const handleIconHover: React.MouseEventHandler<SVGElement> = (e) => {
    if (!!data?.openInNewWindow && data.trigger === "hover") {
      e.preventDefault();
      createNewPopup(title, modifiedMessage, id, data.size);
    }
  };

  return (
    <span className="sb3-pointer-events-none" ref={iconRef}>
      <span className='sb3-hidden sb3-tooltip-clickable'></span>
      <HiOutlineInformationCircle
        className="sb3-text-primary !sb3-text-lg sb3-pointer-events-auto focus:sb3-outline-none sb3-fill-none"
        data-tooltip-id={`delta-table-${tooltipId}`}
        onClick={handleIconClick}
        onMouseEnter={handleIconHover}
        style={{
          color: builderSpec?.[BUILDER_CONFIG]?.[CONFIG_CUSTOMIZATION]?.[CUSTOMIZATION_THEME_SETTINGS]?.[THEME_FILL_BUTTON_BG],
          stroke: builderSpec?.[BUILDER_CONFIG]?.[CONFIG_CUSTOMIZATION]?.[CUSTOMIZATION_THEME_SETTINGS]?.[THEME_FILL_BUTTON_BG],
          ...iconStyle
        }}
      />
      {!data.openInNewWindow && window.innerWidth > 640 && (
        <Tooltip
          className="!sb3-text-white sb3-pointer-events-none sb3-break-normal sb3-z-20 sb3-p-0.5 sb3-whitespace-normal sb3-shadow-lg"
          clickable={true}
          place={placement}
          id={`delta-table-${tooltipId}`}
          style={{
            position: 'fixed',
            background: "white",
            color: "black",
            width: size.width,
            height: size.height,
            zIndex: 10002
          }}
          border="1px solid #c5c5ca"
          opacity={1}
          delayShow={500}
          events={data.trigger === "click" ? ['click'] : []}
          afterShow={initializeTooltip}

          // Disable internal positioning
          offset={0}
          positionStrategy="fixed"
        >
          {
                  //placement === 'left' ? 'sb3-right-full sb3-translate-x-1/2' : 'sb3-left-full -sb3-translate-x-1/2 '
                  // ${placement === 'left' ? 'sb3-right-full sb3-translate-x-full ' : 'sb3-left-full -sb3-translate-x-full '}
                  }
            <span
              onClick={() => createNewPopup(title, modifiedMessage, id, data.size)}
              className={`sb3-absolute sb3-p-0.5 sb3-text-xs sb3-top-0 
                  
                  sb3-left-full -sb3-translate-x-1/2 
                 -sb3-translate-y-1/2 sb3-border sb3-border-black sb3-rounded-full sb3-text-black sb3-bg-gray sb3-cursor-pointer sb3-pointer-events-auto`}
            >
              <BsPinAngle />
            </span>
            <div
              ref={tooltipRef}
              className="sb3-text-black sb3-pointer-events-auto sb3-overflow-auto sb3-w-full sb3-h-full sb3-max-h-full sb3-max-w-full"
            >
              <div
                className="react-tooltip info-text"
                dangerouslySetInnerHTML={{ __html: modifiedMessage }}
              />
            </div>
            <BsTextareaResize
              className={`sb3-absolute sb3-bottom-0 sb3-left-full -sb3-translate-x-full  sb3-text-black sb3-bg-white sb3-text-xl sb3-cursor-se-resize sb3-pointer-events-auto`}
              onMouseDown={() => setIsResizing(true)}
            />
        </Tooltip>
      )}
    </span>
  );
};

export default TooltipInfo;