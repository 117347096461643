import {
    BUILDER_CONFIG,
    CONFIG_CUSTOMIZATION,
    CUSTOMIZATION_THEME_SETTINGS,
    FRMDATA_SMID,
    MODEL_DATA,
    MODEL_SUB_MODEL,
    MODEL_SUBMODEL_ID,
    PAGE_FORM_DATA,
    SORT,
    THEME_FILL_BUTTON_BG,
    THEME_FILL_BUTTON_CLR,
    THEME_ONLINE_BUTTON,
    THEME_PLATFORMS,
    THEME_PRIMARY_COLOR,
    THEME_SECONDARY, THEME_SUBTITLES
} from "constants/constants"
import { useAppContext } from "contexts/AppContext"
import CompatioAnalytics from "helpers/CompatioAnalyticsSb3"
import NodeHelper from "helpers/NodeHelper"
import { Dispatch, SetStateAction } from "react"
import Select, {components} from 'react-select'
import Utils from "Services/Utils"
import {TModel, TUI_Node} from "types"

const options = [
    { value: SORT.RECOMMENDED, label: 'Recommended', selected: true },
    { value: SORT.PRICE_ASC, label: 'Price (low to high)' },
    { value: SORT.PRICE_DESC, label: 'Price (high to low)' },
    { value: SORT.ALPHABETICAL_ASC, label: 'Alphabetical (A > Z)' },
    { value: SORT.ALPHABETICAL_DESC, label: 'Alphabetical (Z < A)' },
]

type MyProps = {
    category?: TUI_Node | null
    categorySort: string
    setCategorySort: Dispatch<SetStateAction<string>>
}

const CustomControl = ({ children, ...props }: any) => {
    const {
      platform,
      builderSpec
  } = useAppContext()

    return (
      <components.Control {...props}>
        <span className="sb3-text-gray-500 sb3-ml-2 sb3-font-bold"
        style={{color: platform === THEME_PLATFORMS.BIG_COMMERCE ? builderSpec?.[BUILDER_CONFIG]?.[CONFIG_CUSTOMIZATION]?.[CUSTOMIZATION_THEME_SETTINGS]?.[THEME_FILL_BUTTON_BG] : undefined}}
        >Sort By: </span>
        {children}
      </components.Control>
    );
  };

const SB2Sort = (props: MyProps) => {
    const {
        sort,
        setSort,
        activeSubmodel,
        builderConfig,
        activePage,
        specs,
        modelId,
        modelSpec,
        platform,
        builderSpec
    } = useAppContext()

    const sortFun = props.setCategorySort ?? setSort

    const handleSortChange = (val: typeof options[number]) => {
        const config = builderConfig()?.compatibleProducts

        sortFun(prev => {
           if(props.category) CompatioAnalytics.track(
                CompatioAnalytics.events.xrsSortByChange,
                {
                  baseFinalProductID: Utils.getClientProductSku() ?? "",
                  baseParentProductID: Utils.getClientProductSku() ?? "",
                  baseFinalProductName: (config?.productName && decodeURIComponent(config.productName)) ?? "",
                  categoryID: props.category?.id,
                  categoryName: props.category?.categoryName,
                  categoryPosition: 0,
                  oldSortOrder: prev,
                  newSortOrder: val.value,
                },
                undefined,
                'V1',
                modelSpec?.project.ISRName,
                modelId
            );

            return val.value
        })
    } 

    return <Select 
        components={{ Control: CustomControl }}
        options={options} 
        value={
            options.find(val => val.value === (props.categorySort ?? sort)) ?? { value: SORT.RECOMMENDED, label: 'Recommended', selected: true }
        } 
        onChange={(val: any) => handleSortChange(val)} 
        isSearchable={false} 
        className="md:sb3-w-[250px] sb3-text-xs sb3-font-bold sb3-w-full sb3-max-w-full"
        classNames={{
            control: () => (' sb3-sort-control'),
        }}
        styles={{
          control: (provided, state) => ({
            ...provided,
              borderRadius: platform === THEME_PLATFORMS.BIG_COMMERCE ? "0.5rem" : 0,
              backgroundColor: builderSpec?.[BUILDER_CONFIG]?.[CONFIG_CUSTOMIZATION]?.[CUSTOMIZATION_THEME_SETTINGS]?.[THEME_SECONDARY] && platform === THEME_PLATFORMS.BIG_COMMERCE ?
                                                  builderSpec[BUILDER_CONFIG][CONFIG_CUSTOMIZATION][CUSTOMIZATION_THEME_SETTINGS][THEME_SECONDARY] : undefined,
              boxShadow: "0",
              borderColor: platform === THEME_PLATFORMS.BIG_COMMERCE ? "#0000" : "#ccc",
              "&:hover": {
                borderColor: platform === THEME_PLATFORMS.BIG_COMMERCE ? "#0000" : "#ccc",
              }
          }),
          option: (provided, state) => ({
            ...provided,
            color: state.isSelected ? (builderSpec?.[BUILDER_CONFIG]?.[CONFIG_CUSTOMIZATION]?.[CUSTOMIZATION_THEME_SETTINGS]?.[THEME_FILL_BUTTON_CLR] ) : '',
            backgroundColor: (builderSpec?.[BUILDER_CONFIG]?.[CONFIG_CUSTOMIZATION]?.[CUSTOMIZATION_THEME_SETTINGS]?.[THEME_SECONDARY] ?
              (!state.isSelected ? (platform === THEME_PLATFORMS.BIG_COMMERCE && builderSpec[BUILDER_CONFIG][CONFIG_CUSTOMIZATION][CUSTOMIZATION_THEME_SETTINGS][THEME_SECONDARY]) : builderSpec[BUILDER_CONFIG][CONFIG_CUSTOMIZATION][CUSTOMIZATION_THEME_SETTINGS][THEME_FILL_BUTTON_BG]) : undefined) || "",
            "&:hover": {
              backgroundColor: builderSpec?.[BUILDER_CONFIG]?.[CONFIG_CUSTOMIZATION]?.[CUSTOMIZATION_THEME_SETTINGS]?.[THEME_FILL_BUTTON_BG] ?
                builderSpec[BUILDER_CONFIG][CONFIG_CUSTOMIZATION][CUSTOMIZATION_THEME_SETTINGS][THEME_FILL_BUTTON_BG] : undefined,
              color: builderSpec?.[BUILDER_CONFIG]?.[CONFIG_CUSTOMIZATION]?.[CUSTOMIZATION_THEME_SETTINGS]?.[THEME_FILL_BUTTON_CLR] ?
                builderSpec[BUILDER_CONFIG][CONFIG_CUSTOMIZATION][CUSTOMIZATION_THEME_SETTINGS][THEME_FILL_BUTTON_CLR] : undefined,
            },
          }),
          dropdownIndicator: (provided, state) => ({
            ...provided,
            color: state.isDisabled ? "#c6cdd6" : builderSpec?.[BUILDER_CONFIG]?.[CONFIG_CUSTOMIZATION]?.[CUSTOMIZATION_THEME_SETTINGS]?.[THEME_ONLINE_BUTTON] && platform === THEME_PLATFORMS.BIG_COMMERCE ?
                builderSpec[BUILDER_CONFIG][CONFIG_CUSTOMIZATION][CUSTOMIZATION_THEME_SETTINGS][THEME_ONLINE_BUTTON] : undefined,
          }),
          menuList: (provided, state) => ({
            ...provided,
            backgroundColor: builderSpec?.[BUILDER_CONFIG][CONFIG_CUSTOMIZATION][CUSTOMIZATION_THEME_SETTINGS][THEME_SECONDARY] && platform === THEME_PLATFORMS.BIG_COMMERCE ? builderSpec[BUILDER_CONFIG][CONFIG_CUSTOMIZATION][CUSTOMIZATION_THEME_SETTINGS][THEME_SECONDARY] : undefined
            // padding: 0
          }),
          indicatorSeparator: () => ({
            display: "none"
          })
        }}
    />
}

export default SB2Sort