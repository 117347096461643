import { useAppContext } from "contexts/AppContext";
import { useEffect } from "react";
import NProgress from 'nprogress'
import Navbar from "components/Navbar";
import Cart from "components/Cart";
import ConfigureModal from "components/ConfigureModal";
import { BUILDER_CONFIG, CONFIG_CUSTOMIZATION, CUSTOMIZATION_NAVIGATION_TYPE, CUSTOMIZATION_SHOW_PAGE_CONTROLS, CUSTOMIZATION_THEME_SETTINGS, DEFN_PROJECT, NAVIGATION_TYPE,PAGE_LAYOUT, PAGE_LAYOUTS, RESULT_PRDT_QTY, RESULT_PRDT_SKU, THEME_FILL_BUTTON_CLR, THEME_PLATFORMS, THEME_SECONDARY, THEME_TITLE } from "constants/constants";
import DisabledModal from "components/DisabledModal";
import {  TdisabledModal } from "types/contexts/AppContext";
import Utils from "Services/Utils";
import BorderedButton from "components/Button/BorderedButton";
import FillButton from "components/Button/FillButton";
import { FaAngleUp, FaSpinner, FaTimes } from "react-icons/fa";
import CloseConfirmModal from "components/CloseModal";
import PagesNavbar from "components/Navbar/PagesNavbar";
import ConfiguratorLayout from "components/Layout/ConfiguratorLayout";
import GuidedSellingLayout from "components/Layout/GuidedSellingLayout";
import ImagePreviewModal from "components/ImagePreviewModal";
import LinearPagesGeneric from "components/Navbar/LinearPagesGeneric";
import usePages from "hooks/usePages";
import FillAnchor from "components/Button/FillAnchor";
import CompatioAnalytics from "helpers/CompatioAnalyticsSb3";
import { Tooltip } from "react-tooltip";
import { useSearchParams } from "react-router-dom";

const TestTheme = () => {
    const {
        filteredModelSpec,
        uiDefn,
        showConfigurations,
        setShowConfigurations,
        disabledModal,
        setDisabledModal,
        updateInstanceInput,
        isOpen,
        setIsOpen,
        isFilterOpen,
        cart,
        getCartTotalPrice,
        activePage,
        modelSpec,
        builderSpec,
        loadingToCart,
        isCloseModalOpen,
        setIsCloseModalOpen,
        loading,
        platform,
        ifAnyVisibleCategory,
        isCartOpen,
        setIsCartOpen,
        handlePrevPage,
        handleNextPage,
        activePageIndex,
        submitEcommerceCart,
        modelId,
        setLoadingToCart,
        handleReset,
        dataLoading,
        isPreview
        } = useAppContext()

    const [URLSearchParams, ] = useSearchParams()
        
    const {
        lastPage
    } = usePages()


    useEffect(() => {
        if(!filteredModelSpec && !uiDefn) {
            NProgress.start()
        }
        else{
            NProgress.done()
        }
        return () => {
            NProgress.done()
        }
    }, [filteredModelSpec, uiDefn])

    useEffect(() => {
        (isFilterOpen || isCartOpen) ? document.querySelector("#scrollableDiv")?.classList.add("!overflow-hidden")
        : document.querySelector("#scrollableDiv")?.classList.remove("!overflow-hidden")

        return () => {
            document.querySelector("#scrollableDiv")?.classList.remove("!overflow-hidden")
        };
    }, [isFilterOpen, isCartOpen])

    // let selectedProducts: TCartItem[] = []

    // if(
    //     activeSubmodel?.[MODEL_SUBMODEL_ID] 
    //     && activeSubmodel.instance 
    //     && specs[activeSubmodel?.[MODEL_SUBMODEL_ID]]?.[MODEL_DATA]?.[MODEL_CATEGORIES]?.[0]?.[CATEGORY_ID]
    // ){
    //     selectedProducts = Utils.getSelectedProductsFromCategoryIds(cart, specs[activeSubmodel[MODEL_SUBMODEL_ID]][MODEL_DATA][MODEL_CATEGORIES].map((cat: TProductCategory) => cat[CATEGORY_ID]), activeSubmodel.instance[RUNTIME_INSTANCE_INSTANCE_ID])
    // }

    // const variants = {
    //     initial: { width: 0, opacity: 0 },
    //     animate: { width: '250px', opacity: 1 },
    //     exit: { width: 0, opacity: 0 }
    // }

    // const visibleNodes = activeSubmodel && NodeHelper.filterVisibleNodesUsingRuntime(activeSubmodel.instance[MODEL_DYNAMIC_FIELDS])

    // const isFilterActive = activeSubmodel && isArray(visibleNodes?.visibleFieldIds) 
    //     && (specs[activeSubmodel[MODEL_SUBMODEL_ID]] as TModel)?.[MODEL_DATA][MODEL_FIELDS]
    //         .filter((field) => visibleNodes.visibleFieldIds.includes(field[FIELD_ID])).length > 0
    //     && specs[activeSubmodel[MODEL_SUBMODEL_ID]]?.[MODEL_DATA][MODEL_CATEGORIES]
    //         ?.some((cat: TProductCategoryField) => 
    //             (activeSubmodel.instance?.[MODEL_DYNAMIC_FIELDS][cat[CATEGORY_ID]][RUNTIME_INSTANCE_MAX_QUANTITY] ?? 1) > Utils.getProductQtyByCategoryId(selectedProducts, cat[CATEGORY_ID]) && 
    //             (activeSubmodel.instance?.[MODEL_DYNAMIC_FIELDS][cat[CATEGORY_ID]][RUNTIME_INSTANCE_MAX_COUNT] ?? 1) > Utils.getUniquePrdtCountByCategoryId(selectedProducts, cat[CATEGORY_ID])
    //         )

    // const isFilterActive = activePage && checkIfPageIsDone(activePage)

    return (
        <>
            {
                loadingToCart &&
                <div className="sb3-fixed  sb3-h-screen sb3-z-[2003] sb3-w-screen sb3-top-0 sb3-left-0 sb3-bg-opacity-70 sb3-bg-black sb3-text-white sb3-flex sb3-flex-col sb3-items-center sb3-font-bold sb3-justify-center sb3-space-y-5">
                    <p className="!sb3-text-white">Stand by while we get your gear together.</p>
                    <FaSpinner className="!sb3-fill-white sb3-animate-spin sb3-text-3xl sb3-text-white"/>
                </div>
            }

            <div className="sb3-z-[1001] sb3-sticky sb3-top-0 sb3-left-0">
                <Navbar/>
            </div>

            { 
                builderSpec?.[BUILDER_CONFIG]?.[CONFIG_CUSTOMIZATION]?.[CUSTOMIZATION_NAVIGATION_TYPE] === NAVIGATION_TYPE.STEPPER &&
                <LinearPagesGeneric/>
            }   
            
            <div className={
                `lg:sb3-max-w-7xl sb3-space-y-3 sb3-max-w-3xl sb3-m-auto sb3-px-3 sb3-relative
                ${builderSpec?.[BUILDER_CONFIG]?.[CONFIG_CUSTOMIZATION]?.[CUSTOMIZATION_NAVIGATION_TYPE] !== NAVIGATION_TYPE.STEPPER && "sb3-py-3 "}
                `
                }>
                {
                    (isCartOpen) &&
                    <div className="sb3-fixed sb3-h-screen sb3-bg-white sb3-min-h-[calc(100%-56px)] sb3-max-h-[calc(100%-56px)] sb3-overflow-auto lg:sb3-hidden sb3-w-full sb3-z-[2000] sb3-top-[56px] sb3-left-0 ">
                        <Cart/>
                    </div>
                }
                { 
                    builderSpec?.[BUILDER_CONFIG]?.[CONFIG_CUSTOMIZATION]?.[CUSTOMIZATION_NAVIGATION_TYPE] !== NAVIGATION_TYPE.STEPPER &&
                    <div className="sb3-flex sb3-justify-between sb3-items-center">
                        <h1 style={{color: builderSpec?.[BUILDER_CONFIG]?.[CONFIG_CUSTOMIZATION]?.[CUSTOMIZATION_THEME_SETTINGS]?.[THEME_TITLE]}} className="sb3-text-primary sb3-model-name">{modelSpec?.[DEFN_PROJECT].ISRName}</h1>

                        { 
                            isOpen !==undefined && platform !== THEME_PLATFORMS.BIG_COMMERCE  && 
                            <BorderedButton onClick={() => setIsCloseModalOpen(true)} className="sb3-close-button sb3-text-sm sb3-px-3 sb3-block !sb3-border-[#707070] hover:!sb3-bg-[#707070] !sb3-text-[#707070] hover:!sb3-text-white sb3-min-w-min sb3-py-0.5 sb3-rounded-none sb3-min-h-min">
                                <span className="sb3-flex sb3-items-center sb3-space-x-2"><span>Close</span> {!Utils.isMagento() && <FaTimes/>}</span>
                            </BorderedButton> 
                        }
                    </div>
                }

            <div className="">
                { 
                    builderSpec?.[BUILDER_CONFIG]?.[CONFIG_CUSTOMIZATION]?.[CUSTOMIZATION_NAVIGATION_TYPE] !== NAVIGATION_TYPE.STEPPER &&
                    <PagesNavbar/>
                }

                
                <main className={`sb3-text-black sb3-max-w-7xl sb3-mx-auto md:sb3-px-3 
                    ${activePage?.[PAGE_LAYOUT] !== PAGE_LAYOUTS.GUIDED_SELLING && 'sb3-flex sb3-justify-between'} 
                    sb3-py-1 sb3-pt-3 sb3-gap-4`
                }>
                    {!activePage && !loading && (
                        <p>
                            Your Build is ready! Please click{" "}
                            <b>Add to Cart</b> to add items to the cart
                        </p>
                    )}

                    {/* left side components */}
                    {
                        activePage?.[PAGE_LAYOUT] === PAGE_LAYOUTS.GUIDED_SELLING ? 
                        <GuidedSellingLayout/>:
                        <ConfiguratorLayout/>
                    }

                {
                    activePage && builderSpec?.[BUILDER_CONFIG]?.[CONFIG_CUSTOMIZATION][CUSTOMIZATION_SHOW_PAGE_CONTROLS] !== false && (activePageIndex !== 0 || lastPage.id !== activePage.id) && 
                    <div className="sb3-fixed lg:sb3-bottom-0 sb3-bottom-[75px] sb3-w-screen sb3-left-0 sb3-z-10 sb3-pointer-events-none lg:sb3-block">
                        <div className="sb3-max-w-7xl sb3-m-auto md:sb3-px-3">
                            <div className={`sb3-w-full sb3-border-t md:sb3-px-3 sb3-grid sb3-grid-cols-2 sb3-gap-2  sm:sb3-flex sm:sb3-items-center sm:sb3-justify-between sb3-pointer-events-auto sb3-bottom-0 sb3-left-0 ${ platform === THEME_PLATFORMS.BIG_COMMERCE ? " lg:sb3-p-5 sb3-p-[6px] lg:sb3-rounded-2xl sb3-rounded-[11px]" : "sb3-bg-white sb3-py-2"}  sb3-w-full`}
                                style={{
                                    backgroundColor:builderSpec?.[BUILDER_CONFIG]?.[CONFIG_CUSTOMIZATION]?.[CUSTOMIZATION_THEME_SETTINGS]?.[THEME_SECONDARY] && 
                                    builderSpec[BUILDER_CONFIG][CONFIG_CUSTOMIZATION][CUSTOMIZATION_THEME_SETTINGS][THEME_SECONDARY],
                                    width: (ifAnyVisibleCategory && window.innerWidth > 1024) ? "calc(100% - 340px)" : "100%"
                                }}
                            >
                                {
                                    modelId && Utils.getGuidanceMessage(modelId) && 
                                    <p className="sb3-block sm:sb3-hidden sb3-text-sm sb3-col-span-2 sb3-text-center">   
                                        {Utils.getGuidanceMessage(modelId)}
                                    </p>
                                }
                                { 
                                    activePageIndex !== 0 ?
                                    <BorderedButton 
                                        className="sb3-px-5 sb3-h-full !sb3-bg-gray sb3-back-button"
                                        onClick={handlePrevPage}
                                        disabled={dataLoading}
                                        disabledClassName="sb3-back-disabled"
                                    >BACK</BorderedButton>
                                    : <span className="sb3-min-w-[92px]"></span>
                                }

                                {
                                    modelId && Utils.getGuidanceMessage(modelId) && 
                                    <p className="sb3-hidden sm:sb3-inline sb3-text-sm">   
                                        {Utils.getGuidanceMessage(modelId)}
                                    </p>
                                }

                                {
                                    lastPage.id !== activePage.id ?
                                    <FillButton 
                                        className="sb3-px-5 sb3-h-full sb3-next-button"
                                        onClick={() => handleNextPage(true)}
                                        disabled={dataLoading}
                                    >NEXT</FillButton>
                                    : <span className="sb3-min-w-[92px]"></span>
                                }
                            </div>
                        </div>
                    </div>
                }

               

                </main>
                 <div className="sb3-min-h-[300px] sb3-h-[200px]"/>
                </div>
                <ConfigureModal
                    showModal={showConfigurations}
                    closeModal={() => setShowConfigurations(null)}
                    onConfirm={() => setShowConfigurations(null)}
                    loading={false}
                />

                <DisabledModal
                    showModal={disabledModal}
                    closeModal={() => setDisabledModal(null)}
                    onConfirm={() => updateInstanceInput((disabledModal as TdisabledModal).fieldId, (disabledModal as TdisabledModal).items, disabledModal?.instanceId, true, false, false, disabledModal?.item)}
                    loading={false}
                />
            </div>

            <div className="sb3-fixed sb3-bottom-0 sb3-bg-white sb3-border-t sb3-z-10 sb3-py-3 sb3-left-0 sb3-w-screen lg:sb3-hidden sb3-min-h-12">
                <div className="sb3-flex sb3-justify-between sb3-items-center sm:sb3-px-5 sb3-px-3.5 ">
                    <div>
                        <p className="sb3-text-sm sb3-font-semibold sb3-space-x-2 sb3-inline-flex">
                            <span>Your {(isPreview && URLSearchParams.get('modelId') === "c7055620000211f0874f1e1b9b2453d0") ? "Bundle" : "Cart"}</span> <span className="sb3-block"><span className="sb3-p-0.5 sb3-aspect-square sb3-bg-[#EDEDED] sb3-text-sm sb3-rounded-full sb3-border-b">{cart.length}</span></span>
                        </p>
                        <p className="!sb3-text-base sb3-font-semibold">
                            <span className="sb3-text-[#707070]">Subtotal: {Utils.formatPrice(getCartTotalPrice())}</span> 
                        </p>
                    </div>

                    <FillButton onClick={() => setIsCartOpen(prev => !prev)}>
                        <span className="sb3-flex sb3-items-center sb3-space-x-2">
                            <FaAngleUp style={{fill: builderSpec?.[BUILDER_CONFIG]?.[CONFIG_CUSTOMIZATION]?.[CUSTOMIZATION_THEME_SETTINGS]?.[THEME_FILL_BUTTON_CLR]}} className="!sb3-text-lg"/> 
                            <span style={{color: builderSpec?.[BUILDER_CONFIG]?.[CONFIG_CUSTOMIZATION]?.[CUSTOMIZATION_THEME_SETTINGS]?.[THEME_FILL_BUTTON_CLR]}}>View Build</span>
                        </span>
                    </FillButton>
                </div>
                
                {
                    !activePage && !loading && Utils.checkIfClientWebsite() && 
                    <FillAnchor 
                        id="addoutfittocart"  
                        className="sb3-w-full dc-outfit-button sb3-cursor-pointer sb3-theme-add-to-cart"
                        productsadded={cart?.map(product => product[RESULT_PRDT_SKU]).join(",")}
                        productsquantity={cart.map(product => product[RESULT_PRDT_QTY]).join(",")}
                        recommendationsource="Configure"
                        data-userid={CompatioAnalytics.getUserId()}
                        onClick={submitEcommerceCart}
                        modelVersion="V1"
                        reelid="-1"
                        modelid={modelId}
                        modelshortname={modelSpec?.project.ISRName}
                        buttonStyle="primary"
                    >ADD TO CART</FillAnchor>
                }
                
            </div>

            <a
                id="addoutfittocartclear"
                className="addoutfittocartclear"
                onClick={() => {
                    setLoadingToCart(false)
                    handleReset(false)
                    if(setIsOpen) setIsOpen(false)
                }}
            ></a>

            <CloseConfirmModal
                isOpen={isCloseModalOpen}
                closeModal={() => setIsCloseModalOpen(false)}
                onConfirm={() => setIsOpen?.(false)}
            />

            <Tooltip 
                id="sb3-shared-tooltip" 
                className="sb3-text-sm sb3-bg-gray-800 sb3-px-1 sb3-py-0.5 sb3-rounded"
                place="bottom"
                delayShow={400}
            />

            <ImagePreviewModal/>            
        </>
    );
}

export default TestTheme