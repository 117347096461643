import ReactSelect, { GroupBase, MultiValue, OptionProps, SingleValue, StylesConfig, components } from "react-select";
import Title from "../Header/Title";
import { TChoice, TInfoText } from "types";
import { BUILDER_CONFIG, CHOICE_DISPLAY_VALUE, CHOICE_ID, CHOICE_IS_EXCLUDED, CHOICE_NAME, CONFIG_CUSTOMIZATION, CUSTOMIZATION_THEME_SETTINGS, EXCLUSION_OPTIONS, SORT, THEME_FILL_BUTTON_BG, THEME_FILL_BUTTON_CLR, THEME_ONLINE_BUTTON, THEME_PLATFORMS, THEME_SECONDARY } from "constants/constants";
import Utils from "Services/Utils";
import NodeHelper from "helpers/NodeHelper";
import { useAppContext } from "contexts/AppContext";
import { PropsWithChildren } from "react";

type TOption = {
    label: string, value: string, disabled: boolean
}

type MyProps = {
    name: string
    value?: TChoice[]
    onChange: (value?: string[]|string) => void
    disabled?: boolean
    required?: boolean
    items?: TChoice[]
    isFilter?: boolean
    isMulti?: boolean
    description?: string
    info?: TInfoText
    sort?: boolean
    exclusionBehaviour?: string
    dataLoading: boolean
    showLabel?: boolean
    id: string
}

// Custom Option component that overrides the default behavior
const CustomOption = ({ children, ...props }: PropsWithChildren<OptionProps<any, boolean, GroupBase<any>>>) => {
  // Extract isDisabled from props to handle it separately
  const { disabled } = props.data;
  // Remove the isDisabled property from props to prevent react-select's default behavior
  const newProps = {
    ...props,
    disabled: false,
    data: {
      ...props.data,
      disabled: false // This makes all options clickable
    }
  };
    
  return (
    <components.Option {...newProps}>
      <div className={disabled ? "sb3-text-gray-400" : ""}>
        {children}
      </div>
    </components.Option>
  );
};

const Select = (props: MyProps) => {
  const {platform, builderSpec} =  useAppContext()
  const valuesIds = props.value ? NodeHelper.getChoicesIds(props.value) : []

  const allDisabled = props.items?.length === 0 || props.items?.every((choice: TChoice) => choice[CHOICE_IS_EXCLUDED]) || props.disabled

  const colourStyles: StylesConfig<TOption> = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      console.log(isDisabled)
      return {
        ...styles,
        color: isDisabled ? "grey" : isSelected ? (builderSpec?.[BUILDER_CONFIG]?.[CONFIG_CUSTOMIZATION]?.[CUSTOMIZATION_THEME_SETTINGS]?.[THEME_FILL_BUTTON_CLR] ) : '',
        cursor: 'pointer',
        backgroundColor: (builderSpec?.[BUILDER_CONFIG]?.[CONFIG_CUSTOMIZATION]?.[CUSTOMIZATION_THEME_SETTINGS]?.[THEME_SECONDARY] ?
          (!isSelected ? (platform === THEME_PLATFORMS.BIG_COMMERCE && builderSpec[BUILDER_CONFIG][CONFIG_CUSTOMIZATION][CUSTOMIZATION_THEME_SETTINGS][THEME_SECONDARY]) : builderSpec[BUILDER_CONFIG][CONFIG_CUSTOMIZATION][CUSTOMIZATION_THEME_SETTINGS][THEME_FILL_BUTTON_BG]) : undefined) || "",
        "&:hover": {
          backgroundColor: builderSpec?.[BUILDER_CONFIG]?.[CONFIG_CUSTOMIZATION]?.[CUSTOMIZATION_THEME_SETTINGS]?.[THEME_FILL_BUTTON_BG] ?
            builderSpec[BUILDER_CONFIG][CONFIG_CUSTOMIZATION][CUSTOMIZATION_THEME_SETTINGS][THEME_FILL_BUTTON_BG] : undefined,
          color: isDisabled ? "#c6cdd6" : builderSpec?.[BUILDER_CONFIG]?.[CONFIG_CUSTOMIZATION]?.[CUSTOMIZATION_THEME_SETTINGS]?.[THEME_FILL_BUTTON_CLR] ?
                builderSpec[BUILDER_CONFIG][CONFIG_CUSTOMIZATION][CUSTOMIZATION_THEME_SETTINGS][THEME_FILL_BUTTON_CLR] : undefined,
        },
      };
    },
    control: (provided, state) => ({
      ...provided,
        minWidth: 100,
        borderRadius: platform === THEME_PLATFORMS.BIG_COMMERCE ? "0.5rem" : 0,
        backgroundColor: state.isDisabled ? "#d1d5db" : builderSpec?.[BUILDER_CONFIG]?.[CONFIG_CUSTOMIZATION]?.[CUSTOMIZATION_THEME_SETTINGS]?.[THEME_SECONDARY] && platform === THEME_PLATFORMS.BIG_COMMERCE ?
                                            builderSpec[BUILDER_CONFIG][CONFIG_CUSTOMIZATION][CUSTOMIZATION_THEME_SETTINGS][THEME_SECONDARY] : undefined,
        boxShadow: "0",
        borderColor: platform === THEME_PLATFORMS.BIG_COMMERCE ? "#0000" : "#ccc",
        "&:hover": {
          borderColor: platform === THEME_PLATFORMS.BIG_COMMERCE ? "#0000" : "#ccc",
        }
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      color: state.isDisabled ? "#c6cdd6" : builderSpec?.[BUILDER_CONFIG]?.[CONFIG_CUSTOMIZATION]?.[CUSTOMIZATION_THEME_SETTINGS]?.[THEME_ONLINE_BUTTON] && platform === THEME_PLATFORMS.BIG_COMMERCE ?
          builderSpec[BUILDER_CONFIG][CONFIG_CUSTOMIZATION][CUSTOMIZATION_THEME_SETTINGS][THEME_ONLINE_BUTTON] : undefined,
    }),
    indicatorSeparator: (provided, state) => ({
      ...provided,
      display: "none"
    }),
    menuList: (provided, state) => ({
      ...provided,
      backgroundColor: builderSpec?.[BUILDER_CONFIG][CONFIG_CUSTOMIZATION][CUSTOMIZATION_THEME_SETTINGS][THEME_SECONDARY] && platform === THEME_PLATFORMS.BIG_COMMERCE ? builderSpec[BUILDER_CONFIG][CONFIG_CUSTOMIZATION][CUSTOMIZATION_THEME_SETTINGS][THEME_SECONDARY] : undefined
      // padding: 0
    }),
    menuPortal: (provided, state) => ({
      ...provided,
      zIndex: 11
    }),
  };


  const options: TOption[]|undefined = props.items
  ?.filter((item: TChoice) => !item[CHOICE_IS_EXCLUDED] || props.exclusionBehaviour !== EXCLUSION_OPTIONS.HIDE)
  .sort(
    (props.sort || props.exclusionBehaviour === EXCLUSION_OPTIONS.DISABLE_WITH_SEPERATOR_ORDER) ? 
    Utils.sortChoices(
      true, 
      (props.exclusionBehaviour === EXCLUSION_OPTIONS.DISABLE_WITH_SEPERATOR_ORDER && props.sort) ? SORT.SEPERATE_ASC :
      (props.sort ?  SORT.ASC : undefined), (item: any) => !!item[CHOICE_DISPLAY_VALUE] ? item[CHOICE_DISPLAY_VALUE]: item[CHOICE_NAME], 
      (item: any) => item[CHOICE_IS_EXCLUDED]
    ) as any : undefined
)
  .reduce((acc: {value: string, label: string, disabled: boolean}[], choice) => {
      acc.push({value: choice[CHOICE_ID], label: !!choice[CHOICE_DISPLAY_VALUE] ? choice[CHOICE_DISPLAY_VALUE]: choice[CHOICE_NAME], disabled: !!choice[CHOICE_IS_EXCLUDED]})
      return acc
  }
  , [])

  const handleChange = (value: MultiValue<TOption> | SingleValue<TOption>) => {
    if(props.isMulti) props.onChange((value as MultiValue<TOption>).map(value => value.value))
    else props.onChange((value as SingleValue<TOption>)?.value)
  }

  return (
    <div className="sb3-space-y-2">
        <Title title={props.name} required={props.required} description={props.description} info={props.info} showLabel={props.showLabel} id={props.id}/>
        <div 
          className={!props.isFilter ? '!sb3-w-60' : 'sb3-w-full'}
        >
          
          <ReactSelect 
            components={{Option: CustomOption}}
            className="sb3-select"
            value = {options?.filter((item) => valuesIds.includes(item.value))}
            isDisabled={allDisabled} 
            options={options} 
            onChange={(selection) => !props.dataLoading && handleChange(selection)}
            classNames = {{
              input: () => "!sb3-outline-none sb3-flex"
            }}
            openMenuOnFocus={true}
            styles={colourStyles}
            isMulti={props.isMulti}
            menuPosition="fixed"
            isClearable={true}
            />
        </div>
    </div>
  )  
};

export default Select