import { PropsWithChildren } from "react"

type MyProps = {
    className?: string
}

const ModalBody = ({children, className}: PropsWithChildren<MyProps>) => {
    return (
        <div className={`sb3-px-6 sb3-py-3.5 ${className}`}>
            {children}
        </div>
    )
}
export default ModalBody