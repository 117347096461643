import { ApolloClient, InMemoryCache, QueryOptions, gql } from "@apollo/client";
import AppSettings from 'Settings/AppSettings'
import { get } from "lodash";
import { toast } from "react-toastify";
import { TMagentoGraphqlResponse } from "types/magento";
import * as consts from 'constants/constants'

const queryFn = (merchantProductSkusString: string) => gql`
    {
        products(
            filter: {
                sku: {
                    in: ${merchantProductSkusString}
                }
            }
            pageSize: 10000
        )
        {
            total_count
            items {
                manufacturer_name
                manufacturer
                sku
                display_stock
                sku
                name
                small_image {
                    label
                    url
                }
                short_description {
                    html
                }
                url_key
                stock_status
                price {
                    regularPrice {
                        amount {
                            value
                            currency
                        }
                    }
                }
            }
            page_info {
                page_size
                current_page
            }
            sort_fields {
                options {
                    value
                    label
                }
            }
        }
    }
`

class MagentoHelper {
    static convertGraphQLToCompatioFormat = (graphQLData: TMagentoGraphqlResponse) => {
        return Object.fromEntries(graphQLData.data.products.items
        // .filter((product) => product.stock_status === "IN_STOCK")
        .map(data => {
          return [data.sku, {
            modelNumber: data.name,
            shortDescription: data.short_description,
            description: (!!data.short_description.html ? (!!data.short_description.html.endsWith(',')
                ? data.short_description.html.slice(0, -1)
                : data.short_description.html) : ''),
            manufacturerName: data.manufacturer_name,
            merchantProductSku: data.sku,
            parentProductId: data.sku,
            name: data.name,
            variantName: data.name,
            url_image: data.small_image.url,
            product_url:
                get(window.compatioConfig, 'magento.baseUrl', '') +
                data.url_key +
                get(window.compatioConfig, 'magento.urlSuffix', ''),
            price: parseFloat("" + get(data.price, 'regularPrice.amount.value', 'error')),
            currency: parseFloat("" + get(data.price, 'regularPrice.amount.value', 'error')),
            stock_status: data.display_stock ? consts.STOCK_STATUSES.IN_STOCK : consts.STOCK_STATUSES.OUT_OF_STOCK
          }];
        }));
    }

    static getProductsBySkus: (merchantProductSkus: string[]) => Promise<ReturnType<typeof this.convertGraphQLToCompatioFormat>> = (
        merchantProductSkus: string[],
        ) =>{
            return new Promise((resolve, reject) =>{
                if(!window.compatioConfig?.magento?.apiEndpointUrl)  {
                    toast.error("Env error")
                    // console.error("Magento graphql suffix env not found", "Err 6")
                    return
                }
                const merchantProductSkusString = JSON.stringify(merchantProductSkus);

                try{
                    const client = new ApolloClient({
                        uri: window.compatioConfig?.magento?.apiEndpointUrl,
                        cache: new InMemoryCache(),
                        connectToDevTools: false,
                    });

                    const queryParams: QueryOptions = {
                        query: queryFn(merchantProductSkusString),
                    }

                    if(window.compatioConfig?.compatibleProducts?.[consts.CMPT_CONF_STORE]) 
                        queryParams.context = {
                            headers: {
                                store: window.compatioConfig?.compatibleProducts?.[consts.CMPT_CONF_STORE]
                            }
                        }
                        
                    client?.query(queryParams)
                    .then((res) => {
                        resolve(this.convertGraphQLToCompatioFormat(res))
                    })
                    .catch((err) => {
                        // console.error(err)
                        reject()
                    })
                }
                catch(err){
                    // console.error(err)
                    reject()
                }
            })
    }

    // static handleBuildSubmit = (cart: TCart) => {
    //     return new Promise((resolve, reject) =>{
    //         if(cart.length <= 0) return toast.error("Cart is empty")
    //         // https://magento-demo.compatio.net/compatioxrs/cart/add/?sku=CWC016-10-30C03-WEG,RW17-1D3-U017-WEG&qty=1,1&_=1709799410268

    //         const finalUrl = AppSettings.CLIENT_MAGENTO_BASE_URL + "compatioxrs/cart/add"
    //         const skus = cart.map((product) => product.merchantProductSku).join(',')
    //         const quantity = cart.map((product) => product.quantity).join(',')

    //         try{
    //             const client = new ApolloClient({
    //                 uri: AppSettings.CLIENT_MAGENTO_GRAPHQL_URL,
    //                 cache: new InMemoryCache()
    //             });
                    
    //             client?.query({
    //                 query: queryFn(merchantProductSkusString),
    //             })
    //             .then((res) => {
    //                 resolve(this.convertGraphQLToCompatioFormat(res))
    //             })
    //             .catch((err) => {
    //                 console.error(err)
    //                 reject()
    //             })
    //         }
    //         catch(err){
    //             console.error(err)
    //             reject()
    //         }
    //     })
    // }
}

export default MagentoHelper