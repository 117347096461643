import FillButton from "components/Button/FillButton"
import ProductHelper from "helpers/ProductHelper"
import { ProductPriceString } from "./ProductPriceString"
import { useAppContext } from "contexts/AppContext"
import {  BUILDER_CONFIG, CATEGORY_NAME, CONFIG_CUSTOMIZATION, CUSTOMIZATION_THEME_SETTINGS, MODEL_DATA, PLACEHOLDER_IMAGE, RESULT_MAX_PURCHASE_QTY, RESULT_MIN_PURCHASE_QTY, RESULT_PRDT_CATEGORY_ID, RESULT_PRDT_DESC, RESULT_PRDT_IS_QUOTE, RESULT_PRDT_NAME, RESULT_PRDT_OPTIONS, RESULT_PRDT_PRICE,  RESULT_PRDT_SKU,  RUNTIME_INSTANCE_INSTANCE_ID, STOCK_STATUSES, THEME_BACKGROUND, THEME_PLATFORMS, THEME_SECONDARY } from "constants/constants"
import QuantityInput from "./QuantityInput"
import { TProductProps } from "./types"
import ProductStockStatus from "./ProductStockStatus"
import BorderedButton from "components/Button/BorderedButton"
import Utils from "Services/Utils"
import { isArray } from "lodash"
import { useEffect, useState } from "react"
import { TResultProduct } from "types"
import ReactSelect from "react-select"
import TooltipInfo from "components/TooltipInfo"
import ProductDescriptionTooltip from "components/ProductDescriptionTooltip"
import CartIcon from "./CartIcon"
import FillAnchor from "components/Button/FillAnchor"

const ProductHorizontal = ({
    category,
    product,
    selected,
    instance,
    showQtyInput,
    quantity,
    maxQty,
    buttonLabel,
    handleProductClick,
    handleQtyChange,
    showImage
}: TProductProps) => { 
    const {
        addProductToCart,
        removeProductFromCart,
        dataLoading,
        setImagePreview,
        platform,
        builderSpec
    } = useAppContext()

    const [selectedVariant, setSelectedVariant] = useState<TResultProduct|undefined>()

    useEffect(() => {
        if (isArray(product[RESULT_PRDT_OPTIONS]) 
            && product[RESULT_PRDT_OPTIONS]?.length > 0 ){
            setSelectedVariant(product[RESULT_PRDT_OPTIONS][0])
        }
    }, [])
    
    const options = product[RESULT_PRDT_OPTIONS]
        // ?.sort(props.sort ? Utils.sortChoices(true, SORT.SEPERATE_ASC, (item: any) => item[CHOICE_NAME], (item: any) => item[CHOICE_IS_DISABLED]) as any : undefined)
        ?.reduce((acc: {value: string, label: string}[], choice) => {
            acc.push({value: choice[RESULT_PRDT_SKU], label: choice.variantTitle ?? choice[RESULT_PRDT_NAME]})
            return acc
        }
    , [])

    const hasOptions = isArray(product[RESULT_PRDT_OPTIONS]) 
        && product[RESULT_PRDT_OPTIONS]?.length > 1 
    
    const productImage = ProductHelper.getProductImage(product, selectedVariant?.[RESULT_PRDT_SKU], product[RESULT_PRDT_OPTIONS])
    
    return (
        <div className={`sb3-product-card sb3-product-list ${ platform === THEME_PLATFORMS.BIG_COMMERCE ? "sb3-py-4 sb3-border-b-2" : "sb3-bg-white sb3-py-3 sm:sb3-py-10 sb3-border-b sb3-product-container"} sb3-h-full  sb3-w-full sb3-flex sb3-flex-wrap sm:sb3-flex-nowrap sb3-gap-5  sb3-px-2`}
        style={{
            borderColor:builderSpec?.[BUILDER_CONFIG]?.[CONFIG_CUSTOMIZATION]?.[CUSTOMIZATION_THEME_SETTINGS]?.[THEME_SECONDARY] && 
            builderSpec[BUILDER_CONFIG][CONFIG_CUSTOMIZATION][CUSTOMIZATION_THEME_SETTINGS][THEME_SECONDARY]
        }}
        >
            {
                showImage !== false && 
                    <span className={`sb3-product-list-image-container sb3-min-w-full sb3-w-full sm:sb3-min-w-32 sm:sb3-w-32  sb3-aspect-square sb3-h-min ${platform === THEME_PLATFORMS.BIG_COMMERCE ? 'sb3-rounded-[21px] sb3-overflow-hidden':'sb3-rounded-none '}`}>
                        <img 
                        key={product[RESULT_PRDT_SKU]}
                        onClick={() => setImagePreview(!!productImage ? productImage : PLACEHOLDER_IMAGE)} 
                        src={!!productImage ? productImage : PLACEHOLDER_IMAGE} 
                        alt="Product" 
                        className={`sb3-product-list-image sb3-aspect-square sb3-min-w-full sb3-w-full sm:sb3-min-w-32 sm:sb3-w-32 sb3-max-w-full sb3-object-fill`}/>
                    </span>
            }
        
            <div className="sb3-flex-grow">
            <div className="sm:sb3-flex sb3-justify-between sb3-space-y-2 sm:sb3-space-y-0 lg:sb3-space-x-2">
                <div className="sb3-product-list-left-container sb3-min-w-0 sb3-overflow-hidden sb3-flex-grow sb3-space-y-1">
                    {
                        product[RESULT_PRDT_OPTIONS] && hasOptions
                        && <span className="sb3-product-list-variants-count sb3-text-xs sb3-text-gray-extraDark">{product[RESULT_PRDT_OPTIONS].length} options</span>
                    }
        
                    {!Utils.isShopify() && 
                    <p data-tooltip-id="sb3-shared-tooltip"
                        onClick={() => handleProductClick(selectedVariant ?? product)}
                        data-tooltip-content={ProductHelper.getProductPrimaryTitle(product, platform)} className={`
                        sb3-product-title-list sb3-line-clamp-2 words-break
                        ${ platform === THEME_PLATFORMS.BIG_COMMERCE ? "sb3-text-sm sb3-font-bold sb3-underline" : "sb3-text-gray-extraDark"}
                    `}>{ProductHelper.getProductPrimaryTitle(product, platform)}</p>}

                    <button 
                    type="button" 
                    className={`
                        sb3-product-list-subtitle
                        ${ platform === THEME_PLATFORMS.BIG_COMMERCE ? "sb3-text-sm sb3-font-bold" : "sb3-text-base"}
                        sb3-mt-2 sb3-text-left sb3-line-clamp-2 words-break sb3-border-0 hover:sb3-bg-[#0000] hover:sb3-border-0 hover:sb3-text-black focus:sb3-text-black focus:sb3-bg-0 focus:sb3-border-[#0000] focus:!sb3-outline-none sb3-break-words`}
                    onClick={() => handleProductClick(selectedVariant ?? product)}
                    data-tooltip-id="sb3-shared-tooltip"
                    data-tooltip-content={ProductHelper.getProductSecondaryTitle(product, platform)}>
                        {ProductHelper.getProductSecondaryTitle(product, platform)}
                    </button>
        
                    {
                        isArray(product[RESULT_PRDT_OPTIONS]) 
                        && product[RESULT_PRDT_OPTIONS]?.length > 1 
                        && <ReactSelect
                            value={options?.filter((item) => (selectedVariant)?.[RESULT_PRDT_SKU] === item.value)}
                            options={options}
                            onChange={(selection) => setSelectedVariant(product[RESULT_PRDT_OPTIONS]?.find(pr => pr[RESULT_PRDT_SKU] === selection?.value))}
                            className="sb3-max-w-96"
                            menuPosition="fixed"
                            styles={{
                                control: (provided) => ({
                                ...provided,
                                    borderRadius: 0,
                                    minWidth: 100,
                                    background: "#F9F9F9"
                                })
                            }}
                        />
                    }
        
                    <span 
                    className=" sb3-product-list-description sb3-text-sm sb3-text-gray-extraDark sb3-break-all sb3-line-clamp-2"
                    data-tooltip-id="sb3-shared-tooltip"           
                    data-tooltip-content={ProductHelper.getProductTertiaryTitle(product, platform)}>{
                        ProductHelper.getProductTertiaryTitle(product, platform)
                    }</span>
        
                    <span className="sb3-product-list-status-span sb3-flex sb3-items-center sb3-max-w-full sb3-text-sm sb3-space-x-2">
                        <ProductStockStatus product={selectedVariant ?? product}/>
                    </span>
        
                    {(selectedVariant?.[RESULT_PRDT_DESC] || product[RESULT_PRDT_DESC]) && <ProductDescriptionTooltip message={selectedVariant?.[RESULT_PRDT_DESC] ?? product[RESULT_PRDT_DESC]}/>}
                </div>
        
                <div className='sb3-product-list-right-container lg:sb3-text-right sm:sb3-pl-2 sb3-space-y-2 sb3-shrink-0 sb3-block sb3-flex-wrap'>
                    <p className="sb3-product-list-price-p sb3-font-bold ">
                        <ProductPriceString
                            price={ProductHelper.getProductPrice(product, selectedVariant?.[RESULT_PRDT_SKU], product[RESULT_PRDT_OPTIONS])}
                            isQuote={product[RESULT_PRDT_IS_QUOTE]}
                            // compareAtPrice={product.compareAtPrice}
                            // currency={product.currency}
                    /></p>
                    <div className="sb3-product-list-controls sb3-space-y-2 sb3-place-items-end sm:sb3-flex sb3-flex-col sb3-items-end">
                        {
                            platform !== THEME_PLATFORMS.BIG_COMMERCE && !selected && 
                            <BorderedButton disabled={!showQtyInput || dataLoading} onClick={() => instance?.[RUNTIME_INSTANCE_INSTANCE_ID] && addProductToCart(selectedVariant ?? product, product[RESULT_MIN_PURCHASE_QTY] ?? 1, product[RESULT_PRDT_CATEGORY_ID], instance, category[MODEL_DATA][CATEGORY_NAME])} 
                            className="sb3-product-list-add-to-build !sb3-px-6 sb3-w-max sb3-min-w-full sm:sb3-min-w-[92px]">{
                                buttonLabel ?? "ADD TO BUILD"
                            }</BorderedButton>
                        }
                        
                        {
                            platform === THEME_PLATFORMS.BIG_COMMERCE && !selected && 
                            <FillButton disabled={!showQtyInput || dataLoading} onClick={() => instance?.[RUNTIME_INSTANCE_INSTANCE_ID] && addProductToCart(selectedVariant ?? product, product[RESULT_MIN_PURCHASE_QTY] ?? 1, product[RESULT_PRDT_CATEGORY_ID], instance, category[MODEL_DATA][CATEGORY_NAME])} 
                            className="sb3-product-list-carticon-btn !sb3-w-min !sb3-min-w-min !sb3-rounded-full ">
                                <CartIcon className="sb3-product-list-carticon"/>
                            </FillButton>
                        }
        
                        {
                            selected && 
                            ((product[RESULT_MIN_PURCHASE_QTY] && product[RESULT_MIN_PURCHASE_QTY] > 1) || 
                            (maxQty && maxQty > 1)) && 
                            quantity &&
                            <QuantityInput
                                quantity={quantity}
                                handleChange={handleQtyChange}
                                disablePrev={(qty: number) => qty === 1}
                                disableNext={() => !showQtyInput}
                                maxPurchaseQty={product[RESULT_MAX_PURCHASE_QTY]}
                                minPurchaseQty={product[RESULT_MIN_PURCHASE_QTY]}
                            />
                        }
        
                        {
                            selected && <BorderedButton disabled={dataLoading} onClick={() => instance?.[RUNTIME_INSTANCE_INSTANCE_ID] && removeProductFromCart(selectedVariant ?? product, product[RESULT_PRDT_CATEGORY_ID], instance[RUNTIME_INSTANCE_INSTANCE_ID])} 
                            className="sb3-product-list-remove-button sb3-remove-button sm:!sb3-px-6 sb3-w-full sm:sb3-w-max !sb3-text-danger !sb3-border-danger hover:!sb3-text-danger hover:!sb3-border-danger sb3-remove-btn ">{
                                "REMOVE"
                            }</BorderedButton>
                        }
                    </div>
                </div>
            </div></div>
        </div>
    )
}

export default ProductHorizontal