import React, { useEffect, useState } from "react";
import FillButton from "../Button/FillButton";
import BorderedButton from "../Button/BorderedButton";
import {IoWarningOutline} from 'react-icons/io5'
import ModalContainer from "../Modal/ModalContainer";
import ModalHeader from "../Modal/ModalHeader";
import ModalBody from "../Modal/ModalBody";
import { TdisabledModal } from "types/contexts/AppContext";
import { useAppContext } from "contexts/AppContext";
import { ImSpinner2 } from "react-icons/im";
import { CHOICE_DISPLAY_VALUE, CHOICE_NAME, CONF_CHOICES, DATA_MODELS, DEFN_PROJECT, FIELD_DATA_CONFIGURATION, FIELD_ID, FIELD_TYPES, FRMDATA_ID, FRMDATA_ITEMS, FRMDATA_SMID, MODEL_DATA, MODEL_DYNAMIC_FIELDS, MODEL_FIELDS, PLACEHOLDER_IMAGE, RESULT_PRDT_MODEL_NO, RESULT_PRDT_SKU, THEME_PLATFORMS } from "constants/constants";
import { TField, TRuntimeDynamicFieldValue, TUI_Node } from "types";
import { isArray } from "lodash";
import DisabledModalHeader from "./DisabledModalHeader";
import ProductHelper from "helpers/ProductHelper";
import { TCartItem } from "types/Cart";

type MyProps = {
  showModal: TdisabledModal|null,
  closeModal: () => void
  onConfirm: () => void
  loading: boolean
}

const DisabledModal = ({ showModal, closeModal, onConfirm, loading }: MyProps) => {
  const {
    fieldIdNameMap,
    cart,
    dataLoading,
    runtimeSpec,
    platform
  } = useAppContext()

  const [activeTab, setActiveTab] = useState<'conflicts' | 'products'>('conflicts');

  const fieldIds = showModal?.fields && Object.entries(showModal?.fields).map(field => field[0])
  const fields = runtimeSpec && Object.values(runtimeSpec).reduce((acc: {[x: string]: TRuntimeDynamicFieldValue}, instance) => {
    fieldIds?.map((fieldId) => {
      if(fieldId in instance[MODEL_DYNAMIC_FIELDS]) acc[fieldId] = instance[MODEL_DYNAMIC_FIELDS][fieldId]
    })

    return acc
  }, {})

  const _fieldIDNameMap = fieldIdNameMap && Object.entries(fieldIdNameMap)


  const conflictedFields = showModal?.fields && Object.entries(showModal?.fields)
    .filter((field) => _fieldIDNameMap?.find((val) => val[0].includes(field[0])))
  
  const conflictedProducts = showModal?.fields && Object.entries(showModal?.fields)
    .filter((field) => !_fieldIDNameMap?.find((val) => val[0].includes(field[0])))

  useEffect(() => {
    const _hasConflictedFields = showModal?.fields && Object.entries(showModal?.fields)
    .filter((field) => _fieldIDNameMap?.find((val) => val[0].includes(field[0]))).length > 0
  
    const _hasConflictedProducts = showModal?.fields && Object.entries(showModal?.fields)
    .filter((field) => !_fieldIDNameMap?.find((val) => val[0].includes(field[0]))).length > 0

    if(_hasConflictedFields) return setActiveTab("conflicts")
    if(_hasConflictedProducts) return setActiveTab("products")

  }, [showModal])

  return (<ModalContainer
    isOpen={!!showModal}
    setOpen={closeModal}
    className="sb3-rounded-none"
  >
    <ModalHeader>
      <div className=" sb3-py-2">
        <h5 className="sb3-text-base sb3-font-semibold">Switch to &quot;{showModal?.name}&quot;</h5>
        {/* <span>{totalProducts} {totalProducts === 1 ? "Product" : "Products"} found</span> */}
      </div>
    </ModalHeader>
    <ModalBody className="!sb3-px-0 !sb3-py-0 !sb3-pt-3.5">
      <div className="">
        <div className="sb3-px-6 sb3-space-y-2" >
      <div className="sb3-bg-orange-50 sb3-flex sb3-space-x-3 sb3-items-center sb3-p-2 sb3-px-3 sb3-rounded sb3-border sb3-border-[#ffc400]">
        <IoWarningOutline className="sb3-text-orange-500 sb3-text-3xl" color={'#ffc400'}/>
        <p>
          This action will unselect options that conflicts with this value
        </p>
      </div>
      {
        (conflictedFields || conflictedProducts) && 
          <DisabledModalHeader
            conflictedFields = {conflictedFields}
            conflictedProducts = {conflictedProducts}
            activeTab = {activeTab}
            setActiveTab = {setActiveTab}
          />
      }
        


        {
          activeTab === 'conflicts' && isArray(conflictedFields) && conflictedFields?.length > 0 && 
          <div className="!sb3-mt-0">
          <div className="sb3-text-xs sb3-border-0 sb3-border-solid sb3-border-b sb3-font-semibold sb3-w-full sb3-flex sb3-justify-between sb3-px-3 sb3-py-2 sb3-gap-x-16">
                        <span className="sb3-w-9/12 sb3-text-[#97A0AF]">Seleted Options</span>
                        <span className="sb3-w-3/12 sb3-text-[#97A0AF]">Values</span>
                      </div>
          <div className="sb3-text-sm sb3-max-h-[170px] sb3-overflow-y-auto sb3-bottom-0 sb3-border-b sb3-border-solid">
              {
                  conflictedFields
                  .map((field, key) => {
                    return (
                      <div key = {key} className="sb3-w-full sb3-border-0 sb3-border-solid last:sb3-border-b-0 sb3-border-b sb3-flex sb3-justify-between sb3-py-2 sb3-px-3 sb3-text-sm sb3-font-medium  sb3-gap-x-16">
                        <span className="sb3-w-9/12">{_fieldIDNameMap?.find((val) => val[0].includes(field[0]))?.[1]}</span>
                        <span className="sb3-w-3/12">{
                        fields?.[field[0]].type === FIELD_TYPES.MULTI_CHOICE ?
                          fields?.[field[0]].choices?.filter((choice) => (field[1] as string[]).includes(choice[CHOICE_NAME]))
                            .map(choice => choice[CHOICE_DISPLAY_VALUE] ?? choice[CHOICE_NAME]).join(', ')
                          : field[1]
                        }</span>
                      </div>
                    )
                })
              }
          </div>
          </div>
        }
        {
          activeTab === 'products' && isArray(conflictedProducts) && conflictedProducts?.length > 0 && 
          <div className="!sb3-mt-0">
            <div className="sb3-text-xs sb3-border-0 sb3-border-solid sb3-border-b sb3-font-semibold sb3-w-full sb3-flex sb3-justify-between sb3-px-3 sb3-py-2 sb3-gap-x-16">
                          <span className="sb3-w-9/12 sb3-text-[#97A0AF]">Seleted Products</span>
                          <span className="sb3-w-3/12 sb3-text-[#97A0AF]">Category</span>
              </div>
            <div className="sb3-text-sm sb3-max-h-[170px] sb3-overflow-y-auto">
                {
                    conflictedProducts
                    .map((field, key) => {
                      const products = cart?.filter((product) => field[1].includes(product[RESULT_PRDT_SKU].toString())) as TCartItem[]
                      return(
                      <div key = {key} className="sb3-w-full sb3-flex sb3-items-center sb3-border-0 sb3-border-solid last:sb3-border-b-0 sb3-border-b sb3-justify-between sb3-py-2  sb3-gap-x-16 sb3-px-3 sb3-text-sm sb3-font-medium">
                        <span className="sb3-w-9/12 sb3-flex sb3-items-center sb3-flex-wrap sb3-space-x-2">
                        {
                          products.map((product, index)=> <>
                            <span key={index} className="sb3-flex space-x-4 sb3-items-center">
                                <div className={`sb3-w-[50px] sb3-h-min sb3-aspect-square ${platform === THEME_PLATFORMS.BIG_COMMERCE && "sb3-rounded-xl sb3-overflow-hidden"}`}>
                                  <img 
                                      src={(product && !!ProductHelper.getProductImage(product)) ? ProductHelper.getProductImage(product) : PLACEHOLDER_IMAGE} 
                                      alt="Product" 
                                      className={`sb3-aspect-square sb3-h-auto sb3-max-w-full sb3-object-contain`}
                                  />
                                </div>
                                <span>{product?.[RESULT_PRDT_MODEL_NO] ?? field[1]}</span>
                              </span>
                              {index < products.length - 1 && <span>,</span>}
                              </>
                          )
                        }
                        </span>

                        <span className="sb3-w-3/12">{cart?.find((product) => product.catgeory_id === field[0])?.categoryName}</span>
                      </div>
                    )})
                }
            </div>
          </div>
        }
        </div>
        <div className="sb3-w-full sb3-h-[0.5px] sb3-bg-[#dfe0e6]"/>
        <div className="!sb3-bg-[#EDEEF0] sb3-space-y-2 !sb3-pb-5 !sb3-pt-4">
          <center><p className="sb3-text-base sb3-font-semibold">Would you like to proceed?</p></center>
          <div className="sb3-flex sb3-justify-center sb3-space-x-3">
        <BorderedButton className='!sb3-bg-transparent'
        onClick={() => closeModal()}
        >Cancel</BorderedButton>
        <FillButton
            onClick={() => onConfirm()}
            disabledClassName = "!sb3-border-red-200 !sb3-bg-red-200"
            disabled = {dataLoading}
        >{dataLoading ? <ImSpinner2 className="sb3-text-xl sb3-text-blue-500 sb3-animate-spin" /> :"Proceed"}</FillButton>
      </div>
        </div>
      </div>
    </ModalBody>
  </ModalContainer>
  )
}

export default DisabledModal