import { CSSProperties, useEffect, useState } from "react";

type MyProps = {
    url: string,
    className?: string,
    style?: CSSProperties
}

// Add this new component at the top of the file with other imports
const SvgLoader = ({ url, className, style }: MyProps) => {
    const [svgContent, setSvgContent] = useState<string|null>(null);
    const [error, setError] = useState<boolean>(false);
  
    useEffect(() => {
      const fetchSvg = async () => {
        const newUrl = url.replace("https://s3.us-east-2.amazonaws.com/gms.bucket.prod/", "https://d22edgwae7w2qi.cloudfront.net/")

        try {
          const response = await fetch(newUrl);
          const text = await response.text();
          
          // Create a temporary div to parse the SVG
          const div = document.createElement('div');
          div.innerHTML = text;
          const svg = div.querySelector('svg');
          
          // Add the classes and styles
          if (svg) {
            if(className) svg.setAttribute('class', className);
            svg.style.stroke = "#00000000";
            svg.style.fill = "#00000000";
            svg.style.height = "100%";
            svg.style.width = "100%";
          }
          
          setSvgContent(div.innerHTML);
        } catch (error) {
          console.error('Error loading SVG:', error);
          setError(true);
        }
      };
  
      if (url) fetchSvg();
    }, [url, className, style?.stroke, style?.fill]);
  
    if (error || !svgContent) {
      return (
        <img 
          src={url} 
          className={className} 
          style={style}
          alt="" 
        />
      );
    }
  
    return <div className={className} style={style} dangerouslySetInnerHTML={{ __html: svgContent }} />;
  };

  export default SvgLoader