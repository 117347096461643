import Utils from "Services/Utils"
import { Link } from "react-router-dom"
import Sort from "./Sort"
import { TItemLayoutProps } from "types/components/Fields"
import { CHOICE_ID, CHOICE_IS_SELECTED } from "constants/constants"
import Title from "components/Header/Title"
import { TChoice } from "types"
import { isArray } from "lodash"
import NodeHelper from "helpers/NodeHelper"

const MultiChoiceGrid = ({
    title,
    items, 
    href, 
    disabled, 
    handleClick, 
    value,
    getImage,
    getName,
    getLabel,
    loading,
    isConfigure,
    isDisabledGray,
    sort,
    sortType,
    dataLoading,
    isMulti,
    description,
    required,
}: TItemLayoutProps) => {
    const handleSelect = (choice: TChoice, isSelected: boolean) => {
        /**
         * if value is already selected remove it from the array
         * and pass it to the select function else vice versa
         */
        if(!handleClick || dataLoading) return

        if(!isMulti) {
            handleClick(choice[CHOICE_ID])
            return
        }

        const newValues = isArray(value) ? [...NodeHelper.getChoicesIds(value)] : []
        if(isSelected){
            const index = newValues.indexOf(choice[CHOICE_ID])
            if(index !== -1) newValues.splice(index, 1)
        }
        else{
            newValues.push(choice[CHOICE_ID])
        }
        handleClick(newValues)
    }

    const selectedValueIds = value ? NodeHelper.getChoicesIds(value) : []

    return (
        <>
        <div className="sb3-flex sb3-justify-between sb3-items-center">
            <Title title = {title} description={description} required={required}/>
            {sort && <Sort/>}
        </div>
        <div className={`
            sb3-grid ${isConfigure ? 'lg:sb3-grid-cols-3' : 'lg:sb3-grid-cols-5'} md:sb3-grid-cols-3 sm:sb3-grid-cols-3 sb3-grid-cols-2 sb3-py-3 sb3-gap-5 sb3-items-start sb3-w-full
            /sb3-justify-between /after:sb3-content-[''] /after:sb3-flex-auto
        `}>
            {
                (!items || items?.length === 0) && 
                <span>No options found</span>
            }
            {
                items
                ?.sort(sort ? Utils.sortChoices(sort, sortType, getName, disabled) : undefined)
                ?.map((item: any, key: any) => {
                    const _disabled = !!(disabled && disabled(item))
                    const _selected = selectedValueIds?.includes(item?.[CHOICE_ID])
                    const image = getImage && getImage(item)

                    return(
                        <Link
                            key={key}
                            to={(!_disabled && href) ? href : "#"}
                            type="button"
                            onClick = {(e) => {
                                e.preventDefault()
                                handleSelect(item, _selected)
                            }}
                            className="focus:sb3-outline-none"
                        >
                        <div className={`sb3-relative sb3-w-full sb3-font-semibold sb3-text-sm sb3-group
                            ${loading && " sb3-animate-pulse "}
                            ${dataLoading && " sb3-opacity-60 sb3-pointer-events-none sb3-cursor-default "}
                            ${(_disabled && isDisabledGray) && 'sb3-pointer-events-none sb3-cursor-default !sb3-opacity-40'}
                        `}>
                            <div 
                                className={
                                    `sb3-relative sb3-w-full sb3-aspect-square sb3-border sb3-border-solid ${!image ? "sb3-bg-linear-gradient" : "sb3-bg-white"}
                                    sb3-rounded-md sb3-overflow-hidden ${ _selected && 'sb3-border-2 sb3-border-primary'}
                                    ${(!_disabled) && "sb3-border sb3-border-solid group-hover:sb3-border-primary"}`
                                }
                            >
                            <span className={`sb3-absolute sb3-left-5 sb3-top-5 sb3-border-2 
                                    sb3-rounded-full sb3-p-0 sb3-z-10 sb3-flex sb3-items-center sb3-justify-center sb3-bg-white
                                    ${_selected ? 'sb3-border-primary' : (!getImage?.(item) && "sb3-border-white")}
                                `}
                                >
                                    <span className={`sb3-p-2 sb3-rounded-full sb3-border-2 sb3-border-white
                                        ${_selected ? 'sb3-bg-primary' : 'sb3-bg-white'}
                                    `}/>
                            </span>
                            {
                                image ?
                                <img
                                    src={getImage(item)} 
                                    alt="Category"
                                    className="sb3-object-contain sb3-p-2 sb3-w-full sb3-h-full"

                                /> 
                                : 
                                <span title={(getLabel && getLabel(item))} className="sb3-flex sb3-items-center sb3-justify-center sb3-h-full sb3-text-base sb3-text-normal sb3-text-center">
                                    <p className="sb3-line-clamp-3 sb3-px-2 sb3-text-white sb3-break-words">{(getLabel && getLabel(item))}</p>
                                </span>
                            }
                            </div>
                            <p title={(getName && getName(item))} className="sb3-pt-2 sb3-text-sm sb3-line-clamp-3">{(getName && getName(item))}</p>
                        </div>
                    </Link>
                )})
            }
        </div>
        </>
    )
}

export default MultiChoiceGrid