import { PropsWithChildren, SetStateAction } from "react"
import { AppContextProvider } from "contexts/AppContext"
import { CartContextProvider } from "contexts/CartContext"
// import CookieAccessCheck from "./useCookies"

type MyProps = {
    isOpen: boolean|undefined
    setIsOpen: (value: boolean) => void,
    modelId?: string|undefined
    blank?: boolean
    setVariants?: boolean
}


const AppContextWrapper = ({ children, isOpen, setIsOpen, modelId, blank, setVariants }: PropsWithChildren<MyProps>) => {
 return (
    // <CartContextProvider>
    // <CookieAccessCheck>
        <AppContextProvider modelId={modelId} isOpen={isOpen} setIsOpen={setIsOpen} blank={blank} setVariants={setVariants}>
            {children}
        </AppContextProvider>
    // </CookieAccessCheck>
    // </CartContextProvider>
 )
}

export default AppContextWrapper