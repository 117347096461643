import { PropsWithChildren } from "react"
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { TAccordionHeaderProps } from "types/components/Accordion";
/**
 * Accordion header element 
 * use with combination of Accordion Layout
 * 
 * @param children
 * @returns 
 */
const AccordionHeader = ({isOpen, setIsOpen, children, options, disabled}: PropsWithChildren<TAccordionHeaderProps>) => {
    const handleClick: React.MouseEventHandler<HTMLButtonElement> = (e) => {
        e.preventDefault()
        if(!disabled) setIsOpen(prev => !prev)
    }

    return <div className={`sb3-w-full sb3-border-gray`}>
            <button className={`sb3-flex sb3-items-center sb3-w-full sb3-text-left !sb3-border-0 focus:!sb3-outline-none
            ${options?.arrowPosition === "left" && "sb3-flex-row-reverse"}
            ${disabled ? "sb3-cursor-default" : "hover:!sb3-bg-white hover:!sb3-border-white hover:!sb3-text-gray-dark focus:!sb3-bg-white focus:!sb3-border-white focus:!sb3-text-gray-dark"}
            `} type="button" onClick={handleClick}>
                <div className={`${!disabled ? "sb3-w-[calc(100%-40px)]" : "sb3-w-full"}  sb3-truncate`}>
                    {children}
                </div>
                {
                    !disabled && 
                    <span className={`sb3-relative sb3-p-2 sb3-w-[40px] sb3-text-center sb3-text-xs`}>
                        { 
                        isOpen ? 
                        <FaChevronUp className="sb3-text-gray-dark sb3-leading-7"/> 
                        : <FaChevronDown className="sb3-text-gray-dark sb3-leading-7"/> 
                        }
                    </span>
                }
            </button>
        </div>
}

export default AccordionHeader