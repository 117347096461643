import { useAppContext } from "contexts/AppContext"
import AccordionBody from "./Accordion/AccordionBody"
import AccordionHeader from "./Accordion/AccordionHeader"
import AccordionLayout from "./Accordion/AccordionLayout"
import Header from "./Header"
import { TModel } from "types"
import {
    BUILDER_CONFIG,
    CONFIG_CUSTOMIZATION,
    CUSTOMIZATION_THEME_SETTINGS,
    FIELD_ID,
    FIELD_NAME,
    FIELD_PLACEMENT,
    FIELD_TYPES,
    FRMDATA_DISPLAY_NAME,
    FRMDATA_FIELD_TYPE,
    FRMDATA_ITEMS,
    FRMDATA_PLACEMENT,
    FRMDATA_TYPE,
    MODEL_DYNAMIC_FIELDS,
    MODEL_SUBMODEL_ID,
    RUNTIME_INSTANCE_FIELD_NAME,
    RUNTIME_INSTANCE_FIELD_REQUIRED,
    RUNTIME_INSTANCE_FIELD_VALUE,
    RUNTIME_INSTANCE_INSTANCE_ID,
    RUNTIME_INSTANCE_IS_INPUT,
    THEME_FILL_BUTTON_BG,
    THEME_FILL_BUTTON_CLR,
    THEME_ONLINE_BUTTON,
    THEME_PLATFORMS
} from "constants/constants"
import Field from "./Submodel/Field"
import NodeHelper from "helpers/NodeHelper"
import { TActiveSubmodel } from "types/contexts/AppContext"
import { isArray } from "lodash"
import { FaTimes } from "react-icons/fa"
import BorderlessButton from "./Button/BorderlessButton"
import Summary from "./Summary/Summary"

type MyProps = {
    activeSubmodel: TActiveSubmodel
}

// @todo pass fields from parent
const Filters = ({activeSubmodel}: MyProps) => { 
    const {
        specs,
        activePage,
        updateInstanceInputs,
        builderSpec,
        platform,
        runtimeSpec
    } = useAppContext()

    if(!activeSubmodel || !activePage) return <p>No fields found</p>

    const instance = runtimeSpec?.[activeSubmodel?.instance[RUNTIME_INSTANCE_INSTANCE_ID]]

    const visibleFields = instance?.[MODEL_DYNAMIC_FIELDS] 
    && NodeHelper.filterVisibleNodesUsingRuntime(
        instance[MODEL_DYNAMIC_FIELDS], 
        activeSubmodel.uiModel
    )

    // const submodelUI = activePage[PAGE_FORM_DATA]?.find((submodel) => submodel[FRMDATA_SMID] === activeSubmodel[MODEL_SUBMODEL_ID]) as TUI_BuilderSubmodel

    const filters = (instance && Object.entries(instance[MODEL_DYNAMIC_FIELDS])?.filter(([fieldId,field]) => {
        return field.type !== "Category" 
        && visibleFields?.visibleFieldIds?.includes(fieldId) 
        && field[RUNTIME_INSTANCE_IS_INPUT] 
        && isArray(field[RUNTIME_INSTANCE_FIELD_VALUE]) 
        && field[RUNTIME_INSTANCE_FIELD_VALUE].length > 0
    })) ?? []


    const handleClearAllFilter = () => {
        if(!instance?.[MODEL_DYNAMIC_FIELDS] ) return

        const value = Object.fromEntries(
            filters.map(([fieldId, _]) => ([fieldId, []]))
        )
        updateInstanceInputs(value, instance[RUNTIME_INSTANCE_INSTANCE_ID])
    }

    const handleRemoveFilter = (field: string, values: string[], value: string) => {
        if(!instance?.[RUNTIME_INSTANCE_INSTANCE_ID]) return

        const newValues = [...values]
        const index = newValues.indexOf(value)
        if(index === -1) return

        newValues.splice(index, 1)
        updateInstanceInputs({[field]: newValues}, instance[RUNTIME_INSTANCE_INSTANCE_ID])
    }

    const styles: any = {}

    if(builderSpec?.[BUILDER_CONFIG]?.[CONFIG_CUSTOMIZATION]?.[CUSTOMIZATION_THEME_SETTINGS]?.[THEME_FILL_BUTTON_BG]) {
        styles.backgroundColor = builderSpec?.[BUILDER_CONFIG]?.[CONFIG_CUSTOMIZATION]?.[CUSTOMIZATION_THEME_SETTINGS]?.[THEME_FILL_BUTTON_BG]
        styles.borderColor = builderSpec?.[BUILDER_CONFIG]?.[CONFIG_CUSTOMIZATION]?.[CUSTOMIZATION_THEME_SETTINGS]?.[THEME_FILL_BUTTON_BG]
        styles.color = builderSpec?.[BUILDER_CONFIG]?.[CONFIG_CUSTOMIZATION]?.[CUSTOMIZATION_THEME_SETTINGS]?.[THEME_FILL_BUTTON_CLR]
      }

    const summaryData = instance && Object.entries(instance[MODEL_DYNAMIC_FIELDS])?.filter(([fieldId,field]) => {
        return field.type !== "Category" 
        && visibleFields?.visibleFieldIds?.includes(fieldId) 
        && !field[RUNTIME_INSTANCE_IS_INPUT] 
        && isArray(field[RUNTIME_INSTANCE_FIELD_VALUE]) 
        && field[RUNTIME_INSTANCE_FIELD_VALUE].length > 0
    }).map(([fieldId,field]) => ({title: field[FIELD_NAME], description: isArray(field[RUNTIME_INSTANCE_FIELD_VALUE]) 
        ? (field[RUNTIME_INSTANCE_FIELD_VALUE] as (string|number)[])?.join(', ') 
        : (field[RUNTIME_INSTANCE_FIELD_VALUE] as string|number)}))


    return (
        <div>
        {
            isArray(summaryData) && summaryData?.length > 0 && <div className="sb3-pb-2">
                <Summary
                items={summaryData}/>
            </div>
        }
        {
            filters?.length > 0 && 
            <div className="sb3-flex sb3-gap-2 sb3-px-1.5 sb3-flex-wrap sb3-max-w-full sb3-pt-2">
                {
                    filters
                    .map(([fieldKey, field]) => {
                            return (field[RUNTIME_INSTANCE_FIELD_VALUE] as string[])?.map((value) => (
                                <span className={`sb3-text-xs ${platform === THEME_PLATFORMS.BIG_COMMERCE && "sb3-rounded-lg sb3-overflow-hidden"} sb3-border sb3-border-solid sb3-border-primary sb3-flex w-min  sb3-min-h-[25px] sb3-filter-chip`} style={{borderColor: styles.borderColor}}>
                                        <span className="sb3-px-1.5 sb3-w-max sb3-flex sb3-items-center">{field[RUNTIME_INSTANCE_FIELD_NAME]}</span>
                                        <span className="sb3-bg-primary sb3-text-white p- sb3-px-1.5 sb3-flex sb3-items-center sb3-w-max sb3-filter-chip-value" style={{backgroundColor: styles.backgroundColor}}>{(value)}
                                        <button type="button" onClick = {() => handleRemoveFilter(fieldKey, field[RUNTIME_INSTANCE_FIELD_VALUE] as string[], value)} 
                                        className="sb3-border-0 hover:sb3-text-white sb3-text-white hover:sb3-bg-[#0000] focus:sb3-text-white focus:sb3-border-0 hover:sb3-border-0 focus:!sb3-outline-none focus:sb3-bg-[#0000]"><FaTimes className="sb3-ml-2"/></button>
                                    </span>
                                </span>
                            ))
                        }
                    )
                }
                <BorderlessButton onClick={handleClearAllFilter} className="sb3-min-w-min sb3-px-0 focus:!sb3-outline-none !sb3-min-h-[25px] !sb3-py-0">Clear All</BorderlessButton>
            </div>
        }
        {  
            activeSubmodel.uiModel?.[FRMDATA_ITEMS]
            // (specs[activeSubmodel[MODEL_SUBMODEL_ID]] as TModel)[MODEL_DATA][MODEL_FIELDS]
            .filter((fieldB) => {
                const fieldR = instance?.[MODEL_DYNAMIC_FIELDS][fieldB[FIELD_ID]]

                return fieldB[FRMDATA_PLACEMENT] !== FIELD_PLACEMENT.GUIDED_SELLING 
                    && fieldR 
                    && visibleFields 
                    && visibleFields.visibleFieldIds.includes(fieldB[FIELD_ID]) 
                    && fieldR[RUNTIME_INSTANCE_IS_INPUT] 
            })
            .map((fieldB, key) => {
                if(!instance) return <></>

                const fieldR = instance[MODEL_DYNAMIC_FIELDS][fieldB[FIELD_ID]]
                
                const fieldValue = NodeHelper.getFieldValueByFieldR(fieldR)


                return (
                    <div className="last:sb3-border-b-0 sb3-border-b" key={key}>
                        <AccordionLayout initialState={true} resetOn={activeSubmodel[MODEL_SUBMODEL_ID] ? [activeSubmodel[MODEL_SUBMODEL_ID]] : []}>
                        {(provided) => (
                            <>
                                <AccordionHeader {...provided.getHeaderProps()}>
                                <div className="sb3-py-1.5 sb3-px-1.5"> 
                                    <Header 
                                        title={!fieldB[FRMDATA_DISPLAY_NAME] ? fieldB[FIELD_NAME] : fieldB[FRMDATA_DISPLAY_NAME]}
                                        className="sb3-max-w-full sb3-whitespace-break-spaces"
                                        required = {fieldR[RUNTIME_INSTANCE_FIELD_REQUIRED] && !(fieldValue && fieldValue?.length > 0)}
                                    />
                                </div>
                                </AccordionHeader>

                                <AccordionBody {...provided.getBodyProps()}>
                                    <div className={`sb3-pb-2 ${fieldB[FRMDATA_TYPE] === FIELD_TYPES.MULTI_CHOICE && "sb3-max-h-52"} sb3-px-1.5 sb3-overflow-auto sb3-overflow-x-hidden`}>
                                        <Field 
                                            required={fieldR[RUNTIME_INSTANCE_FIELD_REQUIRED]}
                                            instance={instance} 
                                            fieldB = {fieldB} 
                                            key={key}
                                            isFilter = {true}
                                            isSearchEnabled = {true}
                                            uiModel={activeSubmodel.uiModel}
                                            showType = {fieldB[FRMDATA_FIELD_TYPE]}
                                            submodel={specs[activeSubmodel[MODEL_SUBMODEL_ID]] as TModel}
                                            showLabel = {false}
                                        />
                                    </div>
                                </AccordionBody> 
                            </>
                            )}
                        </AccordionLayout>

                    </div>
                )
            }
            ) 
        }
        <div className="sb3-min-h-[150px] sb3-h-[150px] lg:sb3-min-h-0 lg:sb3-h-0 "/>

        </div>
    )
}

export default Filters