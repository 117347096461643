import BorderlessButton from "components/Button/BorderlessButton"
import Header from "components/Header"
import NumberFieldSmall from "components/Input/NumberFieldSmall"
import { FaRegTrashAlt } from "react-icons/fa"

type MyProps = {
    title: string,
    quantity: number,
    onQuantityChange: (value: string) => void
    handleDropConnection: () => void
}

const ConnectorItem = ({
    title,
    onQuantityChange,
    handleDropConnection
}: MyProps) => {
    return (
        <div className="sb3-flex sb3-text-xs sb3-font-semibold sb3-justify-between sb3-items-center sb3-border sb3-border-solid sb3-rounded sb3-p-1 sb3-px-3">
            <p>{title}</p>
            
            <div className="sb3-flex sb3-space-x-2 sb3-items-center">
                {/* <NumberFieldSmall name="Qty" value="" onChange={(e) => onQuantityChange(e.target.value)}/> */}

                <BorderlessButton onClick={() => handleDropConnection()} className="sb3-min-w-min">
                    <FaRegTrashAlt/>
                </BorderlessButton>
            </div>
        </div>
    )
}

export default ConnectorItem