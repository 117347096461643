import { PropsWithChildren, SetStateAction, createContext, useContext, useEffect, useState } from "react"
import * as types from "types/contexts/AppContext"
import * as consts from 'constants/constants'
import { toast } from "react-toastify"
import { TChoice, TModelSpec, TResultProduct, TRuntimeSpec, TUI_BuilderPage, } from "types"
import AppContextHelper from "./helper"
import AuthManager from "Services/AuthManager"
import _, { isArray } from "lodash"
import { useLocation } from "react-router-dom"
import useCart from "hooks/useCart"
import useApis from "hooks/useApis"
import NodeHelper from "helpers/NodeHelper"
import Utils from "Services/Utils"
import usePages from "hooks/usePages"

type TSubmodelFieldValues = {
    selectedValues: types.TSelectedValuesNotNull
    systemSelectedFields: string[]
}


export const AppContext = createContext<ReturnType<typeof AppContextValues> | undefined>(undefined)

type MyProps = {
    isOpen: boolean | undefined
    setIsOpen?: (value: boolean) => void,
    modelId?: string | undefined
    blank?: boolean
    setVariants?: boolean
}

/**
 * Global states and functions
 * 
 * Common states and functions to transform the states
 * are written here
 */
const AppContextValues = (isOpen: boolean | undefined, setIsOpen?: (value: boolean) => void, modelId?: string | undefined, blank?: boolean, setVariants?: boolean) => {
    const location = useLocation()

    const {
        generateSpecsFromModelSpec,
    } = AppContextHelper()

    // necessary specs
    const [uiDefn, setUiDefn] = useState<types.TuiDefn | null>(null)
    const [modelSpec, setModelSpec] = useState<types.TmodelSpec | null>(null)
    const [runtimeSpec, setRuntimeSpec] = useState<TRuntimeSpec | null>(null)
    const [cartInfo, setCartInfo] = useState<TResultProduct[]>([])

    // Loading states
    const [loading, setLoading] = useState<types.Tboolean>(true) // used initial loading(specs and data)
    const [dataLoading, setDataLoading] = useState<types.Tboolean>(false) // used for instance based updates
    const [loadingToCart, setLoadingToCart] = useState<boolean>(false) // last popup with loading when adding to cart in client

    const [isCartOpen, setIsCartOpen] = useState<boolean>(false)

    // creates spec object for easy retrieval .. like {id: Field/Category/Submodel}
    const [specs, setSpecs] = useState<types.TSpec>({})

    // if a disabled option is to be selected the user has to use a modal for 
    // rewriting conflicted data .. this state is used for visibility and data of the modal
    const [disabledModal, setDisabledModal] = useState<types.TdisabledModal | null>(null)

    const [availabilityFlag, setAvailabilityFlag] = useState<types.TisAvailabilityCheck>(false)
    const [isCloseModalOpen, setIsCloseModalOpen] = useState<boolean>(false)

    const [imagePreview, setImagePreview] = useState<string|undefined>()
     const [angle, setAngle] = useState<number>(0);


    const [colors, setColors] = useState({
        background: {
            default: null,
        },
        solidBtnBG: {
            default: null,
            hover: null,
            disabled: null
        },
        solidButtonLabel: {
            default: null
        },
        secondaryBackground: {
            default: null,
            light: null
        },
        subtitles: {
            default: null
        },
        title: {
            default: null
        },
        primaryColor: {
            default: null,
            hover: null,
            disabled: null
        }
    })

    // const [messages, setMessages] = useState<string[]>([])
    const [popups, setPopups] = useState<types.TPopup[]>([])

    // states to track active nodes
    const [activeField, setActiveField] = useState<types.TActiveField>(null)
    const [activePage, setActivePage] = useState<types.TActivePage|undefined>(undefined)
    const [activeSubmodel, setActiveSubmodel] = useState<types.TActiveSubmodel>(null)
 
    const {
        loadModelSpec,
        updateInstanceInput,
        updateInstanceInputs,
        createInstance,
        deleteInstance,
        createConnection,
        dropConnection,
    } = useApis(
        modelSpec,
        setUiDefn,
        setModelSpec,
        setRuntimeSpec,
        setCartInfo,
        setLoading,
        setDataLoading,
        setDisabledModal
    )

    const {
        cart,
        addProductToCart,
        removeProductFromCart,
        getCartTotalPrice,
        setCart,
        updateProductQty,
        generateCartFromProductData,
        submitEcommerceCart
    } = useCart(
        setRuntimeSpec, 
        loadModelSpec, 
        setDataLoading, 
        setCartInfo, 
        modelId, 
        modelSpec,
        setLoadingToCart,
        setVariants
    )

    const [warningFields, setWarningFields] = useState<types.TWarningFields>([]) // fields that are system selected
    const [dangerFields, setDangerFields] = useState<types.TWarningFields>([]) // fields that are system de-selected
    const [selectedValues, setSelectedValues] = useState<types.TSelectedValues>(null) // local copy of selected values

   
    const [categoryLabels, setCategoryLabels] = useState<types.TCategoryLabels>({})

    const [disableClick, setDisableClick] = useState<types.Tboolean>(false) // true if setInput is loading to invalidate any other clicks
    // map each submodel, field and category into id -> field/cat/submodel format
    const [fieldIdNameMap, setFieldIdNameMap] = useState<types.TFieldIdNameMap>(null)
    const [connectedInstances, setConnectedInstances] = useState<types.TconnectedInstances>([])

    const [filteredModelSpec, setFilteredModelSpec] = useState<types.TmodelSpec | null>(null) // @todo remove after duplicate fields are allowed
    const [viewConfigurations, setViewConfigurations] = useState<types.TViewConfigurations>(false)

    const [sidebarToggle, setSidebarToggle] = useState<boolean>(false)

    const [theme, setTheme] = useState<types.TTheme>(null)
    const [platform, setPlatform] = useState<types.TTheme>(null)
    const [sort, setSort] = useState<types.TSort>(consts.SORT.RECOMMENDED)

    // const initialUISpec = useRef<types.TuiDefn|null>(null)
    // const initialModalSpec = useRef<types.TmodelSpec|null>(null)

    const [showConfigurations, setShowConfigurations] = useState<{ name: string } | null>(null)

    const isPreview = location.pathname.includes(consts.PAGES.PREVIEW)
    const [isFilterOpen, setFilterOpen] = useState<types.TActiveSubmodel|undefined>(undefined)

    const [categoryDisplayType, setCategoryDisplayType] = useState<string | null>(null)

    const builderConfig = () => (window.compatioConfig)

    const builderSpec = uiDefn?.[consts.DEFN_BUILDER_PAGES] ?? null

    const [categoryProductsCount, setCategoryProductsCount] = useState<{[key: string]: {[x: string]: number}}>({})

    const [traversed, setTraversed] = useState(() => new Set<string>())

    useEffect(() => {
        if (categoryDisplayType || !uiDefn?.[consts.DEFN_BUILDER_PAGES]?.[consts.BUILDER_CONFIG][consts.CONFIG_CUSTOMIZATION][consts.CUSTOMIZATION_VIEW_PREF]) return

        const type = uiDefn?.[consts.DEFN_BUILDER_PAGES]?.[consts.BUILDER_CONFIG][consts.CONFIG_CUSTOMIZATION][consts.CUSTOMIZATION_VIEW_PREF] === "grid" ? consts.CATEGORY_DISPLAY_TYPES.GRID : consts.CATEGORY_DISPLAY_TYPES.LIST
        setCategoryDisplayType(type)
    }, [uiDefn])

    useEffect(() => {
        if (!AuthManager.isLoggedIn() && isOpen === false) return
        if (isOpen === false) return

        const variants = []
        const productSku = Utils.getClientProductSku()
        if (productSku && setVariants !== false) variants.push(productSku)
        resetStates()
        loadModelSpec(modelId === AuthManager.getProjectId() && (isOpen === true || isOpen === undefined), 0, true, modelId, variants, blank)
    }, [isOpen, modelId])

    useEffect(() => {
        if (!modelSpec || !uiDefn || !runtimeSpec || (!AuthManager.isLoggedIn() && isOpen === false)) {
            return
        }

        const launchOnFirstPage = uiDefn[consts.DEFN_BUILDER_PAGES]?.[consts.BUILDER_CONFIG]?.[consts.CONFIG_CUSTOMIZATION]?.[consts.CUSTOMIZATION_LAUNCH_WITH_FIRST_PAGE]

        const _modelSpec: TModelSpec = JSON.parse(JSON.stringify(modelSpec))
        // when page switches set the first empty field in that page as next field
        // could be field, connector or category
        // @todo set first field as initial even before starting this
        const _specs = generateSpecsFromModelSpec(_modelSpec)
        setSpecs(_specs)

        // if (!activePage || !activePage[consts.PAGE_FORM_DATA]) return setInitialPage()
        const submodelFields: TSubmodelFieldValues = {
            systemSelectedFields: [],
            selectedValues: {},
        };
        // const nonSubModelFields: TSubmodelFieldValues = {
        //   systemSelectedFields: [],
        //   selectedValues: {},
        // };
        const _fieldIDNameMap: types.TFieldIdNameMap = {}

        const currentSelectedFields = selectedValues ?
            Object.keys(selectedValues)
                .filter((fieldid) => selectedValues[fieldid].some((choice) => !!((choice as TChoice)[consts.CHOICE_IS_SELECTED] || (choice as { [consts.CHOICE_NAME]: string })[consts.CHOICE_NAME]))) : []

        const nextActive: types.TNextActiveField = {
            page: null,
            field: null,
            submodel: null,
            active: -1,
            activeInstance: -1,
            activePage: -1,
            instance: undefined,
            uiModel: undefined
        }

        /**
         * Used to generate cart from selected products in category in runtime spec
         */
        const cartProductIds: { categoryId: string, categoryName: string, instanceId: string, productIds: (string)[] }[] = []

        const pages = uiDefn[consts.DEFN_BUILDER_PAGES][consts.BUILDER_PAGES]
        const _categoryLabels: types.TCategoryLabels = {}
        pages.forEach((page) => {
            if (
                (activePage?.[consts.PAGE_AUTO_ADVANCE] &&
                page[consts.PAGE_ID] === activePage[consts.PAGE_ID])
                || !activePage
            ) {
                nextActive.activePage = 0
            }

            const alLeastOneSubmodelInstance = page[consts.PAGE_FORM_DATA].some((builderSubmodel) => {
                const instances = getInstances(builderSubmodel[consts.FRMDATA_SMID])
                return instances && instances?.filter(instance => !instance[consts.RUNTIME_INSTANCE_IS_EXCLUDED]).length > 0
            })

            const isPageDone = !page[consts.PAGE_FORM_DATA].some((submodelB) => {
                const instances = getInstances(submodelB[consts.FRMDATA_SMID]) ?? []
                return instances.some((instance) => {
                    return !NodeHelper.checkIfSubmodelIsDoneByRuntimeSpecForAutoAdvance(
                        instance,
                        page[consts.PAGE_LAYOUT]
                    )
                })
            })
            
            page[consts.PAGE_FORM_DATA].forEach((submodelB) => {
                const instances = getInstances(submodelB[consts.FRMDATA_SMID]) ?? []
                
                if (
                    activePage?.[consts.PAGE_AUTO_ADVANCE] &&
                    nextActive.activePage === 0 &&
                    submodelB[consts.FRMDATA_SMID] === activeSubmodel?.[consts.MODEL_SUBMODEL_ID]
                ) {
                    nextActive.active = 0
                }

                return instances.forEach((instance) => {
                    const nodeVisibility = NodeHelper.filterVisibleNodesUsingRuntime(
                        instance[consts.MODEL_DYNAMIC_FIELDS],
                        submodelB
                    )

                    if (
                        activePage?.[consts.PAGE_AUTO_ADVANCE] &&
                        nextActive.active === 0 &&
                        instance[consts.RUNTIME_INSTANCE_INSTANCE_ID] === activeSubmodel?.instance[consts.RUNTIME_INSTANCE_INSTANCE_ID]
                    ) {
                        nextActive.activeInstance = 0
                    }

                    submodelB[consts.FRMDATA_ITEMS]
                        .filter((fieldB) => {
                            if([consts.FIELD_TYPES.DIVIDER, consts.FIELD_TYPES.CONTENT_BLOCK].includes(fieldB[consts.FRMDATA_TYPE])) return false

                            // filter to only visible nodes
                            if (![consts.FIELD_TYPES.CATEGORY, consts.FIELD_TYPES.CONNECTOR].includes(fieldB[consts.FRMDATA_TYPE])) {
                                if (!_fieldIDNameMap[fieldB[consts.FRMDATA_ID]])
                                    _fieldIDNameMap[NodeHelper.getFieldKey(fieldB[consts.FRMDATA_ID], instance)] = !!fieldB[consts.FRMDATA_DISPLAY_NAME] ? fieldB[consts.FRMDATA_DISPLAY_NAME] : fieldB[consts.FIELD_NAME]
    
                                return nodeVisibility.visibleFieldIds.includes(fieldB[consts.FRMDATA_ID])
                            }
                            if (fieldB[consts.FRMDATA_TYPE] === consts.FIELD_TYPES.CATEGORY) {

                                if(fieldB[consts.FRMDATA_GROUP_LABEL]) {
                                    _categoryLabels[fieldB[consts.FRMDATA_ID]] = fieldB[consts.FRMDATA_GROUP_LABEL]
                                }
                                else {
                                    _categoryLabels[fieldB[consts.FRMDATA_ID]] = "Others"
                                }
                                return nodeVisibility.visibleCategoryIds.includes(fieldB[consts.FRMDATA_ID])
                            }
                            
                            return false
                        })
                        .forEach((fieldB) => {
                            const nodeInstance = instance[consts.MODEL_DYNAMIC_FIELDS][fieldB[consts.FRMDATA_ID]]

                            if (!nodeInstance) return

                            // if node is of type Category
                            if (fieldB[consts.FRMDATA_TYPE] === consts.FIELD_TYPES.CATEGORY) {
                                if (
                                    !nodeInstance
                                    || !isArray(nodeInstance[consts.RUNTIME_INSTANCE_FIELD_VALUE])
                                    || (nodeInstance[consts.RUNTIME_INSTANCE_FIELD_VALUE].length === 0)
                                ) return;

                                // to generate cart from selected product ids in categories
                                cartProductIds.push({
                                    categoryId: fieldB[consts.FRMDATA_ID],
                                    categoryName: nodeInstance[consts.RUNTIME_INSTANCE_FIELD_NAME],
                                    productIds: nodeInstance[consts.RUNTIME_INSTANCE_FIELD_VALUE] as string[],
                                    instanceId: instance[consts.RUNTIME_INSTANCE_INSTANCE_ID]
                                })
                                return 
                            }

                            // // if node is a field
                            // if (!_fieldIDNameMap[node[consts.FRMDATA_ID]])
                            //     _fieldIDNameMap[NodeHelper.getFieldKey(node[consts.FRMDATA_ID], instance)] = nodeInstance[consts.RUNTIME_INSTANCE_FIELD_NAME]

                            const selectedValue = instance[consts.MODEL_DYNAMIC_FIELDS][fieldB[consts.FRMDATA_ID]] &&
                                NodeHelper.getFieldValueByFieldR(instance[consts.MODEL_DYNAMIC_FIELDS][fieldB[consts.FRMDATA_ID]])

                            // const allDisabled = NodeHelper.checkIfAllChoicesAreDisabled(instance[consts.MODEL_DYNAMIC_FIELDS][node[consts.FRMDATA_ID]][consts.RUNTIME_INSTANCE_CHOICES])

                            if (selectedValue && !currentSelectedFields.includes(fieldB[consts.FRMDATA_ID])) submodelFields.systemSelectedFields?.push(fieldB[consts.FRMDATA_ID])
                            if(selectedValue && submodelFields.selectedValues) submodelFields.selectedValues[NodeHelper.getFieldKey(fieldB[consts.FRMDATA_ID], instance)] = selectedValue
                            
                            // generate array of selected inputs 
                            if (selectedValue && submodelFields.selectedValues)
                                submodelFields.selectedValues[NodeHelper.getFieldKey(fieldB[consts.FRMDATA_ID], instance)] = selectedValue

                            return 
                        })

                    // return instances.every((instance) => {
                    //     return NodeHelper.checkIfSubmodelIsDoneByRuntimeSpec(instance, page[consts.PAGE_LAYOUT])
                    // })
                })
            })

            if(
                (activePage?.[consts.PAGE_AUTO_ADVANCE] &&
                alLeastOneSubmodelInstance &&
                !isPageDone &&
                nextActive.activePage === 0) || (
                    !activePage 
                    && !nextActive.page 
                    && !isPageDone)
            ) {
                nextActive.page = page
                nextActive.activePage = 1
            } 

            // if(
            //     activePage?.[consts.PAGE_AUTO_ADVANCE] &&
            //     alLeastOneSubmodelInstance &&
            //     !isPageDone && 
            //     !nextActive.page
            // ) {
            //     nextActive.page = page
            // } 
        })

        const checkIfAnyPageIsNotDone = pages.some((page) => page[consts.PAGE_FORM_DATA].some((builderSubmodel) => {
            const instances = getInstances(builderSubmodel[consts.FRMDATA_SMID])
            return instances && instances?.filter(instance => !instance[consts.RUNTIME_INSTANCE_IS_EXCLUDED]).length > 0
                && instances.some((instance) => {
                    return !NodeHelper.checkIfSubmodelIsDoneByRuntimeSpecForAutoAdvance(
                        instance,
                        page[consts.PAGE_LAYOUT]
                    )
                })
        })) ?? null

        if(activePage === undefined && launchOnFirstPage){
            nextActive.page = pages.find((page) => page[consts.PAGE_FORM_DATA].some((builderSubmodel) => {
                const instances = getInstances(builderSubmodel[consts.FRMDATA_SMID])
                return instances && instances?.filter(instance => !instance[consts.RUNTIME_INSTANCE_IS_EXCLUDED]).length > 0
            })) ?? null
        }

        // if(activePage === undefined && !uiDefn[consts.DEFN_BUILDER_PAGES][consts.BUILDER_CONFIG][consts.CONFIG_CUSTOMIZATION][consts.CUSTOMIZATION_LAUNCH_WITH_FIRST_PAGE]) {
            // nextActive.page = pages.find((page) => page[consts.PAGE_FORM_DATA].some((builderSubmodel) => {
            //     const instances = getInstances(builderSubmodel[consts.FRMDATA_SMID])
            //     return instances && instances?.filter(instance => !instance[consts.RUNTIME_INSTANCE_IS_EXCLUDED]).length > 0
            // })) ?? null
        // }

        // if no field or submodel is set set the first one in page
        // if (!nextActive.field && !nextActive.submodel && activePage[consts.PAGE_FORM_DATA][0] && theme !== consts.THEMES.CONFIGURATOR) {
        //     const submodel: TModel = _specs[activePage[consts.PAGE_FORM_DATA][0][consts.FRMDATA_SMID]]
        //     const _instances = getInstances(activePage[consts.PAGE_FORM_DATA][0][consts.FRMDATA_SMID]) ?? []

        //     nextActive.field = submodel[consts.MODEL_DATA][consts.MODEL_FIELDS][0]
        //     nextActive.instance = _instances[0]
        //     nextActive.submodel = submodel
        //     nextActive.uiModel = activePage[consts.PAGE_FORM_DATA][0]
        // }

        // Get next field for fields outside
        // getNextActiveField(
        //   nonSubModelFields,
        //   undefined,
        //   _modelSpec[consts.DEFN_PROJECT][consts.MODEL_FIELDS],
        //   _modelSpec[consts.DEFN_PROJECT][consts.MODEL_CATEGORIES],
        //   _fieldIDNameMap,
        //   activeField?.field as TField,
        //   undefined,
        //   currentSelectedFields,
        //   nextActive,
        //   (field) => field[consts.MODEL_DATA],
        //   (field) => { _specs[field[consts.FIELD_ID]] = field }
        // )

        const fieldIds: string[] = Object.keys({
            ...submodelFields.selectedValues,
            // ...nonSubModelFields.selectedValues
        })
        const _difference = currentSelectedFields.filter(x => !fieldIds.includes(x));

        // setActiveField({
        //     field: nextActive.field,
        //     instance: nextActive.instance as TRuntimeInstance,
        //     type: nextActive.type,
        //     uiModel: nextActive.uiModel as TUI_BuilderSubmodel
        // })
        setSelectedValues({ ...submodelFields.selectedValues })
        setFieldIdNameMap(Object.keys(_fieldIDNameMap).length > 0 ? _fieldIDNameMap : null)
        setDangerFields(_difference)
        setWarningFields([...submodelFields.systemSelectedFields,
            // ...nonSubModelFields.systemSelectedFields
        ])
        setViewConfigurations(!nextActive.field)

        // if (isAllRequiredSelected.current) setActiveSubmodel(null)
        // if (!isAllRequiredSelected.current && nextActive.submodel)
        //     setActiveSubmodel({ ...nextActive.submodel, instance: nextActive.instance as TRuntimeInstance, uiModel: nextActive.uiModel as TUI_BuilderSubmodel })
        // else setActiveSubmodel(null)

        if ((activePage?.[consts.PAGE_AUTO_ADVANCE] && (nextActive.page || !checkIfAnyPageIsNotDone)) || activePage === undefined) {
            setActivePage(nextActive.page)
        }
        // if(nextActive.page && nextActive.page[consts.PAGE_LAYOUT] === consts.PAGE_LAYOUTS.GUIDED_SELLING) setActiveSubmodel
        setFilteredModelSpec(_modelSpec)

        setDisableClick(false)
        setDataLoading(false)
        setCategoryLabels(_categoryLabels)
        
        generateCartFromProductData(cartProductIds, cartInfo)

        // @todo - remove when panel board solution is found 
        // where available poles goes into -ve
        const instance = runtimeSpec && Object.values(runtimeSpec).find((instance) => instance.class_type === "be25f0fc402f11efb6a70eae795c602d")
        const rightInstance = runtimeSpec && Object.values(runtimeSpec).find((instance) => instance.class_type === "861c005e410911ef8e30ae644f5d7b70")
    
        const availableLeft = +((instance?.dynamic_fields?.["82064ba041d611efab57ae644f5d7b70"]?.value as string[])?.[0] ?? 0)
        const availableRight = +((rightInstance?.dynamic_fields?.["e633cd4041d711ef931dae644f5d7b70"]?.value as string[])?.[0] ?? 0)
        if(runtimeSpec && (availableLeft < 0 || availableRight < 0)) 
            toast.error("Branch circuit breakers exceeded available slots")

        setPlatform(uiDefn[consts.DEFN_BUILDER_PAGES][consts.BUILDER_CONFIG][consts.CONFIG_CUSTOMIZATION][consts.CUSTOMIZATION_PLATFORM] ?? consts.THEME_PLATFORMS.CUSTOM)
        setCategoryProductsCount({})
    }, [modelSpec, runtimeSpec, uiDefn])

    // useEffect(() => {
    //     setColors({
    //         background: {
    //             default: builderSpec?.[BUILDER_CONFIG]?.[CONFIG_CUSTOMIZATION]?.[CUSTOMIZATION_THEME_SETTINGS]?.[THEME_PRIMARY_COLOR],
    //         },
    //         solidBtnBG: {
    //             default: builderSpec?.[BUILDER_CONFIG]?.[CONFIG_CUSTOMIZATION]?.[CUSTOMIZATION_THEME_SETTINGS]?.[THEME_PRIMARY_COLOR],
    //             hover: null,
    //             disabled: null
    //         },
    //         solidButtonLabel: {
    //             default: builderSpec?.[BUILDER_CONFIG]?.[CONFIG_CUSTOMIZATION]?.[CUSTOMIZATION_THEME_SETTINGS]?.[THEME_PRIMARY_COLOR]
    //         },
    //         secondaryBackground: {
    //             default: builderSpec?.[BUILDER_CONFIG]?.[CONFIG_CUSTOMIZATION]?.[CUSTOMIZATION_THEME_SETTINGS]?.[THEME_PRIMARY_COLOR],
    //             light: null
    //         },
    //         subtitles: {
    //             default: builderSpec?.[BUILDER_CONFIG]?.[CONFIG_CUSTOMIZATION]?.[CUSTOMIZATION_THEME_SETTINGS]?.[THEME_PRIMARY_COLOR]
    //         },
    //         title: {
    //             default: builderSpec?.[BUILDER_CONFIG]?.[CONFIG_CUSTOMIZATION]?.[CUSTOMIZATION_THEME_SETTINGS]?.[THEME_PRIMARY_COLOR]
    //         },
    //         primaryColor: {
    //             default: builderSpec?.[BUILDER_CONFIG]?.[CONFIG_CUSTOMIZATION]?.[CUSTOMIZATION_THEME_SETTINGS]?.[THEME_PRIMARY_COLOR],
    //             hover: null,
    //             disabled: null
    //         }
    //     })
    // }, [uiDefn])


    useEffect(() => {
        if (!runtimeSpec) return

        const ids = Object.values(runtimeSpec)?.reduce((acc: string[], instance) => {
            if (instance[consts.MODEL_CONNECTED_IDS]) acc.push(...instance[consts.MODEL_CONNECTED_IDS])

            return acc
        }, [])
        setConnectedInstances(ids)
    }, [runtimeSpec])

    const setPage = (pageId: string) => {
        if (!uiDefn) return

        const page = uiDefn[consts.DEFN_BUILDER_PAGES][consts.BUILDER_PAGES].find((page) => pageId === page[consts.PAGE_ID])
        if(page?.[consts.PAGE_ID]) setTraversed((prev) => new Set(prev).add(page[consts.PAGE_ID]))
        setActivePage(page ?? null)
    }

    // const setInitialPage = () => {
    //     if (!uiDefn) return

    //     if (!(consts.DEFN_BUILDER_PAGES in uiDefn) || !(consts.BUILDER_PAGES in uiDefn[consts.DEFN_BUILDER_PAGES]) ||
    //         !_.isArray(uiDefn[consts.DEFN_BUILDER_PAGES][consts.BUILDER_PAGES]) ||
    //         uiDefn[consts.DEFN_BUILDER_PAGES][consts.BUILDER_PAGES].length < 1) return

    //     const page = uiDefn[consts.DEFN_BUILDER_PAGES][consts.BUILDER_PAGES][0]
    //     setActivePage(page ?? null)
    // }

    const getInstances = (submodelId: string) => {
        return runtimeSpec && Object.values(runtimeSpec).filter((instance) =>
            instance[consts.RUNTIME_INSTANCE_BASE_ID] === submodelId
        )
    }

    const handleReset = (loadSpec: boolean = true) => {
        AuthManager.removeSessionId()
        resetStates()
        if (loadSpec) {
            const variants = []
            const productSku = Utils.getClientProductSku()
            if (productSku && setVariants !== false) variants.push(productSku)
            loadModelSpec(false, 0, true, modelId, variants, blank)
        }
    }

    const resetStates = () => {
        setSpecs({})
        setCart([])
        // setActiveSubmodel(null)
        setActivePage(undefined)
        setCategoryDisplayType(null)
        setAvailabilityFlag(false)
        setLoading(true)
        setUiDefn(null)
        setModelSpec(null)
        setRuntimeSpec(null)
        setSelectedValues(null)
        setFilteredModelSpec(null)
        setActiveField(null)
        setDangerFields([])
        setWarningFields([])
        setTheme(null)
        setTraversed(new Set())
        setPlatform(null)
        setFilterOpen(undefined)
        setPopups([])
        setSort(consts.SORT.RECOMMENDED)
        setIsCartOpen(false)
    }

    const handleFieldSelect: types.ThandleFieldSelect = (field, instance, type, uiModel) => {
        setWarningFields([])
        setDangerFields((prev) => prev.filter(fieldId => fieldId !== field[consts.FIELD_ID]))
        setViewConfigurations(false)
        setActiveField({
            field,
            instance,
            type,
            uiModel
        })
    }

    // const checkIfPageIsDone = (page: TUI_BuilderPage) => {
    //     return page[consts.PAGE_FORM_DATA].every((submodelB) => {
    //         const instances = getInstances(submodelB[consts.FRMDATA_SMID])
    //         return instances?.every((instance) => {
    //             return NodeHelper.checkIfSubmodelIsDoneByRuntimeSpec(
    //                 instance
    //             )
    //         })
    //     })
    // }

    const _removeProductFromCart = (product: TResultProduct, category: string, instanceId: string) => {
        const instance = runtimeSpec?.[instanceId]
        if(instance) {
            const page = uiDefn?.[consts.DEFN_BUILDER_PAGES][consts.BUILDER_PAGES].find(page => {
                return page[consts.PAGE_FORM_DATA].find(submodel => (
                    submodel[consts.FRMDATA_SMID] === instance[consts.RUNTIME_INSTANCE_BASE_ID]
                ))
            })
            if(page) setPage(page[consts.PAGE_ID])
        }
        
        removeProductFromCart(product, category, instanceId)
    }

    const createNewPopup = (title: string, message: string, id: string, defaultSize: "small"|"medium"|"large"|"auto" = "auto") => {
        setPopups(prev => {
            const index = prev.findIndex(p => p.uniqueId === id);
            if(index !== -1) return prev
            
            return [
                ...prev,
                {
                    id: prev.length === 0 ? 1 : (_.max(prev.map(i => i.id)) ?? 0) + 1,
                    title: title,
                    uniqueId: id,
                    message,
                    position: Utils.calculatePosition(prev.length),
                    size: { width: (Math.min(window.innerWidth * 0.95, consts.INFO_SIZES[defaultSize].width ?? 300)), height: (Math.min(window.innerHeight * 0.95, consts.INFO_SIZES[defaultSize].height ?? 200)) },
                    zIndex: prev.length,
                    isResizing: false,
                    isMinimized: false,
                    prevHeight: 200
                }
            ]
        })
    }

    const isLinear = builderSpec?.[consts.BUILDER_CONFIG][consts.CONFIG_CUSTOMIZATION][consts.CUSTOMIZATION_NAVIGATION] === "linear"

    const activePageIndex = uiDefn?.[consts.DEFN_BUILDER_PAGES][consts.BUILDER_PAGES].findIndex(p => p[consts.PAGE_ID] === activePage?.[consts.PAGE_ID]) ?? 0

    const handleNextPage = (skip: boolean = false) => {
        if(!skip && isLinear && activePage?.[consts.PAGE_FORM_DATA].some((builderSubmodel) => {
            const _instances = getInstances(builderSubmodel[consts.FRMDATA_SMID]) ?? []
            return _instances.some((instance) => !NodeHelper.checkIfSubmodelIsDoneByRuntimeSpec(
                instance
            ))
        })) return toast.error("Complete all the required fields")

        if(!uiDefn) return
        
        if(uiDefn?.[consts.DEFN_BUILDER_PAGES][consts.BUILDER_PAGES].length > activePageIndex) 
            setActivePage(uiDefn?.[consts.DEFN_BUILDER_PAGES][consts.BUILDER_PAGES]
            .find((page, index) => page[consts.PAGE_FORM_DATA].some((builderSubmodel) => {
                if(index <= activePageIndex) return false
                const instances = getInstances(builderSubmodel[consts.FRMDATA_SMID])
                return instances && instances?.filter(instance => !instance[consts.RUNTIME_INSTANCE_IS_EXCLUDED]).length > 0
            })
        ))
    }


    const handlePrevPage = () => {
        if(!uiDefn) return
        
        if(uiDefn?.[consts.DEFN_BUILDER_PAGES][consts.BUILDER_PAGES].length > activePageIndex){ 
            const pagesLength = uiDefn?.[consts.DEFN_BUILDER_PAGES][consts.BUILDER_PAGES].length
            
            setActivePage(uiDefn?.[consts.DEFN_BUILDER_PAGES][consts.BUILDER_PAGES]
                .findLast((page, index) => page[consts.PAGE_FORM_DATA].some((builderSubmodel) => {
                    if(index >= activePageIndex) return false
                    const instances = getInstances(builderSubmodel[consts.FRMDATA_SMID])
                    return instances && instances?.filter(instance => !instance[consts.RUNTIME_INSTANCE_IS_EXCLUDED]).length > 0
                })
            ) ?? uiDefn?.[consts.DEFN_BUILDER_PAGES][consts.BUILDER_PAGES][0])
        }
    }


    const ifAnyVisibleCategory = activePage?.[consts.PAGE_FORM_DATA]?.some((uiModel, modelKey) => {
        const _instances = getInstances(uiModel[consts.FRMDATA_SMID]) ?? []
        return _instances.some((instance, key) => {
            const visibleNodes = NodeHelper.filterVisibleNodesUsingRuntime(
                instance[consts.MODEL_DYNAMIC_FIELDS],
                uiModel
            )
            return visibleNodes.visibleCategoryIds.length > 0
        })
    })

    const lightSecondaryColor = builderSpec?.[consts.BUILDER_CONFIG][consts.CONFIG_CUSTOMIZATION][consts.CUSTOMIZATION_THEME_SETTINGS][consts.THEME_SECONDARY] 
        && Utils.newShade(builderSpec[consts.BUILDER_CONFIG][consts.CONFIG_CUSTOMIZATION][consts.CUSTOMIZATION_THEME_SETTINGS][consts.THEME_SECONDARY], 25)
    

    // const builderSpec = undefined

    return {
        uiDefn,
        modelSpec,
        loading,
        filteredModelSpec,
        warningFields,
        setWarningFields,
        dangerFields,
        setDangerFields,
        dataLoading,
        disableClick,
        setDisableClick,
        fieldIdNameMap,
        selectedValues,
        setSelectedValues,
        activeField,
        setActiveField,
        specs,
        activePage,
        setActivePage,
        setPage,
        getInstances,
        createInstance,
        createConnection,
        dropConnection,
        deleteInstance,
        updateInstanceInput,
        runtimeSpec,
        connectedInstances,
        handleReset,
        disabledModal,
        setDisabledModal,
        theme,
        setTheme,
        viewConfigurations,
        setViewConfigurations,
        sort,
        setSort,
        sidebarToggle,
        setSidebarToggle,
        handleFieldSelect,
        activeSubmodel,
        setActiveSubmodel,
        builderSpec,
        showConfigurations,
        setShowConfigurations,
        builderConfig,
        setIsOpen,
        isOpen,
        isPreview,
        isFilterOpen,
        setFilterOpen,
        cart,
        addProductToCart,
        removeProductFromCart: _removeProductFromCart,
        getCartTotalPrice,
        setCart,
        updateProductQty,
        updateInstanceInputs,
        categoryDisplayType,
        setCategoryDisplayType,
        availabilityFlag,
        setAvailabilityFlag,
        loadingToCart,
        setLoadingToCart,
        // checkIfPageIsDone,
        categoryLabels,
        isCloseModalOpen,
        setIsCloseModalOpen,
        imagePreview,
        setImagePreview,
        modelId,
        angle,
        setAngle,
        traversed,
        platform,
        setPlatform,
        categoryProductsCount,
        setCategoryProductsCount,
        createNewPopup,
        setPopups,
        popups,
        isLinear,
        activePageIndex,
        handleNextPage,
        handlePrevPage,
        ifAnyVisibleCategory,
        isCartOpen,
        setIsCartOpen,
        submitEcommerceCart,
        lightSecondaryColor
    }
}

export const AppContextProvider = ({ children, isOpen, setIsOpen, modelId, blank, setVariants }: PropsWithChildren<MyProps>) => {
    return (
        <AppContext.Provider value={AppContextValues(isOpen, setIsOpen, modelId, blank, setVariants)}>
            {children}
        </AppContext.Provider>
    )
}

export const useAppContext = () => {
    const context = useContext(AppContext);
    if (context === undefined) {
        throw new Error('useAppContext must be used within an AppContextProvider');
    }
    return context;
};