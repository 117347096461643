import { ApolloClient, InMemoryCache, QueryOptions, gql } from "@apollo/client";
import { toast } from "react-toastify";
import * as consts from 'constants/constants';
import { TResultProduct } from "types";
import { get } from "lodash";

const queryFn = (entityIds: string) => gql`
  query Products {
    site {
      products(
        first: 50
        entityIds: ${entityIds}
      ) {
           edges {
            node {
              entityId
              sku
              name
              path
              description
              defaultImage {
                url(width: 1000)
                altText
              }
              brand {
                name
              }
              minPurchaseQuantity
              maxPurchaseQuantity
              customFields {
                edges {
                    node {
                        name
                        value
                    }
                }
              }
              prices {
                price {
                  value
                  currencyCode
                }
                salePrice {
                  value
                  currencyCode
                }
                retailPrice {
                  value
                  currencyCode
                }
                basePrice {
                  value
                  currencyCode
                }
              }
              inventory {
                isInStock
                aggregated {
                  availableToSell
                }
              }
            }
          }
        }
      }
  }
`;

class BigCommerceHelper {
    static getVariantId = (encryptedID: string) => {
        let str = encryptedID;
        str = str.split('/')[str.split('/').length - 1];
        return str;
    }
    static convertGraphQLToCompatioFormat = (graphQLData: TBigCommerceGraphqlResponse) => {
      const productsVariants: {[x: string]: any} = {};
    
      // Process each product
      graphQLData.data.site.products.edges.forEach(({ node: product }) => {
        if (!product) return;
        
        const productID = product.entityId;

        productsVariants[productID] = {
          [consts.RESULT_PRDT_NAME]: product.name,
          [consts.RESULT_PRDT_SKU]: productID,
          [consts.RESULT_PRDT_DESC]: product.customFields.edges.find(
            field => field.node.name === "Short Description"
          )?.node.value,
          [consts.RESULT_PRDT_URL_IMAGE]: product.defaultImage?.url,
          [consts.RESULT_PRDT_STOCK_STATUS]: product.inventory?.isInStock ? consts.STOCK_STATUSES.IN_STOCK : consts.STOCK_STATUSES.OUT_OF_STOCK,
          [consts.RESULT_PRDT_PRICE]: parseFloat(product.prices?.price?.value + "") || 0,
          [consts.RESULT_PRDT_MODEL_NO]: product.sku,
          [consts.RESULT_MIN_PURCHASE_QTY]: product.minPurchaseQuantity,
          [consts.RESULT_MAX_PURCHASE_QTY]: product.maxPurchaseQuantity,
          [consts.RESULT_MANUFACTURER]: product.brand?.name,
          [consts.RESULT_PRDT_URL]: !!product.path.substring(1) ? 
              get(window.compatioConfig, 'bigcommerce.baseUrl', '') +
              product.path.substring(1) : undefined
        };
    
        // Initialize product if it has variants and inventory
        // if (product.variants?.edges 
          // && product.inventory?.aggregated?.availableToSell
        // ) {
          // if (!productsVariants[productID]) {
            
          // }
    
          // // Process variants
          // product.variants.edges.forEach(({ node: variant }) => {
          //   if (!variant) return;
    
          //   const variantID = this.getVariantId(variant.sku);
            
          //   productsVariants[productID][consts.RESULT_PRDT_OPTIONS].push({
          //     [consts.RESULT_PRDT_SKU]: variantID,
          //     [consts.RESULT_PARENT_ID]: productID,
          //     variantTitle: variant.name || '',
          //     [consts.RESULT_PRDT_NAME]: variant.name ? `${product.name} - ${variant.name}` : product.name,
          //     [consts.RESULT_PRDT_DESC]: product.description,
          //     [consts.RESULT_PRDT_URL]: window.compatioConfig?.bigcommerce?.baseUrl + (product.path || ''),
          //     [consts.RESULT_PRDT_URL_IMAGE]: variant.defaultImage?.url || '',
          //     [consts.RESULT_PRDT_PRICE]: parseFloat(variant.prices?.price?.value + "") || 0,
          //     availableForSale: variant.inventory?.aggregated?.availableToSell || false,
          //     [consts.RESULT_PRDT_STOCK_STATUS]: variant.inventory?.isInStock ? "IN_STOCK" : "OUT_OF_STOCK"
          //   });
          // });
        // }
      });
    
      return productsVariants;
    };
    
  
    static fetchProducts: <T extends (...args: any) => any>(merchantProductSkus: string[], callbackFn: T) => Promise<ReturnType<T>> = (
        merchantProductSkus, 
        callbackFn
      ) => {
      return new Promise((resolve, reject) => {
        if (!window.compatioConfig?.bigcommerce?.apiEndpointUrl) {
          reject("BigCommerce API endpoint not configured");
          return;
        }

        const headers: Record<string, string> = {}

        if(window.compatioConfig.bigcommerce.apiToken)
          headers["Authorization"] = "Bearer " + window.compatioConfig.bigcommerce.apiToken
  
        try {
          const client = new ApolloClient({
            uri: window.compatioConfig.bigcommerce.apiEndpointUrl,
            cache: new InMemoryCache(),
            connectToDevTools: false,
            headers
          });

          const cleanedSkus = merchantProductSkus
            .filter(sku => /^\d+$/.test(sku))  // Keep only pure number strings
            .map(Number);

          if (cleanedSkus.length === 0) {
            return resolve([] as any)
          }
  
          const queryParams: QueryOptions = {
            query: queryFn(JSON.stringify(cleanedSkus)),
          };
  
          // Add store context if configured
          if (window.compatioConfig?.compatibleProducts?.[consts.CMPT_CONF_STORE]) {
            queryParams.context = {
              headers: {
                store: window.compatioConfig.compatibleProducts[consts.CMPT_CONF_STORE]
              }
            };
          }
  
        //   // Add authentication headers if configured
        //   if (window.compatioConfig.bigcommerce.accessToken) {
        //     if (!queryParams.context) queryParams.context = { headers: {} };
        //     queryParams.context.headers['Authorization'] = `Bearer ${window.compatioConfig.bigcommerce.accessToken}`;
        //   }
  
          client.query(queryParams)
            .then((res) => {
              resolve(callbackFn(res));
            })
            .catch((err) => {
              // toast.error("Bigcommerce GraphQL Error");
              // console.error('BigCommerce GraphQL Error:', err);
              reject(err);
            });
        } catch (err) {
          // console.debug('GraphQL Error Response:', err);
          reject(err);
        }
      });
    };


    static getProductsBySkus: (merchantProductSkus: string[]) => Promise<ReturnType<typeof this.convertGraphQLToCompatioFormat>> = (
        merchantProductSkus: string[],
        ) =>{
        return this.fetchProducts(merchantProductSkus, this.convertGraphQLToCompatioFormat)
    }

    static getCartProducts:(products: TResultProduct[]) => Promise<TResultProduct[]> = async (
        products
      ) => {
        const graphQLData: TBigCommerceGraphqlResponse = await this.fetchProducts(products.map(product => product[consts.RESULT_PRDT_SKU]), (res) => res)
        // const productSkus = products.map(product => product[consts.RESULT_PRDT_SKU])
    
        const graphQlProducts: any = {}
    
        graphQLData.data.site.products.edges.forEach(({ node: product }) => {
          const productID = product.entityId

          graphQlProducts[productID] = {
            [consts.RESULT_PRDT_NAME]: product.name,
            [consts.RESULT_PRDT_SKU]: productID,
            [consts.RESULT_PRDT_DESC]: product.customFields.edges.find(
              field => field.node.name === "Short Description"
            )?.node.value,
            [consts.RESULT_PRDT_URL_IMAGE]: product.defaultImage?.url,
            [consts.RESULT_PRDT_STOCK_STATUS]: product.inventory?.isInStock ? consts.STOCK_STATUSES.IN_STOCK : consts.STOCK_STATUSES.OUT_OF_STOCK,
            [consts.RESULT_PRDT_PRICE]: parseFloat(product.prices?.price?.value + "") || 0,
            [consts.RESULT_PRDT_MODEL_NO]: product.sku,
            [consts.RESULT_MIN_PURCHASE_QTY]: product.minPurchaseQuantity,
            [consts.RESULT_MAX_PURCHASE_QTY]: product.maxPurchaseQuantity,
            [consts.RESULT_MANUFACTURER]: product.brand?.name,
            [consts.RESULT_PRDT_URL]: !!product.path.substring(1) ? 
                get(window.compatioConfig, 'bigcommerce.baseUrl', '') +
                product.path.substring(1) : undefined
          }
        })
        return graphQlProducts
      }
  }

export default BigCommerceHelper;