import { CART_INFO, CMPT_CONF_COMPATIO_ADD_TO_CART, CMPT_CONF_PRICE_ENGINE, RESULT_MAX_PURCHASE_QTY, RESULT_MIN_PURCHASE_QTY, RESULT_PRDT_CATEGORY_NAME, RESULT_PRDT_IS_QUOTE, RESULT_PRDT_MODEL_NO, RESULT_PRDT_PRICE, RESULT_PRDT_QTY, RESULT_PRDT_SKU, RESULT_PRDT_STOCK_STATUS, RUNTIME_INSTANCE_INSTANCE_ID, RUNTIME_SPEC, STOCK_STATUSES } from "constants/constants"
import { Dispatch, SetStateAction,  useState } from "react"
import { toast } from "react-toastify"
import { TCart, TCartItem } from "types/Cart"
import { TResultProduct, TRuntimeInstance, TRuntimeSpec } from "types"
import { setInstanceInput } from "api"
import Utils from "Services/Utils"
import MagentoHelper from "helpers/Clients/Magento"
import ShopifyHelper from "helpers/Clients/Shopify"
import PriceEngine from "api/PriceEngineApi"
import { TmodelSpec } from "types/contexts/AppContext"
import BigCommerceHelper from "helpers/Clients/BigCommerce"
import ProductHelper from "helpers/ProductHelper"
import _ from "lodash"

/**
 * Cart based context 
 */

const useCart = (
    setRuntimeSpec: Dispatch<SetStateAction<TRuntimeSpec | null>>, 
    loadModelSpec: (setSessionId?: boolean, iter?: number, isReset?: boolean, modelId?: string, variants?: string[]) => Promise<void>, 
    setDataLoading: Dispatch<SetStateAction<boolean>>,
    setCartInfo: Dispatch<SetStateAction<TResultProduct[]>>,
    modelId: string|undefined,
    modelSpec: TmodelSpec,
    setLoadingToCart: Dispatch<SetStateAction<boolean>>,
    setVariants?: boolean
) => {
    const [cart, setCart] = useState<TCart>([])
    
    const addProductToCart = (product: TResultProduct, quantity: number, category: string, instance: TRuntimeInstance, categoryName?: string) => {
        if(!product[RESULT_PRDT_SKU]) return toast.error("SKU not found")

        // if product is already in cart
        if(Utils.findProductIndex(cart, product, category, instance[RUNTIME_INSTANCE_INSTANCE_ID]) !== -1) return
        setDataLoading(true)

        const productValue = Array(quantity).fill(product[RESULT_PRDT_SKU])

        setCart((prev) => {
            const _prevCart = [...prev]
            setInstanceInput(category, [
                ...prev.filter((_product) => _product.category === category && _product.instanceId === instance[RUNTIME_INSTANCE_INSTANCE_ID])
                .reduce((acc: TCartItem[], _product) => {
                    for(let i = 0; i < _product.quantity; i++) {
                        acc.push(_product)
                    }
                    return acc
                }, [])
                .map(_product=> `${_product.sku}`), 
                ...productValue
            ], instance[RUNTIME_INSTANCE_INSTANCE_ID])
            .then((res: any) => {
                setRuntimeSpec(Utils.formatRuntimeSpec(res[RUNTIME_SPEC])) 
                if(res[CART_INFO]?.length>0) setCartInfo(res[CART_INFO] ?? [])
            })
            .catch((err) => {
                setCart(_prevCart)
                toast.error(err.message)
                // console.error(err)
            })
            .finally(() => setDataLoading(false))

            return [...prev, {...product, quantity: quantity, category, instanceId: instance[RUNTIME_INSTANCE_INSTANCE_ID], [RESULT_PRDT_CATEGORY_NAME]: categoryName ?? ""}]
        })
    }

    const updateProductQty = (product: TResultProduct, quantity: number, category: string, instanceId: string, apiCall: boolean = true) => {
        if(!product[RESULT_PRDT_SKU]) return toast.error("SKU not found")
        setDataLoading(true)

        setCart(prev => {
            if(apiCall) setInstanceInput(category, [
                ...prev.filter((_product) => _product.category === category && _product.instanceId === instanceId)
                .reduce((acc: string[], _product) => {
                    let _quantity = _product.sku !== product.sku ? _product.quantity : quantity
                    for(let i = 0; i < _quantity; i++) {
                        acc.push(`${_product.sku}`)
                    }
                    return acc
                }, []), 
                // ...[...Array(quantity)].map(() => product[RESULT_PRDT_SKU])
            ], instanceId)
            .then((res: any) => {
                setRuntimeSpec(Utils.formatRuntimeSpec(res[RUNTIME_SPEC])) 
                if(res[CART_INFO]?.length>0) setCartInfo(res[CART_INFO] ?? [])
            })
            .catch(() => {
                const variants = []
                const productSku = Utils.getClientProductSku()
                if(productSku && setVariants !== false) variants.push(productSku)
                loadModelSpec(false, 0, true, modelId, variants)
                // setCart(prevCart)
            })
            .finally(() => setDataLoading(false))

            return prev
        })
    }


    const removeProductFromCart = (product: TResultProduct, category: string, instanceId: string) => {
        // check if product already exists in cart add the quantity
        setCart((prev) => {
            const _prevCart = [...prev]
            const newCart = [...prev]
            
            const index = Utils.findProductIndex(_prevCart, product, category, instanceId)

            if(index === -1) return prev
            setDataLoading(true)

            newCart.splice(index, 1)
            
            setInstanceInput(
                category, 
                [
                    ...newCart.filter((_product) => _product.category === category && _product.instanceId === instanceId)
                    .reduce((acc: TCartItem[], _product) => {
                        for(let i = 0; i < _product.quantity; i++) {
                            acc.push(_product)
                        }
                        return acc
                    }, [])
                    .map(_product=> `${_product.sku}`)
                ],
                instanceId, 
                false, 
                false, 
                false
            )
            .then((res: any) => {
                setRuntimeSpec(Utils.formatRuntimeSpec(res[RUNTIME_SPEC])) 
                if(res[CART_INFO]?.length>0) setCartInfo(res[CART_INFO] ?? [])
            })
            .catch((err) => {
                setCart(_prevCart)
                toast.error(err.message)
            })
            .finally(() => setDataLoading(false))

            return newCart
        })

        
        // .finally(() => setDataLoading(false))
    }

    const getCartTotalPrice = () => {
        let total = 0;
        cart.forEach(product => {
            total += (product[RESULT_PRDT_PRICE] as number) * (product[RESULT_PRDT_QTY] ?? 1);
        });
        return total;
    }
    /**
     * Todo: graphql call only if product is not in current data
     * 
     * @param cartProductIds 
     * @param products 
     */
    const generateCartFromProductData = async (
        cartProductIds: {categoryId: string, instanceId: string, categoryName: string, productIds: (string)[]}[],
        products: TResultProduct[]
    ) => {
        const productIdMap = Object.fromEntries(products.map((product) => ([product[RESULT_PRDT_SKU], product])))
        const productIds: (string)[] = Object.keys(productIdMap)
        let clientProducts: {[x: string]: any} = {}
        if(products.length > 0){
            if(Utils.isMagento()) {
                try{
                    // if there is a client integration get Availability from 
                    // and filter Compatio products
                    clientProducts = await 
                        MagentoHelper.getProductsBySkus(productIds)
                }
                catch(error){
                    toast.error("Could not fetch products, please refresh and try again")
                }
            }
            else if(Utils.isShopify()) {
                try{
                    clientProducts = await 
                        ShopifyHelper.getCartProducts(products)
                }
                catch(error){
                    toast.error("Could not fetch products, please refresh and try again")
                }
            }
            else if(Utils.isBigCommerce()) {
                try{
                    clientProducts = await 
                        BigCommerceHelper.getCartProducts(products)
                }
                catch(error){
                    toast.error("Could not fetch products, please refresh and try again")
                }
            }
            else {
                clientProducts = Object.fromEntries(products.map((product) => ([product[RESULT_PRDT_SKU], product])))
            }
        }

        try{
            // If third party pricing engines are used 
            // fetch the prices and replace Compatio prices
            if(
                (window.compatioConfig?.compatibleProducts?.[CMPT_CONF_PRICE_ENGINE] === 'true'
                || window.compatioConfig?.compatibleProducts?.[CMPT_CONF_PRICE_ENGINE] === true)
                && Object.keys(clientProducts).length > 0
            ) {
                const prices = await PriceEngine.fetchProductPricesBySku(productIds)
    
                if(prices !== "404"){
                    if(Object.keys(clientProducts).some((productSku) => (!prices[productSku])))
                        toast.error("Cart product not found in price engine")

                    clientProducts = Object.keys(clientProducts)
                        .reduce((acc: typeof clientProducts, productSku) => {
                            if(!_.toNumber(prices[productSku].price) || 
                                    prices[productSku].show_price === "none" || 
                                    prices[productSku].show_price === "cart") clientProducts[productSku][RESULT_PRDT_IS_QUOTE] = true
                            acc[productSku] = clientProducts[productSku]

                            return acc;
                        }, {})

                    Object.keys(clientProducts).forEach((productSku) => {
                        if(prices[productSku]) clientProducts[productSku][RESULT_PRDT_PRICE] = _.toNumber(prices[productSku].price)
                    })
                }
            }
        }
        catch(err) {
            console.debug("Price engine call failed, Prices may not be proper", err)
        }

        const newCartValue: TCartItem[] = []
        cartProductIds.forEach(data => {
            const ids = new Set(data.productIds)

            ids.forEach((productId) => {
                if(productIds.includes(productId)) newCartValue.push({
                    ...productIdMap[productId], 
                    quantity: data.productIds.filter(_productId => productId === _productId).length, 
                    category: data.categoryId, 
                    instanceId: data.instanceId, 
                    [RESULT_PRDT_CATEGORY_NAME]: data.categoryName ?? "",
                    [RESULT_PRDT_MODEL_NO]: clientProducts[productId]?.modelNumber ?? productIdMap[productId]?.[RESULT_PRDT_MODEL_NO] ?? "",
                    ...(clientProducts[productId] ? clientProducts[productId] : [])
                })
            })
        })
        
        setCart(newCartValue)
        setCartInfo([])
    }

    const validateQuantities = () => {
        const errors = cart?.reduce((acc: string[], product) => {
            const quantity = product[RESULT_PRDT_QTY];
            const minQty = product[RESULT_MIN_PURCHASE_QTY];
            const maxQty = product[RESULT_MAX_PURCHASE_QTY];
            const name = ProductHelper.getProductPrimaryTitle(product);
            
            if (minQty !== null && quantity < minQty) {
                acc.push(`${name}: Minimum quantity required is ${minQty}, but selected quantity is ${quantity}`);
            }
            if (maxQty !== null && quantity > maxQty) {
                acc.push(`${name}: Maximum quantity allowed is ${maxQty}, but selected quantity is ${quantity}`);
            }
            return acc;
        }, []);
    
        if (errors.length > 0) {
            toast.error(errors.join('\n'));
            return false;
        }
        return true;
    };


    const submitEcommerceCart = () => {
        // const params = new URLSearchParams(location.search);

        // if (params.has('smartBuilder')) {
        //     params.delete('smartBuilder');
        // }
        // if (params.has('blank')) {
        //     params.delete('blank');
        // }
        // const newUrl = `${location.pathname}?${params.toString()}`;

        // Update the URL with the new parameters
        // navigate(newUrl, { replace: true });

        let dataFilterdForAnalytic: any = [];
        cart.forEach(cartItem => {
            dataFilterdForAnalytic.push({
                baseFinalProductID: Utils.getClientProductSku(),
                baseParentProductID: Utils.getClientProductSku(),
                baseFinalProductName: window.compatioConfig?.compatibleProducts?.productName && decodeURIComponent(window.compatioConfig.compatibleProducts.productName),
                finalProductID: cartItem[RESULT_PRDT_SKU],
                parentProductID: cartItem[RESULT_PRDT_SKU],
            });
        });

        setLoadingToCart(true)

        if(Utils.isBigCommerce() && window[CMPT_CONF_COMPATIO_ADD_TO_CART]) {
            if (!validateQuantities()) {
                return setLoadingToCart(false);
            }

            const items = cart?.map(product => ({productId: product[RESULT_PRDT_SKU], quantity: product[RESULT_PRDT_QTY]}))

            const analyticsData = {
                baseFinalProductID: Utils.getClientProductSku() ?? null,
                baseParentProductID: Utils.getClientProductSku() ?? null,
                baseFinalProductName: window.compatioConfig?.compatibleProducts?.productName && decodeURIComponent(window.compatioConfig.compatibleProducts.productName),
                finalProductID: Utils.getClientProductSku() ?? null,
                parentProductID: Utils.getClientProductSku() ?? null,
                applicationID : "Configure",
                modelVersion : 'V1',
                modelShortName: modelSpec?.project.ISRName ?? null,
                modelID: modelId,
                builderID: modelId,
                merchantKey: Utils.getMerchantKey(),
                platform: "bigcommerce",
                recommendationSource: "Configure",
                recommenderInstance: null
            }

            window[CMPT_CONF_COMPATIO_ADD_TO_CART](items, analyticsData).then(function (res) {
                const closeButton = document.querySelector('#addoutfittocartclear') as HTMLButtonElement
                if(res.success && closeButton){
                    closeButton?.click()
                }
                else {
                    console.debug(res.success, closeButton)
                    throw new Error ("Product could not be added to cart")
                }
            })
            .catch(function (err) {
                console.debug(err)
                toast.error(err.message ?? "Failed to add product to cart")
                setLoadingToCart(false)
            })
            
        }
        // window.location.replace(newUrl);
    }

    return {
        cart,
        setCart,
        addProductToCart,
        removeProductFromCart,
        updateProductQty,
        getCartTotalPrice,
        generateCartFromProductData,
        submitEcommerceCart
    }
}

export default useCart