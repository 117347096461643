import {useState} from "react";
import BorderedButton from "components/Button/BorderedButton";
import useClickOutside from "hooks/useClickOutside";

type MyProps = {
    name?: string
    disabled?: boolean
    onClick: (value: string) => void
    items?: { title: string, value: string }[]|null,
    isDisabled: (id: string) => boolean
}
const StepDropDown = (props: MyProps) => {
    const { showMenu, ref, handleClick } = useClickOutside();

    return (
        <div ref={ref} className="sb3-w-min">
            <BorderedButton
                onClick={handleClick}
                aria-label="Add Load"
            >
                {props.name}
            </BorderedButton>
            {
            !showMenu ? <div/> : <div className="sb3-absolute sb3-top-100 sb3-z-10">
                {
                    props.items?.map((e, key) => (<div
                        key={key}
                        className="sb3-min-w-[252px] sb3-border sb3-border-solid sb3-border-[color:var(--shades-of-grey-20,#DADBDD)] sb3-bg-white sb3-flex sb3-items-center sb3-justify-between sb3-gap-5 sb3-px-3 sb3-py-3 sb3-border-solid">
                        <div
                            className="sb3-overflow-hidden sb3-text-black sb3-text-ellipsis sb3-text-sm sb3-leading-5 sb3-tracking-tight sb3-grow sb3-whitespace-nowrap sb3-my-auto">
                            {e.title}
                        </div>
                        <BorderedButton
                            disabled={props.isDisabled(e.value)}
                            onClick={() => props.onClick(e.value)}
                            className="sb3-text-primary sb3-border-primary sb3-text-xs sb3-min-w-min sb3-px-4 sb3-min-h-min hover:!sb3-bg-primary hover:sb3-text-white"
                            aria-label={e.title}
                        >
                            Add
                        </BorderedButton>
                    </div>
                    ))
                }
            </div>
            }

        </div>
    );
};

export default StepDropDown