import { TTableHeader } from "types/components/Table"
import { ThandleRemove } from "types/components/TableHeaderControlModal"
import { DraggableProvided, DraggableRubric, DraggableStateSnapshot } from "react-beautiful-dnd"
import DragAndDropItemWrapper from "components/DragAndDrop/DragAndDropItemWrapper"
import { FaTimes, } from 'react-icons/fa';
import { TbGripVertical } from "react-icons/tb";

/**
 * Header contol modal dragable item
 * 
 * @param items - all the items to render
 * @param handleRemove - remove button handler
 * @returns 
 */
const DragAndDropHeaderItem = (items: TTableHeader[], handleRemove: ThandleRemove) => (className?: string) => (provided: DraggableProvided, snapshot: DraggableStateSnapshot, rubric: DraggableRubric) => (
    <DragAndDropItemWrapper provided={provided}>
         <div className={`!sb3-cursor-move sb3-flex sb3-items-center sb3-justify-between sb3-bg-gray sb3-p-1.5 sb3-px-2.5 sb3-rounded sb3-w-[inherit] ${className}`}>
            <div className={`sb3-flex sb3-items-center sb3-space-x-3`}>
                <span>
                    <TbGripVertical/>
                </span>
                <span>
                    <p className={`sb3-leading-5 sb3-pointer-events-none sb3-max-w-[220px] sb3-truncate`}>{items[rubric.source.index].name}</p>
                </span>
            </div>
            <div className={`sb3-p-1 hover:sb3-bg-[#E4E4E4] sb3-rounded-sm sb3-group sb3-w-5 sb3-h-5 sb3-flex sb3-items-center sb3-justify-center sb3-cursor-pointer`}>
                <button onClick={(e) => handleRemove(e, rubric.source.index)} type="button" className={`sb3-hidden group-hover:sb3-block  focus:!sb3-outline-none`}>
                    <span className={`sb3-border sb3-border-solid !sb3-border-dark sb3-rounded-sm sb3-flex sb3-items-center sb3-justify-center sb3-text-dark sb3-h-[13.5px] sb3-w-[13.5px]`}>
                        <FaTimes/>
                    </span>
                </button>
            </div>
        </div>
     </DragAndDropItemWrapper>
 )

 export default DragAndDropHeaderItem