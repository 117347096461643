import CompatioAnalytics from "helpers/CompatioAnalyticsSb3";
import { Fragment, useEffect, useLayoutEffect, useRef, useState } from "react";
import _, {get as _get} from 'lodash'
import {
    ANCHOR_TYPES,
    CMPT_CONF_BUILDER_TYPE,
    CMPT_CONF_CATEGORY_NAMES,
    CMPT_CONF_DEFAULT,
    CMPT_CONF_DETAILS,
    CMPT_CONF_HASH_MODEL_NAME,
    CMPT_CONF_MODEL_ID,
    CMPT_CONF_PRICE_ENGINE,
    PLACEHOLDER_IMAGE
} from "constants/constants";
import { checkVariantHasBuilders } from "api";
import AppContextWrapper from "contexts";
import ThemeSelector from "pages";
import Utils from "Services/Utils";
import { TDCCONFIG } from "types";
import PriceEngine from "api/PriceEngineApi";

type MyProps = {
  type?: string
}

const Anchor = (props: MyProps) => {
  const [isDcShow, setIsDcShow] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [modelId, setModelId] = useState<string>()
  const [models, setModels] = useState<any>([])
  const [builderResponse, setBuilderResponse] = useState<{project_id: string, project_name: string}[]>([])
  const originalScroll = useRef<string|null>(null)


  const DcConfigs = _get(window, 'compatioConfig.compatibleProducts.dcConfig');
  const activeCategoryName = _get(window, 'compatioConfig.bigcommerce.activeCategoryName');
  const [dcConfigDetails, setDCConfigDetails] = useState((DcConfigs?.[0] as any)?.details);

  // const IsProductSpecificDC = _get(
  //   window,
  //   'compatioConfig.compatibleProducts.isProductSpecificDC'
  // ) as boolean|undefined ?? false

  // const propsNew: string[] = [];

  const trackAnchorClick = (modelID: string | undefined) => {
    CompatioAnalytics.track(
      CompatioAnalytics.events.arsRecShow,
      {
        baseFinalProductID: Utils.getClientProductSku(),
        baseParentProductID: Utils.getClientProductSku(),
        baseFinalProductName: window.compatioConfig?.compatibleProducts?.productName,
        clickAction: CompatioAnalytics.clicks.dcGotoWidget,
      },
      undefined,
      'V1',
        builderResponse.find((each) => each.project_id === modelID)?.project_name ?? '',
      modelID
    );
  };

  // const handleButtonClick = (e: any) => {
  //   const _modelId = e.target.getAttribute("modelId");
  //   if (_modelId) {
  //     window.location.hash = `smartbuilder3&blank=true&modelId=${_modelId}`;
  //   }
  // };

  useEffect(() => {
    if (props.type === ANCHOR_TYPES.HIDDEN) {
      const clickHandler = (event: Event) => {
        const target = event.target as Element;
        const builderButton = target.closest('.compatio-builder-button');
        
        if (builderButton) {
          // Stop the event immediately
          event.preventDefault();
          event.stopImmediatePropagation();
          
          // Get the model ID for handling
          const modelId = builderButton.getAttribute('modelid');
          
          if(modelId){
            trackAnchorClick(modelId)
      
            setModelId(modelId)
            setIsOpen(true)
          }
          
          return false; // Ensure no other handlers run
        }
      };
  
      // Attach to both document and the specific container
      document.addEventListener('click', clickHandler, true);
    }
  }, [])

  const handleIsOpenChange = (value: boolean) => {
    // Get the current URL and extract the hash part
    const url = window.location.href;
    const urlParts = url.split('#');
    
    if (urlParts.length > 1) {
      // We have a hash part
      const baseUrl = urlParts[0];
      const hashPart = urlParts[1];
      
      // Split hash by & to get individual parameters
      const hashParams = hashPart.split('&');
      
      // Filter out 'configure' and any parameter starting with 'model='
      const filteredParams = hashParams.filter(param => 
        param !== 'configure' && !param.startsWith('model=')
      );
      
      // Reconstruct the URL
      let newUrl = baseUrl;
      if (filteredParams.length > 0) {
        newUrl += '#' + filteredParams.join('&');
      }
      
      // Update the URL without reloading
      window.history.replaceState({}, document.title, newUrl);
    }

    setIsOpen(false)
  }

  const checkPriceEngineStatus = async (sku: string) => {
    if(
      window.compatioConfig?.compatibleProducts?.[CMPT_CONF_PRICE_ENGINE] !== 'true'
      && window.compatioConfig?.compatibleProducts?.[CMPT_CONF_PRICE_ENGINE] !== true
    ) return true

    try{
      const prices = await PriceEngine.fetchProductPricesBySku([sku])

      if(
        prices  === "404" 
        || !_.toNumber(prices[sku].price)  
        || prices[sku].show_price === "none" 
        || prices[sku].show_price === "cart"
      ) {
        return false
      }

      return true
    }
    catch(err: any){
      console.debug("Price Engine call failed", err.message)
      return false
    }
  }

  useEffect(() => {
    if(window.compatioConfig?.compatibleProducts?.WidgetId?.[0] === 2) return

    const checkBuilderStatus = async () => {
      let productSku = Utils.getClientProductSku()
      let productPrice = Utils.getClientProductPrice()
      if(
        !productSku 
        || !Utils.getMerchantKey() 
        || props.type === ANCHOR_TYPES.HIDDEN
        || !productPrice
      ) return

      const priceCheck = await checkPriceEngineStatus(productSku)

      if(!priceCheck) return

      checkVariantHasBuilders(productSku, Utils.getMerchantKey() as string)
      .then((res) => {
        setBuilderResponse(res);
        const modelTypeDefined = DcConfigs?.find((each) => each[CMPT_CONF_BUILDER_TYPE] === "sb3")
        const sb3Builders = modelTypeDefined ? DcConfigs?.filter(each => each[CMPT_CONF_BUILDER_TYPE] === "sb3") : DcConfigs
        setIsDcShow(true)
        setModels(sb3Builders?.filter((_model) => res?.some((each) => each.project_id === _model.modelId)))
      })
      .catch(() => setIsDcShow(false))
    }

    checkBuilderStatus()
  }, [])


  useEffect(() => {
    if (props.type !== ANCHOR_TYPES.HIDDEN) return
    const handleModelId = () => {
      const hash = window.location.hash;

      // Check if hash contains 'configure'
      if (hash.includes('configure')) {
        const paramsString = hash.substring(1).split('&').join('&');
        const params = new URLSearchParams(paramsString);
        
        // Get model value
        const modelShortName = params.get('model');
        const modelId = DcConfigs?.find((each) => each[CMPT_CONF_BUILDER_TYPE] === "sb3" && modelShortName === each[CMPT_CONF_DETAILS][CMPT_CONF_HASH_MODEL_NAME])?.[CMPT_CONF_MODEL_ID]

        if(modelId) {
          trackAnchorClick(modelId)

          setIsOpen(true);
          setModelId(modelId)
        }
      }
    };
  
    // Initial check
    handleModelId();
  
    // Optional: Listen for hash changes
    window.addEventListener('hashchange', handleModelId);
    
    // Cleanup
    return () => {
      window.removeEventListener('hashchange', handleModelId);
    };
  }, []); // Empty dependency array as we're setting up the listener only once


  useLayoutEffect(() => {
    const body = document.querySelector('body');
    if (body) {
      if(originalScroll.current === null) originalScroll.current = body.style.overflow

      if (isOpen) {
          body.style.overflow = "hidden";
      } else {
          body.style.overflow = originalScroll.current ?? "auto";
      }
    }
}, [isOpen]);

  useEffect(() => {
    setDCConfigDetails(DcConfigs?.find(each => each.modelId === modelId)?.details)
  }, [modelId])

  // useEffect(() => {
  //   if(props.type === ANCHOR_TYPES.BANNER){
  //     const defaultBuilders = DcConfigs?.filter(each => {
  //         return (!!activeCategoryName ? (each[CMPT_CONF_CATEGORY_NAMES] ?? []).includes(activeCategoryName) : each[CMPT_CONF_DEFAULT] === "1");
  //       })
  //       defaultBuilders?.forEach((currentAnchorDetails, key) => {
  //         if(!currentAnchorDetails.details) return
          
  //         CompatioAnalytics.track(
  //           CompatioAnalytics.events.arsRecShow,
  //           {},
  //           'SmartBuilder3',
  //           '',
  //           'V1',
  //           "",
  //           currentAnchorDetails.modelId
  //         );
  //     })
  //   }
  // }, [])

  // useEffect(() => {
  //   if(props.type === undefined && models.length > 0 && isDcShow == true){
  //     models?.forEach((currentAnchorDetails: TDCCONFIG, key: number) => {
  //       if(!currentAnchorDetails.details) return
          
  //         CompatioAnalytics.track(
  //           CompatioAnalytics.events.arsRecShow,
  //           {
  //             baseFinalProductID: Utils.getClientProductSku(),
  //             baseParentProductID: Utils.getClientProductSku(),
  //             baseFinalProductName: window.compatioConfig?.compatibleProducts?.productName && decodeURIComponent(window.compatioConfig.compatibleProducts.productName),
  //           },
  //           'SmartBuilder3',
  //           '',
  //           'V1',
  //           "",
  //           currentAnchorDetails.modelId
  //         );
  //     })
  //   }
  // }, [models])

  if(props.type === ANCHOR_TYPES.HIDDEN) {
    return (
      <AppContextWrapper modelId={modelId} isOpen={isOpen} setIsOpen={handleIsOpenChange} blank={true} setVariants={false}>
        <div className="sb3-contain">
          <ThemeSelector/>
        </div>
      </AppContextWrapper>
  )
  }

  if(props.type === ANCHOR_TYPES.BANNER && !Utils.isBigCommerce()){ 
    // const currentAnchorDetails = DcConfigs?.find(each => each.modelId === window.compatioConfig?.magento?.modelId)?.details
    const defaultBuilders = DcConfigs?.filter(each => each[CMPT_CONF_DEFAULT] === "1")
    return (
      <AppContextWrapper modelId={modelId} isOpen={isOpen} setIsOpen={handleIsOpenChange} blank={true}>
        <div className="sb3-contain">
          <div className="sb3-w-full sb3-space-y-2">
            {
              defaultBuilders?.map((currentAnchorDetails, key) => {
                if(!currentAnchorDetails.details) return <Fragment key={key}/>


                return (
                  <div
                    className={`compatio-anchor-sb3 bsb3-order-l sm:sb3-flex sb3-w-full sb3-border sb3-border-solid sb3-shadow`}
                    key={key}
                  >
                    <div className="sb3-flex sb3-justify-center sm:sb3-w-5/12">
                      <img
                        src={currentAnchorDetails.details?.imageUrl ?? PLACEHOLDER_IMAGE}
                        className="sb3-w-full"
                        alt="Build-Fly-Fishing"
                      />
                    </div>
                    
                    <div className="sb3-m-auto sb3-h-full sb3-p-5 sm:sb3-w-7/12 sb3-w-full">
                      <div className="contentset-dc-anchor sb3-space-y-2">
                        <div className="buildouttext-left">
                          <span className="dc-anchor-text sb3-space-y-2">
                            <p className="!sb3-text-lg">
                              {currentAnchorDetails.details?.description
                                ? currentAnchorDetails.details.description
                                : 'Browse compatible categories for your products and other accessories'}
                            </p>
                          </span>
                        </div>

                        <div className="sb3-text-left">
                          <button 
                          onClick={() => {
                            trackAnchorClick(currentAnchorDetails.modelId)
                          
                            setIsOpen(true);
                            setModelId(currentAnchorDetails.modelId)
                          }}
                          type="button"
                          className="btn btn--full add-to-cart action primary sb3-border sb3-border-solid sb3-rounded sb3-px-2 sb3-py-1.5 !sb3-text-lg sb3-min-w-max  focus:!sb3-outline-none">
                            {currentAnchorDetails.details?.title
                              ? currentAnchorDetails.details.title
                              : 'Build a Outfit'}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })
            }
          </div>
        
      
        <div className="sb3-contain">
          <ThemeSelector/>
        </div>
      </div>
    </AppContextWrapper>
  )
}

if(props.type === ANCHOR_TYPES.BANNER && Utils.isBigCommerce()){ 
  const defaultBuilders = DcConfigs?.filter(each => {
      return (!!activeCategoryName ? (each[CMPT_CONF_CATEGORY_NAMES] ?? []).includes(activeCategoryName) : each[CMPT_CONF_DEFAULT] === "1");
  })

  return (
    <AppContextWrapper modelId={modelId} isOpen={isOpen} setIsOpen={handleIsOpenChange} blank={true}>
       <div className="sb3-contain">
      <div className={`sb3-w-full sb3-contain ${!!activeCategoryName && defaultBuilders && defaultBuilders?.length > 1 ? "sb3-grid sb3-gap-4 sb3-grid-cols-1 md:sb3-grid-cols-2" : "md:sb3-space-y-3 sb3-space-y-2"}`}>
        {
          defaultBuilders?.map((currentAnchorDetails, key) => {
            if(!currentAnchorDetails.details) return <Fragment key={key}/>


            const title = currentAnchorDetails.details.title ?? '';
            const description = currentAnchorDetails.details.description ?? '';
            const buttonText = currentAnchorDetails.details.buttonText;
            return (
              <div
                className={`compatio-anchor-sb3 sb3-order-l sb3-items-center ${!!activeCategoryName ? "sb3-p-5 sb3-border-2 sb3-border-[#B1F064]" : "md:sb3-pr-6 sb3-p-3 md:sb3-p-0"} md:sb3-flex sb3-space-y-2 md:sb3-space-y-0 sb3-gap-4 sb3-w-full  sb3-bg-white sb3-rounded-3xl`}
                key={key}
              >
                {
                  !activeCategoryName &&
                  <div className=' sb3-flex-shrink-0'>
                      <img className="sb3-w-full md:sb3-w-[320px] sb3-aspect-[2.29/1] sb3-object-cover sb3-rounded-3xl" src={currentAnchorDetails.details?.imageUrl ?? PLACEHOLDER_IMAGE}  alt={'image'}/>
                  </div>
                }
                <div className="sb3-flex-1">
                    <div className={`sb3-text-[#052525] ${activeCategoryName ? "sb3-text-lg" : "sb3-text-xl"} sb3-font-bold`}>{title}</div>
                    <div className={`sb3-text-[#324f4f]  ${activeCategoryName ? "sb3-text-sm" : "sb3-text-lg"}  sb3-font-normal sb3-mt-[5px]`}>{description}</div>
                </div>
                  {buttonText ?
                      <button
                      style={{border: !activeCategoryName && "2px solid #fb8c53"}}
                      onClick={() => {
                        trackAnchorClick(currentAnchorDetails.modelId)

                        setIsOpen(true);
                        setModelId(currentAnchorDetails.modelId)
                      }}
                      type="button"
                      className=" button button--primary  focus:!sb3-outline-none sb3-w-full md:sb3-w-max sb3-px-[45px] sb3-py-[18px] sb3-rounded-lg sb3-border-2 sb3-border-[#fb8c53] sb3-justify-center sb3-items-center sb3-text-[#052525] sb3-text-sm sb3-font-bold">
                        {buttonText}
                      </button> : null
                  }
              </div>
            )
          })
        }
      </div>
      </div>
    
  
    <div className="sb3-contain">
      <ThemeSelector/>
    </div>
  </AppContextWrapper>
)
}

if(!isDcShow) return <></>

if(props.type === ANCHOR_TYPES.BUTTON) return (
      <AppContextWrapper modelId={modelId} isOpen={isOpen} setIsOpen={handleIsOpenChange}>
        <div className={`sb3-w-full sb3-space-y-2 sb3-contain`}>
        {
            models?.map((currentAnchorDetails: TDCCONFIG, key: number) => {
              if(!currentAnchorDetails.details) return <Fragment key={key}/>
              const title = currentAnchorDetails.details.title ?? '';
              return (
                <button
                  type="button"
                  onClick={() => {
                    trackAnchorClick(currentAnchorDetails.modelId)

                    setIsOpen(true);
                    setModelId(currentAnchorDetails.modelId)
                  }}
                  className={`action sb3-contain secondary btn btn--full ${!Utils.isShopify() ? "sb3-bg-gray-100 sb3-border-gray-300 sb3-border sb3-rounded sb3-text-xl sb3-px-2 sb3-py-1.5 " : "sb3-bg-black"} sb3-font-semibold !sb3-w-full sb3-mb-[15px] sb3-min-w-max tocart`}
                  >
                    {title
                      ?? 'Create Your Solution'}
                  </button>
              )
        })}
        
        </div>

      <div className="sb3-contain">
        <ThemeSelector/>
      </div>
    </AppContextWrapper>
  )

  if(Utils.isBigCommerce()) return (
      <AppContextWrapper modelId={modelId} isOpen={isOpen} setIsOpen={handleIsOpenChange} blank={true}>
         <div className="sb3-contain">
        <div className={`sb3-w-full sb3-space-y-2 sb3-contain`}>
          {
            models?.map((currentAnchorDetails: TDCCONFIG, key: number) => {
              if(!currentAnchorDetails.details) return <Fragment key={key}/>

              const title = currentAnchorDetails.details.title ?? '';
              const description = currentAnchorDetails.details.description ?? '';
              const buttonText = currentAnchorDetails.details.buttonText;
              return (
                <div
                  className={`compatio-anchor-sb3 sb3-order-l sb3-p-6 sb3-space-y-4 sb3-w-full  sb3-bg-white sb3-rounded-3xl`}
                  key={key}
                >
                  <div className="md:sb3-flex md:sb3-space-x-4  sb3-space-y-2 md:sb3-space-y-0">
                    {
                      !activeCategoryName &&
                      <div className=' sb3-flex-shrink-0'>
                          <img className="sb3-w-full md:sb3-w-[155px] sb3-h-[122px] sb3-object-cover sb3-rounded-3xl" src={currentAnchorDetails.details?.imageUrl ?? PLACEHOLDER_IMAGE}  alt={'image'}/>
                      </div>
                    }
                    <div className="sb3-flex-1">
                        <div className="sb3-text-[#052525] sb3-text-lg sb3-font-bold">{title}</div>
                        <div className="sb3-text-[#324f4f] sb3-text-sm sb3-font-normal sb3-mt-[5px]">{description}</div>
                    </div>
                  </div>
                  
                  {buttonText ?
                      <button
                      style={{border: "2px solid #fb8c53"}}
                      onClick={() => {
                        trackAnchorClick(currentAnchorDetails.modelId)

                        setIsOpen(true);
                        setModelId(currentAnchorDetails.modelId)
                      }}
                      type="button"
                      className=" button button--primary  focus:!sb3-outline-none sb3-px-[45px] sb3-min-w-full sb3-rounded-lg sb3-border-2 sb3-border-[#fb8c53] sb3-justify-center sb3-items-center sb3-text-[#052525] sb3-text-base sb3-font-bold">
                        {buttonText}
                      </button> : null
                  }
                </div>
              )
            })
          }
        </div>
        </div>
      
    
      <div className="sb3-contain">
        <ThemeSelector/>
      </div>
    </AppContextWrapper>
  )


  return (
      <AppContextWrapper modelId={modelId} isOpen={isOpen} setIsOpen={handleIsOpenChange}  blank={true}>
         <div className="sb3-contain">
      <div className={`sb3-w-full sb3-space-y-2 sb3-contain sb3-product-anchor-container`}>
        {
          models?.map((currentAnchorDetails: TDCCONFIG, key: number) => {
          if(!currentAnchorDetails.details) return <Fragment key={key}/>

          const title = currentAnchorDetails.details.title ?? '';
          const description = currentAnchorDetails.details.description ?? '';
          const buttonText = currentAnchorDetails.details.buttonText;

          return <div
            className={`sb3-product-anchor compatio-anchor-sb3 sb3-pl-5 sb3-border-l sb3-max-w-[234px]`}
            onClick={() => {
              trackAnchorClick(currentAnchorDetails.modelId)
            
              setModelId(currentAnchorDetails.modelId)
              setIsOpen(true);
            }}
          >
          <div className="sb3-product-anchor-main sb3-space-y-2">
              <img
                src={currentAnchorDetails?.details.imageUrl ?? PLACEHOLDER_IMAGE}
                className="sb3-product-anchor-img sb3-min-h-36 sb3-object-contain"
                alt="Build-Fly-Fishing"
              />

              <div className="sb3-product-anchor-text-container sb3-space-y-2">
                  <p className="sb3-product-anchor-title sb3-text-[32px] sb3-font-bold sb3-text-[#7faadb]">
                    {title
                      ?? 'Build It Out'}
                  </p>
                  <p className="sb3-product-anchor-desc sb3-text-sm">
                    {description
                      ?? 'Browse compatible categories for your products and other accessories'}
                  </p>
              </div>
            </div>

              <button 
              type="button"
              className="sb3-product-anchor-button action primary anchor btn btn--full sb3-border sb3-border-solid sb3-rounded sb3-px-2 sb3-py-1.5 sb3-text-xl !sb3-w-full  focus:!sb3-outline-none">
                {buttonText
                  ?? 'BUILD AN OUTFIT'}
              </button>

          </div>
          
      })
    }
  </div></div>
      
      <div className="sb3-contain">
        <ThemeSelector/>
      </div>
      
    </AppContextWrapper>
  )
}

export default Anchor