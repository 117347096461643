import {
    MODEL_CONNECTED_IDS,
} from "constants/constants"
import { useAppContext } from "contexts/AppContext"
import { TConnector, TModelData, TRuntimeInstance } from "types"
import StepDropDown from "components/Dropdown/StepDropDown"
import ConnectorItem from "./ConnectorItem"
import useConnector from "hooks/useConnectors"

type MyProps = {
    connector: TConnector,
    instance?: TRuntimeInstance | TModelData
}

/**
 * To render connector 
 * 
 * @param {TConnector} connector - field data
 * @returns 
 * 
 * @todo 
 * - add ui definition
 */
const Connector = ({connector, instance}: MyProps) => {
    const {
        connectedInstances,
        dataLoading
    } = useAppContext()

    const {
        instanceIdMap,
        valueInstances,
        handleAddConnection,
        handleDropConnection
    } = useConnector({connector, instance})

    return (
        <div className="sb3-space-y-2">
            {
                (instance as TRuntimeInstance)?.[MODEL_CONNECTED_IDS]?.map((instanceId, key) => (
                    <ConnectorItem key = {key} title={instanceIdMap?.[instanceId] ?? ""}  quantity ={0} onQuantityChange={() => {}} handleDropConnection={() => handleDropConnection(instanceId)}/>
                ))
            }

            <StepDropDown
                name="Add"
                disabled= {dataLoading}
                onClick= {handleAddConnection}
                items={valueInstances}
                isDisabled={(id) => !!connectedInstances?.includes(id)}
            />
        </div>
    )
    
}

export default Connector