import { fetchResultsApi } from "api"
import Header from "components/Header"
import Table from "components/Table"
import { BUILDER_CONFIG, BUILDER_PAGES, CATEGORIES_WITHOUT_IMAGE, CATEGORY_DISPLAY_TYPES, CATEGORY_ID, CATEGORY_NAME, CMPT_CONF_PRDT_MODEL_NUMBER, CMPT_CONF_PRICE_ENGINE, CONFIG_CUSTOMIZATION, CUSTOMIZATION_THEME_SETTINGS, CUSTOMIZATION_VIEW_PREF, FIELD_BUTTON_LABEL, FIELD_ID, FIELD_TYPES, FRMDATA_CATEGORY_LABEL, FRMDATA_CONFIG, FRMDATA_DISPLAY_NAME, FRMDATA_ID, FRMDATA_ITEMS, FRMDATA_SMID, FRMDATA_TYPE, MODEL_DATA, MODEL_DYNAMIC_FIELDS, MODEL_SUBMODEL_ID, PAGE_FORM_DATA, PAGE_LAYOUT, PAGE_LAYOUTS, PRDT_DISPLAY_TYPES, RESULT_IS_AVAILABLE, RESULT_MANUFACTURER, RESULT_MAX_PURCHASE_QTY, RESULT_MIN_PURCHASE_QTY, RESULT_PARENT_ID, RESULT_PRDT_CATEGORY, RESULT_PRDT_CATEGORY_ID, RESULT_PRDT_DESC, RESULT_PRDT_INSTANCEID, RESULT_PRDT_IS_QUOTE, RESULT_PRDT_MODEL_NO, RESULT_PRDT_NAME, RESULT_PRDT_PRICE, RESULT_PRDT_QTY, RESULT_PRDT_SKU, RESULT_PRDT_STOCK_STATUS, RESULT_PRDT_URL, RESULT_PRDT_URL_IMAGE, RUNTIME_ELEMENT_TYPES, RUNTIME_INSTANCE_ELEMENT_TYPE, RUNTIME_INSTANCE_INSTANCE_ID, RUNTIME_INSTANCE_MAX_COUNT, RUNTIME_INSTANCE_MAX_QUANTITY, RUNTIME_INSTANCE_MIN_QUANTITY, SORT, STOCK_STATUSES, THEME_PLATFORMS, THEME_SECONDARY, THEME_TITLE } from "constants/constants"
import { useCallback, useEffect, useRef, useState } from "react"
import { TModel, TProductCategoryField, TResultProduct, TRuntimeInstance, TUI_BuilderSubmodel } from "types"
import { TTableHeader } from "types/components/Table"
import Utils from "Services/Utils"
// import { DUMMY_TABLE_DATA } from "constants/dummy_data"
import NProgress from "nprogress"
import { useAppContext } from "contexts/AppContext"
import Product from "components/Product"
import ProductSkeleton from "components/Product/ProductLoading"
import MagentoHelper from "helpers/Clients/Magento"
import { TCartItem } from "types/Cart"
import usePaginate from "hooks/usePaginate"
import PriceEngine from "api/PriceEngineApi"
// import InfiniteScroll from 'react-infinite-scroller';
import InfiniteScroll from 'react-infinite-scroll-component'
import { HiOutlineShoppingBag } from 'react-icons/hi'
import CategoryHeader from "./CategoryHeader"
// import { AnimatePresence, m } from "framer-motion"
import _, { isArray } from "lodash"
import AuthManager from "Services/AuthManager"
import { FaSpinner } from "react-icons/fa"
import SkeletonElement from "components/Table/components/SkeletonElement"
import Title from "components/Header/Title"
import SB2Sort from "components/Input/SB2Sort"
import ShopifyHelper from "helpers/Clients/Shopify"
import BigCommerceHelper from "helpers/Clients/BigCommerce"
import { toast } from "react-toastify"
import AvailabilityInput from "components/Input/AvailabilityInput"
import { TisAvailabilityCheck, TSort } from "types/contexts/AppContext"

type MyProps = {
    categories: TProductCategoryField[]|undefined
    submodel: TModel
    // selectedProducts?: TCartItem[]
    instance: TRuntimeInstance
    uiModel: TUI_BuilderSubmodel
    load?: boolean
}
export interface TFilters {
    search?: string,
    sort_by?: string,
    sort_type?: string,
    product_type?: string,
    page?: number,
    limit?: number|string,
    filters?: string[],
    [x: string]: any
}

const MAX_PRODUCTS_PER_PAGE = 50

const Categories = ({
    categories, 
    submodel,
    // selectedProducts,
    instance,
    uiModel,
    load,
}: MyProps) => {
    const {
        // modelSpec,
        // activePage,
        theme,
        // setShowConfigurations,,
        activePage,
        // sort,
        setFilterOpen,
        categoryDisplayType,
        builderSpec,
        // availabilityFlag,
        runtimeSpec,
        dataLoading,
        platform,
        categoryProductsCount,
        setCategoryProductsCount,
        // sort,
        cart
    } = useAppContext()

    const [headers, setHeaders] = useState<TTableHeader[]>([])
    const [loading, setLoading] = useState<boolean>(true)
    const [isLoading, setIsLoading] = useState<boolean>(true)
    // if products are not available during initial product fetch then we need to retain the loading state
    const [initialLoading, setInitialLoading] = useState<boolean>(true) 
    const [tableDataLoading, setTableDataLoading] = useState<boolean>(true)
    const [countLoading, setCountLoading] = useState<boolean>(true)
    
    const [compatioProducts, setCompatioProducts] = useState<TResultProduct[]>([])

    const isConfigurator = Utils.isConfigurator(theme) || Utils.isLinear(theme)

    const categoryIdMap = categories ? Object.fromEntries(categories?.map((category) => ([category[CATEGORY_ID], category]))) : {}
    
    const abortRef = useRef<AbortController>()

    const [availabilityFlag, setAvailabilityFlag] = useState<TisAvailabilityCheck>(false)
    const [categorySort, setCategorySort] = useState<TSort>(SORT.RECOMMENDED)
    
    const [trigger, setTrigger] = useState<number>(0)

    const productCountDiff = useRef<number>(0)
    const categoryCountRef = useRef<number>(0)

    const { 
        rows,
        setPage,
        setLimit,
        page,
        limit
    } = usePaginate<TResultProduct>(
        compatioProducts, 
        1, 
        MAX_PRODUCTS_PER_PAGE,
        "manual"
    )

    // generate table headers for cummins UI
    useEffect(() => {
        if(rows?.length === 0) return

        const headers = Utils.generateHeadersFromArray(Object.keys(rows?.[0]))
        setHeaders(headers)
    }, [rows])

    const setSort = () => {
        const _sort: {
            s_key?: "min_ad_price"|"name",
            s_order?: string,
        } = {}

        if(categorySort === SORT.PRICE_ASC || categorySort === SORT.PRICE_DESC) {
            _sort.s_key = RESULT_PRDT_PRICE
            _sort.s_order = categorySort === SORT.PRICE_ASC ? 'asc' : 'desc'
        }
        else if(categorySort === SORT.ALPHABETICAL_ASC || categorySort === SORT.ALPHABETICAL_DESC) {
            _sort.s_key = RESULT_PRDT_NAME
            _sort.s_order = categorySort ===  SORT.ALPHABETICAL_ASC ? 'asc' : 'desc'
        }
        return _sort
    }

    const getCategoryNamesPendingSelection = () => {
        const selectedProducts = (categories && runtimeSpec && Utils.getSelectedProductsFromCategoryIds(cart, [categories[0][CATEGORY_ID]], runtimeSpec?.[instance[RUNTIME_INSTANCE_INSTANCE_ID]]?.[RUNTIME_INSTANCE_INSTANCE_ID])) ?? undefined

        return (categories as TProductCategoryField[])?.filter((cat) => {
            return (runtimeSpec?.[instance[RUNTIME_INSTANCE_INSTANCE_ID]]?.[MODEL_DYNAMIC_FIELDS][cat[CATEGORY_ID]][RUNTIME_INSTANCE_MAX_COUNT] ?? 1) 
                > Utils.getUniquePrdtCountByCategoryId(selectedProducts, cat[CATEGORY_ID]) &&
                (runtimeSpec?.[instance[RUNTIME_INSTANCE_INSTANCE_ID]]?.[MODEL_DYNAMIC_FIELDS][cat[CATEGORY_ID]][RUNTIME_INSTANCE_MAX_QUANTITY] ?? 1) 
                > Utils.getProductQtyByCategoryId(selectedProducts, cat[CATEGORY_ID])
            })
            .map((cat) => cat[MODEL_DATA][CATEGORY_NAME])
    }

    const getShopifyProducts = async (productSkus: string[], products: TResultProduct[]) => {
        // parent ids for shopify
        let _productSkus: string[]|undefined = undefined

        const currentParentIds = compatioProducts.map((product) => product[RESULT_PARENT_ID])
        
        const variantIds = products
            .filter((product) => isArray(product.c_variants) && product.c_variants.length > 0)
            .reduce((arr: string[], product) => ([...arr, ...(product.c_variants as string[])]), [])

        const parentProductIds = products.map(product => product[RESULT_PARENT_ID])

        const uniqueParentProducts = products
            .filter((product, key) => (key === parentProductIds.indexOf(product[RESULT_PARENT_ID]) 
                && !!product[RESULT_PARENT_ID] && !currentParentIds.includes(product[RESULT_PARENT_ID])))

        _productSkus = uniqueParentProducts.map((product) => product[RESULT_PARENT_ID] as string)

        // if there is a client integration get Availability from 
        // and filter Compatio products
        const clientProducts = await 
            ShopifyHelper.getProductsBySkus(_productSkus, variantIds)

        clientProducts
        .sort((product1, product2) => parentProductIds.indexOf(product1[RESULT_PRDT_SKU]) - parentProductIds.indexOf(product2[RESULT_PRDT_SKU]))
        .forEach((parentProduct: any) => {
            const categoryId = products.find((product) => {
                return product[RESULT_PARENT_ID] === parentProduct[RESULT_PRDT_SKU]})?.[RESULT_PRDT_CATEGORY_ID] ?? ""
            
            parentProduct[RESULT_PRDT_CATEGORY_ID] = categoryId
        })

        return clientProducts
    }

    const fetchData = async (isInfinite: boolean = false, _isReset?: boolean) => {
        if(!AuthManager.getSessionId() || dataLoading) return 

        if(_isReset || !isInfinite) {
            categoryCountRef.current = 0;
        }

        if(abortRef.current) {
            // console.log("category controller is aborted", categories?.[0].data.name)
            abortRef.current?.abort()
        }
        
        const _sort = setSort()
        const controller = new AbortController()
        abortRef.current = controller
        const signal = controller.signal
        let productCount = 0
        let filteredProducts: TResultProduct[] = []
        const _categories = getCategoryNamesPendingSelection()
        let _countLoading = true

        try{
            if((isArray(categories) && categories?.length <= 0) || !instance) {
                setInitialLoading(false)
                setLoading(false)
                setCountLoading(false)
                _countLoading = false
                return new Error("Category missing")
            }
            
            if((isArray(_categories) && _categories?.length <= 0))  {

                setInitialLoading(false)
                setLoading(false)
                setCountLoading(false)
                _countLoading = false
                return
            }
            const res = await fetchResultsApi(
                _categories, 
                (instance as TRuntimeInstance)[RUNTIME_INSTANCE_INSTANCE_ID],
                page,
                MAX_PRODUCTS_PER_PAGE,
                0,
                _sort,
                signal,
                false
            )

            if(controller.signal.aborted) return

            
            // if(res.total_prod_count_available) setAvailableProductsCount(res.total_prod_count_available ?? 0)
            productCount = res.total_prod_count
            
            // if(Utils.isLocal() &&  page === 1) res.products = []

            // if no products found fetch next page products
            if(res?.products.length === 0 
                && isInfinite 
                && productCount > 0 
                && (page * MAX_PRODUCTS_PER_PAGE) < productCount
            ) {
                if(controller.signal.aborted) return
                if(page === 1) {
                    setCompatioProducts([])
                }

                setPage(prev => prev + 1)
                return 
            }

            // const pageCount = Math.ceil(res.total_prod_count/MAX_PRODUCTS_PER_PAGE)
            filteredProducts = res?.products

            // if(!AppSettings.CLIENT_MAGENTO_API_END_POINT) filteredProducts = res.products
            // else filteredProducts = res?.products?.filter((product) => !availabilityFlag || (product[RESULT_PRDT_STOCK_STATUS] === undefined ? product[RESULT_IS_AVAILABLE] : product[RESULT_PRDT_STOCK_STATUS] === "IN_STOCK"))
            
            let productSkus = res?.products.map((product) => product[RESULT_PRDT_SKU])
            
            // Client Availability check
            if(Utils.isShopify() && res?.products.length > 0) {
                // shopify
                try{
                    filteredProducts = await getShopifyProducts(productSkus, res.products)
                    if(controller.signal.aborted) return

                    categoryCountRef.current =  categoryCountRef.current + filteredProducts.length

                    filteredProducts.map(product => product.catgeory_id = res.products[0].catgeory_id)

                     // if no products found fetch next page products
                    if(filteredProducts.length === 0
                        && isInfinite 
                        && productCount > 0 
                        && (page * MAX_PRODUCTS_PER_PAGE) < productCount
                    ) {
                        if(controller.signal.aborted) return
                        if(page === 1) {
                            setCompatioProducts([])
                        }

                        setPage(prev => prev + 1)
                        return 
                    }
                }
                catch(error){
                    // console.debug(error, "Client Inventory check failed, showing all products")
                }
               
                
            }
            else if(Utils.isBigCommerce() && res?.products.length > 0) {
                // magento
                try{
                    // based on client
                    // skus for magento and parent ids for shopify
                    let _productSkus: string[]|undefined = undefined

                    _productSkus = productSkus

                    // if there is a client integration get Availability from 
                    // and filter Compatio products
                    const clientProducts = await 
                        BigCommerceHelper.getProductsBySkus(_productSkus) as any
                    if(controller.signal.aborted) return

                    // console.log(clientProducts)
                    productSkus = Object.keys(clientProducts)
                    categoryCountRef.current =  categoryCountRef.current + productSkus.length

                    filteredProducts = res.products.filter((product) => {
                        if(!productSkus.includes(product[RESULT_PRDT_SKU])) return false
                        
                        product[RESULT_PRDT_STOCK_STATUS] = clientProducts[product[RESULT_PRDT_SKU]][RESULT_PRDT_STOCK_STATUS]
                        product[RESULT_PRDT_URL_IMAGE] = clientProducts[product[RESULT_PRDT_SKU]][RESULT_PRDT_URL_IMAGE]
                        product[RESULT_PRDT_URL] = clientProducts[product[RESULT_PRDT_SKU]][RESULT_PRDT_URL]
                        product[RESULT_PRDT_NAME] = clientProducts[product[RESULT_PRDT_SKU]][RESULT_PRDT_NAME]
                        product[RESULT_PRDT_PRICE] = clientProducts[product[RESULT_PRDT_SKU]][RESULT_PRDT_PRICE]
                        product[RESULT_PRDT_DESC] = clientProducts[product[RESULT_PRDT_SKU]][RESULT_PRDT_DESC]
                        product[RESULT_PRDT_MODEL_NO] = clientProducts[product[RESULT_PRDT_SKU]][RESULT_PRDT_MODEL_NO]
                        product[RESULT_MIN_PURCHASE_QTY] = clientProducts[product[RESULT_PRDT_SKU]][RESULT_MIN_PURCHASE_QTY]
                        product[RESULT_MAX_PURCHASE_QTY] = clientProducts[product[RESULT_PRDT_SKU]][RESULT_MAX_PURCHASE_QTY]
                        if(clientProducts[product[RESULT_PRDT_SKU]][RESULT_MANUFACTURER]) product[RESULT_MANUFACTURER] = clientProducts[product[RESULT_PRDT_SKU]][RESULT_MANUFACTURER]
                        if(availabilityFlag && clientProducts[product[RESULT_PRDT_SKU]][RESULT_PRDT_STOCK_STATUS] !== "IN_STOCK") return false
                        return true
                    })
                }
                catch(error){
                    // setCompatioProducts(res.products ?? [])
                    toast.error("Store data couldn't be loaded. Please refresh your page.")
                }
                if(controller.signal.aborted) return


                // if no products found fetch next page products
                if(filteredProducts.length === 0
                    && isInfinite 
                    && productCount > 0 
                    && (page * MAX_PRODUCTS_PER_PAGE) < productCount
                ) {
                    if(page === 1) {
                        setCompatioProducts([])
                    }

                    setPage(prev => prev + 1)
                    return 
                }
            }
            else if(Utils.isMagento() && res?.products.length > 0) {
                // magento
                try{
                    // based on client
                    // skus for magento and parent ids for shopify
                    let _productSkus: string[]|undefined = undefined

                    _productSkus = productSkus

                    // if there is a client integration get Availability from 
                    // and filter Compatio products
                    const clientProducts = await 
                        MagentoHelper.getProductsBySkus(_productSkus)
                    if(controller.signal.aborted) return

                    // console.log(clientProducts)
                    productSkus = Object.keys(clientProducts)
                    categoryCountRef.current = categoryCountRef.current + productSkus.length

                    filteredProducts = res.products.filter((product) => {
                        if(!productSkus.includes(product[RESULT_PRDT_SKU])) return false
                        
                        product[RESULT_PRDT_STOCK_STATUS] = clientProducts[product[RESULT_PRDT_SKU]][RESULT_PRDT_STOCK_STATUS]
                        product[RESULT_PRDT_URL_IMAGE] = clientProducts[product[RESULT_PRDT_SKU]][RESULT_PRDT_URL_IMAGE]
                        product[RESULT_PRDT_URL] = clientProducts[product[RESULT_PRDT_SKU]][RESULT_PRDT_URL]
                        product[RESULT_PRDT_NAME] = clientProducts[product[RESULT_PRDT_SKU]][RESULT_PRDT_NAME]
                        product[RESULT_PRDT_MODEL_NO] = clientProducts[product[RESULT_PRDT_SKU]][CMPT_CONF_PRDT_MODEL_NUMBER] ?? product[RESULT_PRDT_MODEL_NO]
                        product[RESULT_PRDT_DESC] = clientProducts[product[RESULT_PRDT_SKU]][RESULT_PRDT_DESC]
                        if(clientProducts[product[RESULT_PRDT_SKU]][RESULT_MANUFACTURER]) product[RESULT_MANUFACTURER] = clientProducts[product[RESULT_PRDT_SKU]][RESULT_MANUFACTURER]
                        if(availabilityFlag && clientProducts[product[RESULT_PRDT_SKU]][RESULT_PRDT_STOCK_STATUS] !== STOCK_STATUSES.IN_STOCK) return false
                        return true
                    })
                }
                catch(error){
                    // setCompatioProducts(res.products ?? [])
                    // console.debug("Client Inventory check failed, showing all products")
                }
                if(controller.signal.aborted) return


                // if no products found fetch next page products
                if(filteredProducts.length === 0
                    && isInfinite 
                    && productCount > 0 
                    && (page * MAX_PRODUCTS_PER_PAGE) < productCount
                ) {

                    if(page === 1) {
                        setCompatioProducts([])
                    }

                    setPage(prev => prev + 1)
                    return 
                }
            }
            else {
                // if no clints check gms availability
                filteredProducts = [...filteredProducts?.filter((product) => !availabilityFlag || product[RESULT_IS_AVAILABLE] === true)]
            }

            try{
                // If third party pricing engines are used 
                // fetch the prices and replace Compatio prices
                if(
                    (window.compatioConfig?.compatibleProducts?.[CMPT_CONF_PRICE_ENGINE] === 'true'
                    || window.compatioConfig?.compatibleProducts?.[CMPT_CONF_PRICE_ENGINE] === true) 
                    && productSkus.length > 0
                ) {
                    const prices = await PriceEngine.fetchProductPricesBySku(productSkus)
                    if(controller.signal.aborted) return
        
                    if(prices !== "404"){
                        // filteredProducts = filteredProducts.filter(product => prices[product[RESULT_PRDT_SKU]] && !!prices[product[RESULT_PRDT_SKU]].price && prices[product[RESULT_PRDT_SKU]].show_price !== "none" && prices[product[RESULT_PRDT_SKU]].show_price !== "cart")

                        filteredProducts.forEach((product) => {
                            if(prices[product[RESULT_PRDT_SKU]]) product[RESULT_PRDT_PRICE] = _.toNumber(prices[product[RESULT_PRDT_SKU]].price)
                            if(!_.toNumber(prices[product[RESULT_PRDT_SKU]].price) || 
                                prices[product[RESULT_PRDT_SKU]].show_price === "none" || 
                                prices[product[RESULT_PRDT_SKU]].show_price === "cart") {
                                    product[RESULT_PRDT_IS_QUOTE] = true;
                                } else {
                                    product[RESULT_PRDT_IS_QUOTE] = false; // Explicitly set false for other cases
                                }
                        })
                    }
                }
            }
            catch(err) {
                console.debug("Price engine call failed, Prices may not be proper", err)
            }

            if(_sort.s_key && _sort.s_order) {
                filteredProducts = filteredProducts.sort(Utils.sortChoices(
                    true, 
                    _sort.s_order === "asc" ? SORT.ASC : SORT.DESC,
                    (item) => ""+item[_sort.s_key as string],
                ) as any)
            }

            if(controller.signal.aborted) return

            // whether to replace current products list with new ones
            if(
                _isReset ||
                !isConfigurator
            )  {
                 // if fetched products are less than MAX_PRODUCTS_PER_PAGE then fetch another set of products to negate the difference
                if(filteredProducts.length < MAX_PRODUCTS_PER_PAGE && productCount > 0 
                    && (page * MAX_PRODUCTS_PER_PAGE) < productCount) {
                    productCountDiff.current = productCountDiff.current + filteredProducts.length
                    setPage(prev => prev + 1)
                }
                else {
                    setCountLoading(false)
                    _countLoading = false
                    productCountDiff.current = 0
                }
                setInitialLoading(false)
                setCompatioProducts(filteredProducts ?? [])
                return
            }

          
            
            if(!isConfigurator){
                setCompatioProducts(filteredProducts)
            }
            else {
                // for infinite loading
                setCompatioProducts((prev) => ([...prev, ...filteredProducts]))
            }
            setInitialLoading(false)

            // if fetched products are less than MAX_PRODUCTS_PER_PAGE then fetch another set of products to negate the difference
            if((productCountDiff.current + filteredProducts.length) < MAX_PRODUCTS_PER_PAGE  && productCount > 0 
                && (page * MAX_PRODUCTS_PER_PAGE) < productCount) {
                productCountDiff.current = productCountDiff.current + filteredProducts.length
                setPage(prev => prev + 1)
                return
            }
            else {
                setCountLoading(false)
                _countLoading = false
                productCountDiff.current = 0
            }
        }
        catch(err: any) {
            // console.error("Error caught:", err.message)
            toast.error(err.message)
        }
        finally{
            if(controller.signal.aborted) return

            // setCategoryProductsCount(prev => ({
            //     ...prev,
            //     [instance[RUNTIME_INSTANCE_INSTANCE_ID]]: {
            //         ...prev[instance[RUNTIME_INSTANCE_INSTANCE_ID]],
            //         [(categories as TProductCategoryField[])[0][FIELD_ID]]: Utils.checkIfClientWebsite() 
            //             ? (isInfinite && !_isReset ? (prev[instance[RUNTIME_INSTANCE_INSTANCE_ID]]?.[(categories as TProductCategoryField[])[0][FIELD_ID]] || 0) + filteredProducts.length : categoryCountRef.current)
            //             : productCount
            //     }
            // }))

            if(!(isArray(_categories) && _categories?.length <= 0) && (!_countLoading || productCount > 50)){
                setCategoryProductsCount(prev => ({
                    ...prev,
                    [instance[RUNTIME_INSTANCE_INSTANCE_ID]]: {
                        ...prev[instance[RUNTIME_INSTANCE_INSTANCE_ID]],
                        [(categories as TProductCategoryField[])[0][FIELD_ID]]: (productCount > 50 ? productCount : (Utils.checkIfClientWebsite() ? categoryCountRef.current : filteredProducts.length)) ?? 0
                    }
                }))
            }
            setIsLoading(false)
            setLoading(false)
            setTableDataLoading(false)
            setInitialLoading(false)
            NProgress.done()
        }
    }

    useEffect(() => {
        setTableDataLoading(true)

        fetchData(true, page === 1)
    }, [page, trigger])

    const handleReset = () => {
        NProgress.start()
        setHeaders([])
        setPage(1)
        setTrigger(prevTrigger => prevTrigger + 1)
        setIsLoading(true)
        setLoading(true)
        setCountLoading(true)
        setCompatioProducts([])
        categoryCountRef.current = 0
        productCountDiff.current = 0

        // Abort any ongoing fetch
        if(abortRef.current) {
            abortRef.current.abort()
        }
    }

    useEffect(() => {
        const selectedProducts = (categories && runtimeSpec && Utils.getSelectedProductsFromCategoryIds(cart, [categories[0][CATEGORY_ID]], runtimeSpec?.[instance[RUNTIME_INSTANCE_INSTANCE_ID]]?.[RUNTIME_INSTANCE_INSTANCE_ID])) ?? undefined

        const isCategoryNotDone = (categories as TProductCategoryField[]).some((cat) => (
            (runtimeSpec?.[instance[RUNTIME_INSTANCE_INSTANCE_ID]]?.[MODEL_DYNAMIC_FIELDS][cat[CATEGORY_ID]][RUNTIME_INSTANCE_MAX_COUNT] ?? 1) 
            > Utils.getUniquePrdtCountByCategoryId(selectedProducts, cat[CATEGORY_ID]) && (runtimeSpec?.[instance[RUNTIME_INSTANCE_INSTANCE_ID]]?.[MODEL_DYNAMIC_FIELDS][cat[CATEGORY_ID]][RUNTIME_INSTANCE_MAX_QUANTITY] ?? 1) 
            > Utils.getProductQtyByCategoryId(selectedProducts, cat[CATEGORY_ID])))
        
        if(initialLoading || load === false || !isCategoryNotDone) {
            if(initialLoading) setInitialLoading(false)
            if(!isCategoryNotDone) {
                setIsLoading(false)
                setLoading(false)
            }
            return
        }
        handleReset()
    }, [
        cart,
        dataLoading, 
        availabilityFlag,
        categorySort,
        runtimeSpec
    ])

    // useEffect(() => {
    //     if(load === false) return 

    //     const check = categories ? (categories as TProductCategoryField[])?.map(cat => {
    //         return (instance?.[MODEL_DYNAMIC_FIELDS][cat[CATEGORY_ID]][RUNTIME_INSTANCE_MAX_QUANTITY] ?? 1) <= Utils.getProductQtyByCategoryId(selectedProducts, cat[CATEGORY_ID]) 
    //         || (instance?.[MODEL_DYNAMIC_FIELDS][cat[CATEGORY_ID]][RUNTIME_INSTANCE_MAX_COUNT] ?? 1) <= Utils.getUniquePrdtCountByCategoryId(selectedProducts, cat[CATEGORY_ID])
    //     }) : []

    //     const categoryProductSelectCheckChanged = JSON.stringify(check) !== JSON.stringify(categoriesProductsCheck.current)

    //     const categoryChanged = JSON.stringify(prevCategories.current) !== JSON.stringify(categories)


    //     const fields: (TRuntimeDynamicFieldValue|string)[] | undefined = instance?.dynamic_fields && Object.values(instance?.dynamic_fields).filter((field) => field.type !== "Category")
    //     if(instance?.[RUNTIME_INSTANCE_INSTANCE_ID]) fields?.push(instance[RUNTIME_INSTANCE_INSTANCE_ID])

    //     const filterValuesChanged = JSON.stringify(prevInstance.current) !== JSON.stringify(fields)

    //     const sortChanged = prevSort.current !== categorySort
    //     const availabilityChanged = availabilityFlag !== prevAvailabilityFlagValue.current

    //     categoriesProductsCheck.current = check
    //     prevInstance.current = fields
    //     prevSort.current = categorySort
    //     prevCategories.current = categories
    //     prevAvailabilityFlagValue.current = availabilityFlag

    //     // if any instance value changes,
    //     // if any category value check changes
    //     // if categorySort is changed
    //     // filter value changes
       
    //     if(!categoryProductSelectCheckChanged && !categoryChanged && !filterValuesChanged && !sortChanged && !availabilityChanged) {
    //         setLoading(false)
    //         setIsLoading(false)
    //         return 
    //     }

    //     handleReset()
    // }, [categories, categorySort, availabilityFlag, load])

    // useEffect(() => {
    //     if(!AppSettings.CLIENT_MAGENTO_API_END_POINT) return setFilteredProducts(compatioProducts)
    //     setFilteredProducts(compatioProducts.filter((product) => !availabilityFlag || product[RESULT_PRDT_STOCK_STATUS] === "IN_STOCK"))
    // }, [compatioProducts, availabilityFlag])

    if(!categories) return <></>

    const selectedProducts = (categories && runtimeSpec && Utils.getSelectedProductsFromCategoryIds(cart, [categories[0][CATEGORY_ID]], runtimeSpec?.[instance[RUNTIME_INSTANCE_INSTANCE_ID]]?.[RUNTIME_INSTANCE_INSTANCE_ID])) ?? undefined

    const isCategoryNotDone = (categories as TProductCategoryField[]).some((cat) => (
        (runtimeSpec?.[instance[RUNTIME_INSTANCE_INSTANCE_ID]]?.[MODEL_DYNAMIC_FIELDS][cat[CATEGORY_ID]][RUNTIME_INSTANCE_MAX_COUNT] ?? 1) 
        > Utils.getUniquePrdtCountByCategoryId(selectedProducts, cat[CATEGORY_ID]) && (runtimeSpec?.[instance[RUNTIME_INSTANCE_INSTANCE_ID]]?.[MODEL_DYNAMIC_FIELDS][cat[CATEGORY_ID]][RUNTIME_INSTANCE_MAX_QUANTITY] ?? 1) 
        > Utils.getProductQtyByCategoryId(selectedProducts, cat[CATEGORY_ID])))

    const isRequired = runtimeSpec && Object.entries(runtimeSpec[instance[RUNTIME_INSTANCE_INSTANCE_ID]][MODEL_DYNAMIC_FIELDS])
    .filter(([catId, cat]) => categories[0].id === catId && cat[RUNTIME_INSTANCE_ELEMENT_TYPE] === RUNTIME_ELEMENT_TYPES.CATEGORY)
    .some(([catId, cat]) => (cat[RUNTIME_INSTANCE_MIN_QUANTITY] ?? 0) > 0)

    if(load === false) return <>
        { 
                activePage?.[PAGE_LAYOUT] === PAGE_LAYOUTS.GUIDED_SELLING && <>
                <Title title={`Select ${uiModel?.[FRMDATA_CONFIG]?.[FRMDATA_DISPLAY_NAME]}`} titleClassName="!sb3-text-lg sb3-pt-3"/>
                <p>Compatibility based on selections in your build</p>
                </>
        }

        <div className="sb3-px-2 sb3-py-7 sb3-flex sb3-justify-center sb3-bg-gray sb3-text-gray-extraDark sb3-border">
            <p>Select required option to load {uiModel?.[FRMDATA_CONFIG]?.[FRMDATA_DISPLAY_NAME]}{(isRequired && isCategoryNotDone) && <span className="sb3-text-danger">*</span>}</p>
        </div>
    </>

    const updateFilters = (filters: TFilters) => {
        setPage(filters.page ?? 1)
        setLimit(filters.limit ? +filters.limit : MAX_PRODUCTS_PER_PAGE)
    }

    const category = uiModel?.[FRMDATA_ITEMS].find((node) => node[FRMDATA_TYPE] === FIELD_TYPES.CATEGORY && node[FRMDATA_ID] === categories[0][FIELD_ID])
    const categoryLabel = category?.[FRMDATA_CATEGORY_LABEL]

    // const handleAddToBuild = () => {
    //     setShowConfigurations({ name: "Set Show Configurations"})
    // }

    // const selectedProductsSkus = selectedProducts?.map((product) => product[RESULT_PRDT_SKU])
    const isGrid = window.innerWidth < 648 || (uiModel?.[FRMDATA_CONFIG]?.[CUSTOMIZATION_VIEW_PREF] ?? categoryDisplayType) === CATEGORY_DISPLAY_TYPES.GRID
    

    if(isConfigurator) {
        const selectedModelNumbersAndQty = Object.fromEntries(selectedProducts
            ?.map(product => [`${product[RESULT_PRDT_SKU]}-${product[RESULT_PRDT_INSTANCEID]}-${product[RESULT_PRDT_CATEGORY_ID]}`, product]) ?? [])


        if(!loading && !initialLoading && !countLoading && compatioProducts.length === 0 && selectedProducts?.length === 0) return (
            <div>
                {
                activePage?.[PAGE_LAYOUT] !== PAGE_LAYOUTS.GUIDED_SELLING && 
                <>
                 <CategoryHeader
                    category={category}
                    title={categoryLabel ?? `Select ${categories[0][MODEL_DATA][CATEGORY_NAME]}`}
                    productCount={categoryProductsCount[instance[RUNTIME_INSTANCE_INSTANCE_ID]]?.[(categories as TProductCategoryField[])[0][FIELD_ID]] ?? 0}
                    selectedProducts={selectedProducts}
                    setFilterOpen={setFilterOpen}
                    submodelName={uiModel?.[FRMDATA_CONFIG][FRMDATA_DISPLAY_NAME]}
                    isLoading={loading}
                    setCategorySort ={setCategorySort} 
                    categorySort={categorySort}
                    model = {submodel}
                    uiModel={uiModel}
                    instance={instance}
                    noProducts = {true}
                    isRequired={(isRequired && isCategoryNotDone)}
                />
                { 
                    isCategoryNotDone && Utils.checkIfClientWebsite() && availabilityFlag && 
                    <div className="sb3-flex sb3-flex-col-reverse sb3-items-end sm:sb3-items-center sm:sb3-flex-row sb3-justify-between sb3-gap-x-2 sb3-pt-2 sb3-flex-wrap sb3-font-semibold">
                        
                        <div>
                            <span className="sb3-flex sb3-text-sm sb3-text-gray-extraDark sb3-items-center sb3-space-x-2">
                                <span>{((initialLoading || isLoading || countLoading)) ? <SkeletonElement type={"sb3-title !sb3-w-9 sb3-bg-gray-100 sb3-animate-pulse"}/> : (compatioProducts.length < 50 ? compatioProducts.length : '50+')}</span> 
                                <span>IN STOCK models Available</span>
                            </span>
                        </div>
                        

                        <AvailabilityInput
                            availabilityFlag={availabilityFlag}
                            setAvailabilityFlag={setAvailabilityFlag}
                        />
                    </div>
                }
                
                </>
                }
                { 
                    activePage?.[PAGE_LAYOUT] === PAGE_LAYOUTS.GUIDED_SELLING && 
                    <div className="sb3-space-y-1">
                        <h4 style={{ color: builderSpec?.[BUILDER_CONFIG]?.[CONFIG_CUSTOMIZATION]?.[CUSTOMIZATION_THEME_SETTINGS]?.[THEME_TITLE] }} className="sb3-flex sb3-space-x-2 sb3-font-bold sb3-text-lg">
                            {categoryLabel ?? `Select ${categories[0][MODEL_DATA][CATEGORY_NAME]}`}{(isRequired && isCategoryNotDone) && <span className="sb3-text-danger">*</span>}
                        </h4>
                        <p>Compatibility based on selections in your build</p>

                        { 
                            // dont show availability check is not compatible products unless its turned on 
                            isCategoryNotDone && Utils.checkIfClientWebsite() && availabilityFlag &&
                            <div className="sb3-flex sb3-flex-col-reverse sb3-items-end sm:sb3-items-center sm:sb3-flex-row sb3-justify-between sb3-gap-x-2 sb3-pt-2 sb3-flex-wrap sb3-font-semibold">
                                
                                <div>
                                    <span className="sb3-flex sb3-text-sm sb3-text-gray-extraDark sb3-items-center sb3-space-x-2">
                                        <span>{((initialLoading || isLoading || countLoading)) ? <SkeletonElement type={"sb3-title !sb3-w-9 sb3-bg-gray-100 sb3-animate-pulse"}/> : (compatioProducts.length < 50 ? compatioProducts.length : '50+')}</span> 
                                        <span>IN STOCK models Available</span>
                                    </span>
                                </div>
                                

                                <AvailabilityInput
                                    availabilityFlag={availabilityFlag}
                                    setAvailabilityFlag={setAvailabilityFlag}
                                />
                            </div>
                        }
                    </div>
                }   

                <div className="sb3-flex sb3-flex-col sb3-items-center sb3-py-3">
                    <HiOutlineShoppingBag className="sb3-text-7xl sb3-text-gray-400 sb3-fill-none sb3-my-3"/> 
                    {
                        platform !== THEME_PLATFORMS.BIG_COMMERCE ? <p className='sb3-font-bold sb3-text-gray-400'>No compatible products found {availabilityFlag && "in-stock"}</p>
                        : <p className='sb3-font-bold sb3-text-sm sb3-text-gray-400 sb3-text-center sb3-max-w-[474px]'>We do not currently show options {availabilityFlag && "that are instock"} for this configuration online. Please contact us to discuss offline options we can recommend. Thank you!</p>
                    }
                    
                </div>
            </div>
        );

        return (
            <>
            {
                activePage?.[PAGE_LAYOUT] !== PAGE_LAYOUTS.GUIDED_SELLING && 
                <>
                <CategoryHeader
                    category={category}
                    title={categoryLabel ?? `Select ${categories[0][MODEL_DATA][CATEGORY_NAME]}`}
                    productCount={categoryProductsCount[instance[RUNTIME_INSTANCE_INSTANCE_ID]]?.[(categories as TProductCategoryField[])[0][FIELD_ID]] ?? 0}
                    selectedProducts={selectedProducts}
                    setFilterOpen={setFilterOpen}
                    submodelName={uiModel?.[FRMDATA_CONFIG][FRMDATA_DISPLAY_NAME]}
                    isLoading={loading}
                    setCategorySort ={setCategorySort} 
                    categorySort={categorySort}
                    model = {submodel}
                    uiModel={uiModel}
                    instance={instance}
                    isCategoryNotDone={isCategoryNotDone}
                    isRequired={(isRequired && isCategoryNotDone)}
                />
                { 
                    isCategoryNotDone &&
                    <div className="sb3-hidden lg:sb3-inline sb3-items-center sb3-w-1/2 lg:sb3-w-full lg:sb3-space-y-1">
                        {/* <label className="sb3-text-sm lg:sb3-block sb3-hidden sb3-ml-0 sb3-font-bold lg:sb3-m-auto">Sort By:</label> */}
                        <SB2Sort 
                        category={category} 
                        categorySort={categorySort} 
                        setCategorySort={setCategorySort}
                        />
                        {/*<SB2Sort categoryID={} categoryName={} categoryPosition={} />*/}
                    </div>
                }

                { 
                isCategoryNotDone && Utils.checkIfClientWebsite() && 
                <div className="sb3-flex sb3-flex-col-reverse sb3-items-end sm:sb3-items-center sm:sb3-flex-row sb3-justify-between sb3-space-x-2 sb3-pt-2 sb3-flex-wrap sb3-font-semibold">
                    <div>
                        { 
                            availabilityFlag && 
                            <span className="sb3-flex sb3-text-sm sb3-text-gray-extraDark sb3-items-center sb3-space-x-2">
                                <span>{((initialLoading || isLoading || countLoading)) ? <SkeletonElement type={"sb3-title !sb3-w-9 sb3-bg-gray-100 sb3-animate-pulse"}/> : (compatioProducts.length < 50 ? compatioProducts.length : '50+')}</span> 
                                <span>IN STOCK models Available</span>
                            </span>
                        }
                    </div>

                    <AvailabilityInput
                        availabilityFlag={availabilityFlag}
                        setAvailabilityFlag={setAvailabilityFlag}
                    />
                </div>
            }
                </>
            }
            

            

            { 
                activePage?.[PAGE_LAYOUT] === PAGE_LAYOUTS.GUIDED_SELLING && 
                <div className="sb3-space-y-1">
                    <h1 className="!sb3-text-lg">{!!categoryLabel ? categoryLabel:
                    `Select ${(categories[0][MODEL_DATA][CATEGORY_NAME] ?? "")}`}{(isRequired && isCategoryNotDone) && <span className="sb3-text-danger">*</span>}</h1>
                    <p>Compatibility based on selections in your build</p>
                    { 
                        isCategoryNotDone &&
                        <div className="sb3-flex sb3-items-center lg:sb3-gap-1">
                            {/* <label className="sb3-text-sm lg:sb3-block sb3-hidden sb3-ml-0 sb3-font-bold lg:sb3-m-auto">Sort By:</label> */}
                            <SB2Sort 
                            category={category} 
                            categorySort={categorySort} 
                            setCategorySort={setCategorySort}
                            />
                        </div>
                    }
                    { 
                        isCategoryNotDone && Utils.checkIfClientWebsite() && 
                        <div className="sb3-flex sb3-flex-col-reverse sb3-items-end sm:sb3-items-center sm:sb3-flex-row sb3-justify-between sb3-gap-x-2 sb3-pt-2 sb3-flex-wrap sb3-font-semibold">
                            
                            <div>
                                {
                                    availabilityFlag &&
                                    <span className="sb3-flex sb3-text-sm sb3-text-gray-extraDark sb3-items-center sb3-space-x-2">
                                        <span>{((initialLoading || isLoading || countLoading)) ? <SkeletonElement type={"sb3-title !sb3-w-9 sb3-bg-gray-100 sb3-animate-pulse"}/> : (compatioProducts.length < 50 ? compatioProducts.length : '50+')}</span> 
                                        <span>IN STOCK models Available</span>
                                    </span>
                                }
                            </div>
                            

                            <AvailabilityInput
                                availabilityFlag={availabilityFlag}
                                setAvailabilityFlag={setAvailabilityFlag}
                            />
                        </div>
                    }
                </div>
                
            }

            <div className="">
                <div>
                    {/* <AnimatePresence> */}
                    {
                        (selectedProducts && selectedProducts.length > 0) &&
                        <div 
                        // initial={{ maxHeight: 0, opacity: 0}}
                        // animate = {{maxHeight: "400px", opacity: 1}}
                        // exit = {{maxHeight: 0, opacity: 0}}
                        className={`sb3-border sb3-border-solid sb3-border-primary sb3-bg-primary-light sb3-p-2 sb3-mb-2.5 ${platform === THEME_PLATFORMS.BIG_COMMERCE ? "sb3-rounded-lg" : ""}`}
                        style={{
                            backgroundColor:builderSpec?.[BUILDER_CONFIG]?.[CONFIG_CUSTOMIZATION]?.[CUSTOMIZATION_THEME_SETTINGS]?.[THEME_SECONDARY] && 
                            Utils.newShade(builderSpec[BUILDER_CONFIG][CONFIG_CUSTOMIZATION][CUSTOMIZATION_THEME_SETTINGS][THEME_SECONDARY], 100),
                            borderColor: builderSpec?.[BUILDER_CONFIG]?.[CONFIG_CUSTOMIZATION]?.[CUSTOMIZATION_THEME_SETTINGS]?.[THEME_SECONDARY] && 
                            Utils.newShade(builderSpec[BUILDER_CONFIG][CONFIG_CUSTOMIZATION][CUSTOMIZATION_THEME_SETTINGS][THEME_SECONDARY], -10)
                        }}                        
                        >
                            {/* @todo change to product name */}
                            {categories[0][MODEL_DATA][CATEGORY_NAME] ?? ""} added your outfit: <b>{selectedProducts?.map(product => 
                                // ProductHelper.getProductName(product)
                                `${Utils.isShopify() ? product[RESULT_PRDT_SKU] : product[RESULT_PRDT_MODEL_NO]}${product[RESULT_PRDT_QTY] > 1 ? 'x'+product[RESULT_PRDT_QTY] : ""}`).join(', ')
                            }</b>
                        </div>
                    }
                    {/* </AnimatePresence> */}
                    <div className="sb3-relative -sb3-z-0">
                        {
                            (!initialLoading && !loading && !!instance) ? 
                                isCategoryNotDone ? 
                                    <>
                                        {isLoading && <div className="sb3-flex sb3-justify-center sb3-w-full"><span className="sb3-animate-spin sb3-text-primary sb3-aspect-square"><FaSpinner/></span></div>}
                                        <InfiniteScroll
                                            dataLength={compatioProducts.length}
                                            next={() => {
                                                return !isLoading && setPage(prev => (prev ?? 1) + 1)}
                                            }
                                            hasMore={((categoryProductsCount[instance[RUNTIME_INSTANCE_INSTANCE_ID]]?.[(categories as TProductCategoryField[])[0][FIELD_ID]]) !== 0 
                                                && !isLoading
                                                && (page * MAX_PRODUCTS_PER_PAGE) < (categoryProductsCount[instance[RUNTIME_INSTANCE_INSTANCE_ID]]?.[(categories as TProductCategoryField[])[0][FIELD_ID]]))}
                                            loader={
                                                <>
                                                {
                                                    [...Array(5)].map((_, key) => (
                                                            <ProductSkeleton key = {key}  type = {isGrid ? PRDT_DISPLAY_TYPES.VERTICAL : PRDT_DISPLAY_TYPES.HORIZONTAL}/>
                                                        )
                                                    )
                                                }
                                                </>
                                            }
                                            scrollableTarget="scrollableDiv"
                                            // useWindow={false}
                                            // getScrollParent={() => parentRef.current}
                                            // threshold={200}
                                            className={isGrid ? "sb3-grid sb3-gap-2 sb3-grid-flow-row sb3-grid-cols-[repeat(auto-fill,_minmax(190px,_1fr))]" : "sb3-grid sm:sb3-flex sb3-flex-col sb3-gap-2 sb3-grid-flow-row sb3-grid-cols-[repeat(auto-fill,_minmax(160px,_1fr))]"}
                                        >
                                            
                                            {compatioProducts.map((product, index) => {
                                                const category = categoryIdMap[product[RESULT_PRDT_CATEGORY_ID]]
                                                let buttonLabel = undefined
                                                if(builderSpec && submodel){
                                                    const pageSpec = builderSpec[BUILDER_PAGES].find((spec) => {
                                                        return isArray(spec[PAGE_FORM_DATA]) && spec[PAGE_FORM_DATA].some((data) => data[FRMDATA_SMID] === submodel[MODEL_SUBMODEL_ID])
                                                    })
                                                    const submodelSpec = pageSpec?.[PAGE_FORM_DATA].find((data) => data[FRMDATA_SMID] === submodel[MODEL_SUBMODEL_ID])
                                                    buttonLabel = submodelSpec?.items.find((item) => item.type === "Category")?.[FIELD_BUTTON_LABEL]
                                                }
                                                if(!category) return undefined
                                                
                                                return (
                                                <div key={index} className={`compatio-product-sb3-fXCxMgOAT`}>
                                                    <Product
                                                        category={category}
                                                        product = {product}
                                                        selected = {Object.keys(selectedModelNumbersAndQty).includes(`${product[RESULT_PRDT_SKU]}-${instance[RUNTIME_INSTANCE_INSTANCE_ID]}-${product[RESULT_PRDT_CATEGORY_ID]}`)}
                                                        quantity={selectedModelNumbersAndQty[`${product[RESULT_PRDT_SKU]}-${instance[RUNTIME_INSTANCE_INSTANCE_ID]}-${product[RESULT_PRDT_CATEGORY_ID]}`]?.quantity ?? 0}
                                                        showQtyInput = {(instance[MODEL_DYNAMIC_FIELDS][category[CATEGORY_ID]][RUNTIME_INSTANCE_MAX_QUANTITY] ?? 1) > (Utils.getProductQtyByCategoryId(selectedProducts, category[CATEGORY_ID]))}
                                                        maxQty={instance[MODEL_DYNAMIC_FIELDS][category[CATEGORY_ID]][RUNTIME_INSTANCE_MAX_QUANTITY] ?? 1}
                                                        instance = {instance}
                                                        buttonLabel={
                                                            product[RESULT_PRDT_IS_QUOTE] ? "Request Quote" :
                                                            buttonLabel
                                                        }
                                                        type={isGrid ? PRDT_DISPLAY_TYPES.VERTICAL : PRDT_DISPLAY_TYPES.HORIZONTAL}
                                                        showImage={!category.data.categories.data[0].id || !CATEGORIES_WITHOUT_IMAGE.includes(category.data.categories.data[0].id)}
                                                    />
                                                </div>
                                            )})}
                                        </InfiniteScroll>
                                    </>
                                    :
                                    <div className={isGrid ? "sb3-grid sb3-gap-2 sb3-grid-flow-row sb3-grid-cols-[repeat(auto-fill,_minmax(190px,_1fr))]" : "sb3-grid sm:sb3-flex sb3-flex-col sb3-gap-2 sb3-grid-flow-row sb3-grid-cols-[repeat(auto-fill,_minmax(160px,_1fr))]"}>
                                        {
                                        selectedProducts?.map((product, key) => {
                                            let buttonLabel = undefined
                                            if(builderSpec && submodel){
                                                const pageSpec = builderSpec[BUILDER_PAGES].find((spec) => {
                                                    return isArray(spec[PAGE_FORM_DATA]) && spec[PAGE_FORM_DATA].some((data) => data[FRMDATA_SMID] === submodel[MODEL_SUBMODEL_ID])
                                                })
                                                const submodelSpec = pageSpec?.[PAGE_FORM_DATA].find((data) => data[FRMDATA_SMID] === submodel[MODEL_SUBMODEL_ID])
                                                buttonLabel = submodelSpec?.items.find((item) => item.type === "Category")?.[FIELD_BUTTON_LABEL]
                                            }
                                            const category = categoryIdMap[product[RESULT_PRDT_CATEGORY]]
                                            if(!category) return undefined
        
                                            return (
                                                    <Product
                                                        key={key}
                                                        category={category}
                                                        product = {product}
                                                        selected = {true}
                                                        instance = {instance}
                                                        showQtyInput = {(instance[MODEL_DYNAMIC_FIELDS][category[CATEGORY_ID]][RUNTIME_INSTANCE_MAX_QUANTITY] ?? 1) > (Utils.getProductQtyByCategoryId(selectedProducts, category[CATEGORY_ID]))}
                                                        quantity={selectedModelNumbersAndQty[`${product[RESULT_PRDT_SKU]}-${instance[RUNTIME_INSTANCE_INSTANCE_ID]}-${product[RESULT_PRDT_CATEGORY_ID]}`]?.quantity ?? 1}
                                                        maxQty={instance[MODEL_DYNAMIC_FIELDS][category[CATEGORY_ID]][RUNTIME_INSTANCE_MAX_QUANTITY] ?? 1}
                                                        type={isGrid ? PRDT_DISPLAY_TYPES.VERTICAL : PRDT_DISPLAY_TYPES.HORIZONTAL}
                                                        buttonLabel={
                                                            product[RESULT_PRDT_IS_QUOTE] ? "Request Quote" :
                                                            buttonLabel
                                                        }
                                                        showImage={!category.data.categories.data[0].id || !CATEGORIES_WITHOUT_IMAGE.includes(category.data.categories.data[0].id)}
                                                    />
                                            )
                                        })
                                        }
                                    </div>
                            :
                            <div className={isGrid ? "sb3-grid sb3-gap-2 sb3-grid-flow-row sb3-grid-cols-[repeat(auto-fill,_minmax(190px,_1fr))]" : "sb3-grid sm:sb3-flex sb3-flex-col sb3-gap-2 sb3-grid-flow-row sb3-grid-cols-[repeat(auto-fill,_minmax(160px,_1fr))]"}>
                                {
                                    [...Array(5)].map((_, key) => (
                                        <ProductSkeleton key = {key} type = {(isGrid || window.innerWidth < 640) ? PRDT_DISPLAY_TYPES.VERTICAL : PRDT_DISPLAY_TYPES.HORIZONTAL}/>
                                    )
                                    )

                                }
                            </div>
                        }
                    </div>
                </div>
            </div>
        </>
        )
    }

    return (
        <div className="sb3-relative -sb3-z-0">
            <Header title = {categories[0][MODEL_DATA][CATEGORY_NAME] ?? ""}/>
            
            <Table
                columns = {headers}
                data = {compatioProducts}
                dataCallback = {(item) => item}
                loading={loading}
                dataLoading={tableDataLoading}
                pageSize={limit}
                totalRecords={categoryProductsCount[instance[RUNTIME_INSTANCE_INSTANCE_ID]]?.[(categories as TProductCategoryField[])[0][FIELD_ID]]}
                fetchData={fetchData}
                updateFilters={updateFilters}
                options= {{
                    footer: true,
                    pagination: "auto",
                    height: "490px",
                    selectable: true,
                    striped: true,
                }}
            />
        </div>
    )
}

export default Categories