import { isArray } from "lodash";
import { Dispatch, SetStateAction } from "react";
import { TConflictField } from "types";

const TabHeader = ({ title, isActive, onClick, count }: { title: string; isActive: boolean; onClick: () => void, count?: number }) => (
    <button
      onClick={onClick}
      className={`sb3-font-semibold sb3-text-sm sb3-flex sb3-items-center focus:!no-underline sb3-bg-[#00000000] !sb3-border-0 focus:!sb3-outline-none sb3-space-x-2 ${
        isActive 
          ? 'sb3-text-[#106AEF]' 
          : 'sb3-text-gray-500 hover:sb3-text-gray-700'
      }`}
    >
      <span
      className={`
        ${isActive 
          ? 'sb3-text-[#106AEF] sb3-border-b-2 sb3-py-2 sb3-border-[#106AEF]' 
          : 'sb3-text-gray-500 sb3-border-b-2 sb3-py-2 sb3-border-[#00000000] hover:sb3-text-gray-700'
        }
      `}
      >{title}</span>
      { 
        !!count && <span className={`
          ${isActive ? "sb3-bg-[#D1E4FF]" : "sb3-bg-[#E5E5E5] sb3-text-black"}
          sb3-py-0.5 sb3-flex sb3-items-center sb3-px-2 sb3-text-sm sb3-rounded-full`}>
          {String(count).padStart(2, '0')
      }
      </span> }
    </button>
  );

type MyProps = {
    conflictedFields: [string, TConflictField][] | undefined
    conflictedProducts:[string, TConflictField][] | undefined
    activeTab: string
    setActiveTab: Dispatch<SetStateAction<"conflicts" | "products">>
}
const DisabledModalHeader = ({
    conflictedFields,
    conflictedProducts,
    activeTab,
    setActiveTab
}: MyProps) => {
    return (
        <div>
          <div className="sb3-flex sb3-space-x-4">
            { isArray(conflictedFields) && conflictedFields?.length > 0 && (
              <TabHeader
                title="Conflicting Options"
                isActive={isArray(conflictedProducts) && conflictedProducts?.length > 0 && activeTab === 'conflicts'}
                onClick={() => setActiveTab('conflicts')}
                count = {conflictedFields?.length}
              />
            )}
            { isArray(conflictedProducts) && conflictedProducts?.length > 0 && (
              <TabHeader
                title="Conflicting Products"
                isActive={isArray(conflictedFields) && conflictedFields?.length > 0 && activeTab === 'products'}
                onClick={() => setActiveTab('products')}
                count = {conflictedProducts?.length}
              />
            )}
          </div>
        </div>
    )
}

export default DisabledModalHeader