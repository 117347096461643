import * as consts from "constants/constants";
import { useAppContext } from "contexts/AppContext";
import { TouchEventHandler, useEffect, useLayoutEffect, useRef, useState } from "react";
import { TUI_BuilderPage } from "types";

const usePages = () => {
    const {
        theme,
        activePage,
        getInstances,
        builderSpec    
    } = useAppContext()

    const pagesNavRef = useRef<HTMLDivElement>(null);
    const [scrollIndicator, setScrollIndicator] = useState({ left: false, right: true });
    const [isDragging, setIsDragging] = useState(false);
    const [startX, setStartX] = useState(0);
    const [scrollLeft, setScrollLeft] = useState(0);

    const handleScrollIndicator = () => {
        const el = pagesNavRef.current;
        if (!el) return;

        // Use requestAnimationFrame for smoother updates
        requestAnimationFrame(() => {
            const left = el.scrollLeft > 2; // Small threshold to prevent flickering
            const right = el.scrollWidth - el.scrollLeft - el.clientWidth > 2;
            setScrollIndicator({ left, right });
        });
    };

    useLayoutEffect(() => {
        const el = pagesNavRef.current;
        if (!el) return;

        // Use a more performant scroll listener
        let scrollTimeout: NodeJS.Timeout;
        const throttledScroll = () => {
            if (scrollTimeout) clearTimeout(scrollTimeout);
            scrollTimeout = setTimeout(handleScrollIndicator, 100);
        };

        // Initial check
        handleScrollIndicator();

        // Add listeners with throttling
        el.addEventListener('scroll', throttledScroll, { passive: true });
        window.addEventListener('resize', throttledScroll, { passive: true });

        return () => {
            el.removeEventListener('scroll', throttledScroll);
            window.addEventListener('resize', throttledScroll);
            if (scrollTimeout) clearTimeout(scrollTimeout);
        };
    }, []);
    
    function isElementInViewport(el: HTMLElement) {
        if(!pagesNavRef.current || !el) return false;
        const rect = el.getBoundingClientRect();
        const containerRect = pagesNavRef.current?.getBoundingClientRect();
        
        const buffer = 5; // Add a small buffer for better detection
        return (
            rect.left >= (containerRect.left - buffer) &&
            rect.right <= (containerRect.right + buffer)
        );
    }

    const findVisiblePages = () => {
        if (!pagesNavRef.current) return [];
        const visiblePages: string[] = [];
        
        newPages.forEach(page => {
            const el = document.querySelector(`#step-submodel-${page[consts.PAGE_ID]}`);
            if (el && isElementInViewport(el as HTMLElement)) {
                visiblePages.push(page[consts.PAGE_ID]);
            }
        });
        
        return visiblePages;
    };
    

    const selectPrev = () => {
        if (!pagesNavRef.current) return;
        
        const visiblePages = findVisiblePages();
        if (visiblePages.length === 0) return;
        
        const firstVisibleIndex = newPages.findIndex(page => page[consts.PAGE_ID] === visiblePages[0]);
        const targetIndex = Math.max(0, firstVisibleIndex - 1);
        const targetPage = newPages[targetIndex];
        
        const targetElement = document.querySelector(`#step-submodel-${targetPage[consts.PAGE_ID]}`);
        if (targetElement) {
            const scrollOptions = {
                behavior: 'smooth' as ScrollBehavior,
                block: 'nearest' as ScrollLogicalPosition,
                inline: 'center' as ScrollLogicalPosition
            };
            
            // Use scrollIntoView instead of scrollBy
            targetElement.scrollIntoView(scrollOptions);
        }
    };

    const selectNext = () => {
        if (!pagesNavRef.current) return;
        
        const visiblePages = findVisiblePages();
        if (visiblePages.length === 0) return;
        
        const lastVisibleIndex = newPages.findIndex(page => page[consts.PAGE_ID] === visiblePages[visiblePages.length - 1]);
        const targetIndex = Math.min(newPages.length - 1, lastVisibleIndex + 1);
        const targetPage = newPages[targetIndex];
        
        const targetElement = document.querySelector(`#step-submodel-${targetPage[consts.PAGE_ID]}`);
        if (targetElement) {
            const scrollOptions = {
                behavior: 'smooth' as ScrollBehavior,
                block: 'nearest' as ScrollLogicalPosition,
                inline: 'center' as ScrollLogicalPosition
            };
            
            // Use scrollIntoView instead of scrollBy
            targetElement.scrollIntoView(scrollOptions);
        }
    };

    // Touch event handlers
    const handleTouchStart: TouchEventHandler<HTMLDivElement> = (e) => {
        setIsDragging(true);
        if (!pagesNavRef.current) return;
        setStartX(e.touches[0].pageX - pagesNavRef.current.offsetLeft);
        setScrollLeft(pagesNavRef.current.scrollLeft);
    };

    const handleTouchMove: TouchEventHandler<HTMLDivElement> = (e) => {
        if (!isDragging || !pagesNavRef.current) return;
        e.preventDefault();
        const x = e.touches[0].pageX - pagesNavRef.current.offsetLeft;
        const walk = (x - startX) * 2;
        pagesNavRef.current.scrollLeft = scrollLeft - walk;
    };

    const handleTouchEnd = () => {
        setIsDragging(false);
    };

    useEffect(() => {
        const el = pagesNavRef.current;
        if (!el) return;

        el.addEventListener('touchstart', handleTouchStart as any);
        el.addEventListener('touchmove', handleTouchMove as any);
        el.addEventListener('touchend', handleTouchEnd);

        return () => {
            el.removeEventListener('touchstart', handleTouchStart as any);
            el.removeEventListener('touchmove', handleTouchMove as any);
            el.removeEventListener('touchend', handleTouchEnd);
        };
    }, [isDragging, startX, scrollLeft]);

    useEffect(() => {
        if(!activePage) return;
        document.querySelector(`#step-submodel-${activePage[consts.PAGE_ID]}`)?.scrollIntoView({ behavior: 'smooth', block: "nearest",inline: 'center' })
    }, [activePage])

    // Filter and group pages logic remains the same
    const newPages = builderSpec?.[consts.BUILDER_PAGES].filter((page) => {
        let isPageVisible = page[consts.PAGE_FORM_DATA].some((builderSubmodel) => {
            const _instances = getInstances(builderSubmodel[consts.FRMDATA_SMID]) ?? []
            return _instances?.filter(instance => !instance[consts.RUNTIME_INSTANCE_IS_EXCLUDED]).length > 0
        })
        return isPageVisible
    }) ?? [];

    const pageGroups = newPages?.reduce((acc: {[x: string]: TUI_BuilderPage[]}, page) => {
        const key = !!page[consts.PAGE_LABEL] ? page[consts.PAGE_LABEL]: "Other"
        if(!acc[key]) acc[key] = []
        acc[key].push(page)
        return acc
    },{}) ?? {};

    const lastPage = newPages[newPages.length - 1];
    const isNextActive = newPages.slice(-1)[0]?.[consts.PAGE_ID] !== activePage?.[consts.PAGE_ID];
    const isPrevActive = newPages[0]?.[consts.PAGE_ID] !== activePage?.[consts.PAGE_ID];

    // Mouse drag handlers
    const startDragging = (e: React.MouseEvent) => {
        setIsDragging(true);
        if(!pagesNavRef.current) return;
        setStartX(e.pageX - pagesNavRef.current.offsetLeft);
        setScrollLeft(pagesNavRef.current.scrollLeft);
    };

    const stopDragging = () => {
        setIsDragging(false);
    };

    const move = (e: React.MouseEvent) => {
        if (!isDragging || !pagesNavRef.current) return;
        e.preventDefault();
        const x = e.pageX - pagesNavRef.current.offsetLeft;
        const walk = (x - startX) * 2;
        pagesNavRef.current.scrollLeft = scrollLeft - walk;
    };

    return {
        scrollIndicator,
        pagesNavRef,
        newPages,
        pageGroups,
        lastPage,
        isNextActive,
        isPrevActive,
        selectPrev,
        selectNext,
        // Mouse drag handlers
        startDragging,
        stopDragging,
        move,
        // Touch handlers
        handleTouchStart,
        handleTouchMove,
        handleTouchEnd
    }
}

export default usePages;