import ProductHorizontal from "./ProductHorizontal"
import ProductVertical from "./ProductVertical"
import { useAppContext } from "contexts/AppContext"
import { TResultProduct  } from "types"
import { CATEGORY_ID, PRDT_DISPLAY_TYPES,  RESULT_PRDT_PRICE, RESULT_PRDT_SKU,  RUNTIME_INSTANCE_INSTANCE_ID } from "constants/constants"
import CompatioAnalytics from "helpers/CompatioAnalyticsSb3"
import { toast } from "react-toastify"
import {  useCallback } from "react"
import { debounce } from "lodash"
import Utils from "Services/Utils"
import { TProductPropsWithoutFns } from "./types"
import ProductHelper from "helpers/ProductHelper"

const Product = (props: TProductPropsWithoutFns) => { 
    const {
        builderConfig,
        removeProductFromCart,
        updateProductQty,
        setCart,
        cart,
        modelId,
        modelSpec
    } = useAppContext()

    const handleProductClick = (data: TResultProduct) => {
        const config = builderConfig()
        // console.log(data,'data',props.productSku,props.productName)
        CompatioAnalytics.track(
          CompatioAnalytics.events.xrsProductClick,
          {
            baseFinalProductID: Utils.getClientProductSku() ?? undefined,
            baseParentProductID: Utils.getClientProductSku() ?? undefined,
            baseFinalProductName: (config?.compatibleProducts?.productName && decodeURIComponent(config.compatibleProducts.productName)) ?? undefined,
            price: ProductHelper.getProductPrice(data),
            // referring_variant_id: this.props.merchantProductSku,
            clickAction: CompatioAnalytics.clicks.xrsOpenProduct,
            finalProductID: data[RESULT_PRDT_SKU],
            parentProductID: data[RESULT_PRDT_SKU],
          },
          undefined,
          'V1',
          modelSpec?.project.ISRName,
          modelId
        );

        const width = window.innerWidth;
        const height = window.innerHeight + 100;
        if(data.product_url){
          const options = `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=yes, resizable=yes, width=${width}, height=${height}`;
          window.open(data.product_url, 'popup', options);
        }
      };

      const debouncedQtyChange = useCallback(debounce((product: TResultProduct, quantity: number, category: string, instanceId: string, apiCall?: boolean) => updateProductQty(product, quantity, category, instanceId), 600), [])
  
      const handleQtyChange = (quantity: number) => {
        if(!props.instance) return

        if(!props.product[RESULT_PRDT_SKU]) return toast.error("SKU not found")

        const prevCart = [...cart]

        // if product is already not in cart
        if(Utils.findProductIndex(prevCart, props.product, props.category[CATEGORY_ID], props.instance[RUNTIME_INSTANCE_INSTANCE_ID]) === -1) return 

        if(quantity <= 0) {
            return removeProductFromCart(props.product, props.category[CATEGORY_ID], props.instance[RUNTIME_INSTANCE_INSTANCE_ID])
        }

        setCart((prev) => {
            const newCart = [...prev]

            const index = Utils.findProductIndex(prevCart, props.product, props.category[CATEGORY_ID], props.instance[RUNTIME_INSTANCE_INSTANCE_ID])

            // if product is already in cart
            if(index === -1) {
                return newCart
            }

            const newProductVal = {...newCart[index]}

            newProductVal.quantity = quantity
            newCart[index] = newProductVal
            return newCart
        })
        // updateProductQty(product, quantity, category[CATEGORY_ID], instance[RUNTIME_INSTANCE_INSTANCE_ID] as string)
        
        debouncedQtyChange(props.product, quantity, props.category[CATEGORY_ID], props.instance[RUNTIME_INSTANCE_INSTANCE_ID] as string)
    }

    if(props.type === PRDT_DISPLAY_TYPES.VERTICAL)
        return  <ProductVertical 
            handleProductClick={handleProductClick} 
            handleQtyChange={handleQtyChange} 
            {...props}
        />
 
    return <ProductHorizontal 
        handleProductClick={handleProductClick} 
        handleQtyChange={handleQtyChange} 
        {...props}
    />
}

export default Product