import Utils from "Services/Utils"
import { BUILDER_CONFIG, CMPT_CONF_COMPATIO_ADD_TO_CART, CONFIG_CUSTOMIZATION, CUSTOMIZATION_THEME_SETTINGS,  MODEL_DYNAMIC_FIELDS, RESULT_MIN_PURCHASE_QTY, RESULT_PRDT_CATEGORY, RESULT_PRDT_INSTANCEID, RESULT_PRDT_QTY, RESULT_PRDT_SKU, RUNTIME_INSTANCE_MAX_QUANTITY, THEME_FILL_BUTTON_BG, THEME_ONLINE_BUTTON, THEME_PLATFORMS, THEME_SECONDARY, THEME_SUBTITLES } from "constants/constants"
import { useAppContext } from "contexts/AppContext"
import CompatioAnalytics from "helpers/CompatioAnalyticsSb3"
import ProductHelper from "helpers/ProductHelper"
// import { AnimatePresence } from "framer-motion"
import CartItem from "./CartItem"
import FillAnchor from "components/Button/FillAnchor"
import { TCartItem } from "types/Cart"
import LinearPreview from "components/LinearPreview"
import AccordionLayout from "components/Accordion/AccordionLayout"
import AccordionHeader from "components/Accordion/AccordionHeader"
import AccordionBody from "components/Accordion/AccordionBody"
import { FaAngleDown } from "react-icons/fa"
import FillButton from "components/Button/FillButton"
import { useRef } from "react"
import { toast } from "react-toastify"
import { toString } from "lodash"
import { useSearchParams } from "react-router-dom"
// import { useLocation, useNavigate } from "react-router-dom"

const Cart = () => {
    const {
        cart,
        getCartTotalPrice,
        runtimeSpec,
        builderSpec,
        handleReset,
        setLoadingToCart,
        setIsOpen,
        theme,
        categoryLabels,
        modelId,
        modelSpec,
        platform,
        setIsCartOpen,
        submitEcommerceCart,
        isPreview,
    } = useAppContext()

     const [URLSearchParams, ] = useSearchParams()
    // const location = useLocation()
    // const navigate = useNavigate()
    
    const cartGroups = cart.reduce((acc: {[x: string]: TCartItem[]}, item) => {
        if(!(categoryLabels[item.catgeory_id] in acc)) acc[categoryLabels[item.catgeory_id]] = []
        acc[categoryLabels[item.catgeory_id]].push(item)
        return acc
    },{})

    return (
        <>
            {
                Utils.isLinear(theme) &&
                <LinearPreview/>
            }
            <div className="sb3-flex sb3-flex-col sb3-flex-grow sb3-min-h-[calc(100%-100px)] lg:sb3-min-h-[250px] sb3-overflow-auto sb3-cart-box sb3-px-2 lg:sb3-px-0">
                <div className="sb3-flex sb3-justify-between sb3-items-center sb3-border-b sb3-py-4 sb3-w-full  sb3-px-1">
                    <h3 className='sb3-font-bold sb3-flex sb3-items-center sb3-space-x-2'>
                        <span className="sb3-px-2 sb3-text-lg sb3-text-black">Your {(isPreview && URLSearchParams.get('modelId') === "c7055620000211f0874f1e1b9b2453d0") ? "Bundle" : "Cart"}</span> 
                        {!Utils.isLinear(theme) && <span><span className="sb3-px-2 sb3-py-1 sb3-flex sb3-bg-[#EDEDED] sb3-text-sm sb3-rounded-full sb3-border-b sb3-cart-count">{cart.length}</span></span>}
                    </h3>

                    <FillButton className="sb3-flex lg:sb3-hidden  sb3-items-center" onClick={() => setIsCartOpen(false)} style={{borderColor: builderSpec?.[BUILDER_CONFIG]?.[CONFIG_CUSTOMIZATION]?.[CUSTOMIZATION_THEME_SETTINGS]?.[THEME_SECONDARY], background: builderSpec?.[BUILDER_CONFIG]?.[CONFIG_CUSTOMIZATION]?.[CUSTOMIZATION_THEME_SETTINGS]?.[THEME_SECONDARY]}}>
                        <span className="sb3-flex sb3-items-center sb3-space-x-2">
                            <FaAngleDown style={{fill: builderSpec?.[BUILDER_CONFIG]?.[CONFIG_CUSTOMIZATION]?.[CUSTOMIZATION_THEME_SETTINGS]?.[THEME_FILL_BUTTON_BG]}} className="!sb3-text-lg sb3-minimize-button-icon"/>
                            <span className="sb3-minimize-button-text" style={{color: builderSpec?.[BUILDER_CONFIG]?.[CONFIG_CUSTOMIZATION]?.[CUSTOMIZATION_THEME_SETTINGS]?.[THEME_FILL_BUTTON_BG]}}>Minimise</span>
                        </span>
                    </FillButton>
                </div>

                {/* Cart Products */}
                <div className={`sb3-flex-grow sb3-overflow-auto ${!Utils.isLinear(theme) && 'sb3-px-3'}`}>
                    {/* <AnimatePresence> */}
                    {
                        !Utils.isLinear(theme) && 
                        cart.map((product, key) => {
                            const isNextActive = (runtimeSpec?.[ProductHelper.getProductInstanceId(product)]?.[MODEL_DYNAMIC_FIELDS][ProductHelper.getProductCategoryId(product)][RUNTIME_INSTANCE_MAX_QUANTITY] ?? 1) <= (Utils.getSelectedProductsFromCategory(cart, product[RESULT_PRDT_CATEGORY], product[RESULT_PRDT_INSTANCEID])?.reduce((acc, product) => acc + product.quantity ,0) ?? 0)
                            
                            return (
                                <CartItem
                                    key = {key}
                                    product={product}
                                    categoryId={product[RESULT_PRDT_CATEGORY]}
                                    instanceId = {product[RESULT_PRDT_INSTANCEID]}
                                    isNextActive={isNextActive}
                                    isQtyActive={(runtimeSpec?.[product[RESULT_PRDT_INSTANCEID]]?.[MODEL_DYNAMIC_FIELDS][product[RESULT_PRDT_CATEGORY]][RUNTIME_INSTANCE_MAX_QUANTITY] ?? 1) > 1 ||
                                        !!(product[RESULT_MIN_PURCHASE_QTY] && product[RESULT_MIN_PURCHASE_QTY] > 1)}
                                />
                            )
                        })
                    }
                    {
                        Utils.isLinear(theme) && 
                        Object.entries(cartGroups)?.map(([groupName, group], key) => {
                            return (
                                <div className="sb3-space-y-1" key={"cartgroup" + key}>
                                    <span className="sb3-text-xs sb3-text-gray-extraDark">{groupName}</span>
                                    {
                                        group.map((product, key) => {
                                            const isNextActive = (runtimeSpec?.[ProductHelper.getProductInstanceId(product)]?.[MODEL_DYNAMIC_FIELDS][ProductHelper.getProductCategoryId(product)][RUNTIME_INSTANCE_MAX_QUANTITY] ?? 1) <= (Utils.getSelectedProductsFromCategory(cart, product[RESULT_PRDT_CATEGORY], product[RESULT_PRDT_INSTANCEID])?.reduce((acc, product) => acc + product.quantity ,0) ?? 0)

                                            return (
                                                <CartItem
                                                    key = {key}
                                                    product={product}
                                                    categoryId={product[RESULT_PRDT_CATEGORY]}
                                                    instanceId = {product[RESULT_PRDT_INSTANCEID]}
                                                    isNextActive={isNextActive}
                                                    isQtyActive={((runtimeSpec?.[product[RESULT_PRDT_INSTANCEID]]?.[MODEL_DYNAMIC_FIELDS][product[RESULT_PRDT_CATEGORY]][RUNTIME_INSTANCE_MAX_QUANTITY] ?? 1) > 1 ||
                                                        !!(product[RESULT_MIN_PURCHASE_QTY] && product[RESULT_MIN_PURCHASE_QTY] > 1))
                                                    }
                                                />
                                        )})
                                    }
                                </div>
                            )
                        })
                    }
                    {/* </AnimatePresence> */}
                </div>
            </div>

            <div className={`sb3-cart-box-disclaimer sb3-space-y-2 sb3-py-2 sb3-border-0 sb3-px-3 sb3-border-t sb3-sticky  first:sb3-bg-gray-200 first:sb3-border-b sm:sb3-shadow-lg sb3-bg-white ${platform === THEME_PLATFORMS.BIG_COMMERCE && "sb3-rounded-lg"}  ${!Utils.isLinear(theme) ? "sb3-bottom-0" : "sb3-bottom-2"}`}
            >
                <div className={`sb3-border-0 ${!Utils.isLinear(theme) ? "sb3-bg-gray-200 sb3-border-b  sb3-px-2" : ""} sb3-font-semibold sb3-py-1.5 sb3-w-full sb3-flex sb3-justify-between sb3-items-center sb3-text-sm sb3-rounded-lg`}
                    style={{backgroundColor: (builderSpec?.[BUILDER_CONFIG]?.[CONFIG_CUSTOMIZATION]?.[CUSTOMIZATION_THEME_SETTINGS]?.[THEME_SECONDARY] && platform === THEME_PLATFORMS.BIG_COMMERCE) ?
                    builderSpec[BUILDER_CONFIG][CONFIG_CUSTOMIZATION][CUSTOMIZATION_THEME_SETTINGS][THEME_SECONDARY] : undefined}}
                >
                    <span>Subtotal:</span> 
                    <span>{Utils.formatPrice(getCartTotalPrice())}</span>
                </div>
                
                {
                    (Utils.checkIfClientWebsite()) &&
                    <>
                        {
                            (Utils.isMagento() || Utils.isBigCommerce()) &&
                            <p className="sb3-text-[11px] sb3-text-gray-extraDark disclimer-text sb3-leading-4"><strong className="sb3-text-gray-extraDark sb3-text-[11px]">Disclaimer:</strong> <span className="!sb3-capitalize sb3-text-[11px] sb3-text-gray-extraDark">{Utils.getCompanyName() ?? window.location.hostname.split('.').slice(-2, -1)[0] ?? (!Utils.isLinear(theme) ? "Compatio" : "Vision")}</span> provides product configurations and recommendations as a guide. The customer is solely responsible for validating the application, operation, maintenance, and compliance of all components. <span className="!sb3-capitalize sb3-text-[11px] sb3-text-gray-extraDark">{Utils.getCompanyName() ?? window.location.hostname.split('.').slice(-2, -1)[0] ?? (!Utils.isLinear(theme) ? "Compatio" : "Vision")}</span> does not guarantee suitability for your specific application and assumes no responsibility for your product design, installation, or operation.</p>
                        }
                        {/* {
                            (Utils.isMagento() || Utils.isBigCommerce()) &&
                                <AccordionLayout initialState={true}>
                                {
                                    (provided) => ( 
                                        <>
                                            <AccordionHeader  {...provided.getHeaderProps()}></AccordionHeader>
                                            <AccordionBody  {...provided.getBodyProps()}>
                                            </AccordionBody>
                                        </>
                                    )
                                }
                                </AccordionLayout>
                        } */}
                        

                        <FillAnchor 
                            id="addoutfittocart"  
                            className="sb3-w-full dc-outfit-button sb3-cursor-pointer sb3-flex sb3-items-center sb3-justify-center sb3-add-outfit-to-cart"
                            productsadded={cart?.map(product => product[RESULT_PRDT_SKU]).join(",")}
                            productsquantity={cart.map(product => product[RESULT_PRDT_QTY]).join(",")}
                            recommendationsource="Configure"
                            data-userid={CompatioAnalytics.getUserId()}
                            onClick={submitEcommerceCart}
                            modelVersion="V1"
                            reelid="-1"
                            modelid={modelId}
                            modelshortname={modelSpec?.project.ISRName}
                            buttonStyle="primary"
                        >ADD TO CART</FillAnchor>
                    </>
                }
            </div>
                

            <a
                id="addoutfittocartclear"
                className="addoutfittocartclear"
                onClick={() => {
                    setLoadingToCart(false)
                    handleReset(false)
                    if(setIsOpen) setIsOpen(false)
                }}
            ></a>
        </>
    )
}

export default Cart