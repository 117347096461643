import { BUILDER_CONFIG, CONFIG_CUSTOMIZATION, CUSTOMIZATION_INSTOCK_STATUS, CUSTOMIZATION_OUT_OF_STOCK_STATUS, THEME_PLATFORMS } from "constants/constants"
import { useAppContext } from "contexts/AppContext"
import ProductHelper from "helpers/ProductHelper"
import { FaCheckCircle } from "react-icons/fa"
import Utils from "Services/Utils"
import { TResultProduct } from "types"

type MyProps = {
    product: TResultProduct
}

const ProductStockStatus = ({product}: MyProps) => {
    const {platform, builderSpec} = useAppContext()
    const stockStatus = ProductHelper.checkIfProductInStockClient(product) === undefined ? ProductHelper.checkIfProductInStock(product) : ProductHelper.checkIfProductInStockClient(product) === "IN_STOCK"

    if(
        platform === THEME_PLATFORMS.BIG_COMMERCE 
    ) {
        if(builderSpec?.[BUILDER_CONFIG][CONFIG_CUSTOMIZATION]?.[CUSTOMIZATION_INSTOCK_STATUS] !== "" && stockStatus) return (
            <span className="sb3-bg-[#B1F064] sb3-px-4 sb3-py-2 sb3-font-bold sb3-text-xs sb3-rounded-lg sb3-price sb3-available-stock">{builderSpec?.[BUILDER_CONFIG][CONFIG_CUSTOMIZATION]?.[CUSTOMIZATION_INSTOCK_STATUS] ?? "IN STOCK"}</span>
        )
        if(builderSpec?.[BUILDER_CONFIG][CONFIG_CUSTOMIZATION]?.[CUSTOMIZATION_OUT_OF_STOCK_STATUS] !== "" && !stockStatus) return (
            <span className="sb3-py-2 sb3-font-bold sb3-text-xs sb3-rounded-lg sb3-price sb3-available">{builderSpec?.[BUILDER_CONFIG][CONFIG_CUSTOMIZATION]?.[CUSTOMIZATION_OUT_OF_STOCK_STATUS] ?? "Available For Back Order"}</span>
        )
        return <></>
    }

    return (
        <>
            {
                stockStatus && !Utils.isMagento && <FaCheckCircle className="sb3-stock-icon sb3-text-green-800 sb3-text-base"/>
            }
            <span className={`sb3-text-sm sb3-price ${stockStatus ? "sb3-available-stock" : "sb3-available"}`}>{
                stockStatus 
                    ? (builderSpec?.[BUILDER_CONFIG][CONFIG_CUSTOMIZATION]?.[CUSTOMIZATION_INSTOCK_STATUS] ?? "IN STOCK") 
                    : builderSpec?.[BUILDER_CONFIG][CONFIG_CUSTOMIZATION]?.[CUSTOMIZATION_OUT_OF_STOCK_STATUS] ?? "Available For Back Order"
            }</span>
        </>
    )
}

export default ProductStockStatus