import * as consts from "constants/constants"
import { useAppContext } from "contexts/AppContext"
import { useEffect, useRef, useState } from "react"
import { FaCheckCircle, FaChevronLeft, FaChevronRight } from "react-icons/fa"
import { TbCircleDotted } from "react-icons/tb"
import { TUI_Page } from "types"

const StepsComponent = () => {
    const {
        theme,
        // uiDefn,
        activePage,
        setPage,
        getInstances,
        builderSpec,
    } = useAppContext()
    let currentIndex = useRef<number|null>(null)

    const containerRef = useRef<HTMLDivElement>(null);
    const [isDown, setIsDown] = useState(false);
    const [startX, setStartX] = useState(0);
    const [scrollLeft, setScrollLeft] = useState(0);

    const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
        setIsDown(true);
        setStartX(e.pageX - (containerRef.current as HTMLDivElement).offsetLeft);
        setScrollLeft((containerRef.current as HTMLDivElement).scrollLeft);
    };

    const handleMouseLeave = () => {
        setIsDown(false);
    };

    const handleMouseUp = () => {
        setIsDown(false);
    };

    const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
        if (!isDown) return;
        e.preventDefault();
        const x = e.pageX - (containerRef.current as HTMLDivElement).offsetLeft;
        const walk = (x - startX); // Adjust scrolling speed here
        (containerRef.current as HTMLDivElement).scrollLeft = scrollLeft - walk;
    };
    
    // if(!uiDefn?.[consts.DEFN_PAGES] || uiDefn?.[consts.DEFN_PAGES].length <= 0) return <></>


    // if its applico theme hide pages if submodel is not instantiated
    const newPages = theme === consts.THEMES.APPLICO ? (builderSpec?.[consts.BUILDER_PAGES].filter((page, key) => {
        let isPageVisible = page[consts.PAGE_FORM_DATA].some((builderSubmodel) => {
            const _instances = getInstances(builderSubmodel[consts.FRMDATA_SMID]) ?? []
            return _instances?.filter(instance => !instance[consts.RUNTIME_INSTANCE_IS_EXCLUDED]).length > 0
        })

        return isPageVisible
    }) ?? []) : (builderSpec?.[consts.BUILDER_PAGES] ?? [])

    const selectNext = () => {
        if(newPages[(currentIndex.current ?? -1) + 1]) 
            setPage(newPages[(currentIndex.current ?? -1) + 1][consts.PAGE_ID])
    }

    const selectPrev = () => {
        if(currentIndex && currentIndex.current !== 0 && newPages[(currentIndex.current ?? -1) - 1]) 
            setPage(newPages[(currentIndex.current ?? -1) - 1][consts.PAGE_ID])
    }

    if(newPages.length === 1) return <></>

    const isNextActive = newPages.slice(-1)[0]?.[consts.PAGE_ID] !== activePage?.[consts.PAGE_ID]
    const isPrevActive = newPages[0]?.[consts.PAGE_ID] !== activePage?.[consts.PAGE_ID]

    let styles: {[x: string]: any} = {}
    if(builderSpec?.[consts.BUILDER_CONFIG]?.[consts.CONFIG_CUSTOMIZATION]?.[consts.CUSTOMIZATION_THEME_SETTINGS]) {
        styles.backgroundColor = builderSpec?.[consts.BUILDER_CONFIG]?.[consts.CONFIG_CUSTOMIZATION]?.[consts.CUSTOMIZATION_THEME_SETTINGS]?.[consts.THEME_FILL_BUTTON_BG]
        styles.borderColor = builderSpec?.[consts.BUILDER_CONFIG]?.[consts.CONFIG_CUSTOMIZATION]?.[consts.CUSTOMIZATION_THEME_SETTINGS]?.[consts.THEME_FILL_BUTTON_BG]
        styles.color = builderSpec?.[consts.BUILDER_CONFIG]?.[consts.CONFIG_CUSTOMIZATION]?.[consts.CUSTOMIZATION_THEME_SETTINGS]?.[consts.THEME_FILL_BUTTON_CLR]
    }
    return (
        <div className="sb3-relative sb3-border-y sb3-border-primary sb3-rounded sb3-overflow-hidden">
        <div 
            className="sb3-flex sb3-overflow-hidden"
            ref={containerRef}
            onMouseDown={handleMouseDown}
            onMouseLeave={handleMouseLeave}
            onMouseUp={handleMouseUp}
            onMouseMove={handleMouseMove}
            >
                {
                    newPages.map((page: TUI_Page, key: number) =>{
                        const active = activePage?.[consts.PAGE_ID] === page[consts.PAGE_ID]
                        if(active) currentIndex.current = key
                        const flagCheck = false

                        return( 
                            <button key={key} 
                                type="button"
                                id={`step-submodel-${page?.[consts.PAGE_ID]}`}
                                onClick={() => setPage(page[consts.PAGE_ID])}
                                className={`sb3-relative sb3-flex-grow sb3-group focus:!sb3-outline-none`}
                                style={{
                                    backgroundColor: active && styles.backgroundColor
                                }}
                                >
                                <span className={`${(active ? "sb3-bg-primary": "sb3-bg-white")} 
                                    sb3-border-r sb3-last:border-r-0 sb3-border-t 
                                    hover:sb3-border-primary hover:sb3-bg-primary 
                                    sb3-border-primary sb3-rotate-45 
                                    sb3-h-[2.121rem] sb3-w-[2.121rem] sb3-aspect-square sb3-absolute sb3-left-full sb3-bottom-1/2 
                                     sb3-z-50 sb3-last:w-0 sb3-last:hidden sb3-rounded-tr sb3-border-solid sb3-border-0`}
                                    style={{
                                        backgroundColor: active && styles.backgroundColor,
                                        borderColor: active && styles.borderColor,
                                        transform: 'translateX(50%) translateY(-50%)'
                                    }}
                                    />
                                {/* Rest of the button content remains the same */}
                            </button>
                        )}
                    )
                }
            </div>
            {/* Control buttons remain the same */}
        </div>
    )
}

export default StepsComponent