import { BUILDER_CONFIG, CONFIG_CUSTOMIZATION, CUSTOMIZATION_THEME_SETTINGS, THEME_PRIMARY_COLOR } from 'constants/constants';
import { useAppContext } from 'contexts/AppContext';
import React from 'react';

interface PricingCardProps {
  budget: number;  // Number of $ symbols (1-5)
  tier: string;
  warranty: string;
  scratchResistance: number;  // Out of 5
  costAfterYear: number;
  specialFeature: string;
  img: string;
  projectId?: string|null
}

const DeckPricingCard: React.FC<PricingCardProps> = ({
  budget,
  tier,
  warranty,
  scratchResistance,
  costAfterYear,
  specialFeature,
  img,
  projectId
}) => {
  const { builderSpec } = useAppContext();

  const primaryColor = builderSpec?.[BUILDER_CONFIG]?.[CONFIG_CUSTOMIZATION]?.[CUSTOMIZATION_THEME_SETTINGS]?.[THEME_PRIMARY_COLOR] || '#1B4D3E';

  const rowClass = `sb3-flex ${projectId === "7a1daedcf9b011ef8c5c87d67c0a6251" && "sb3-flex-col"} sb3-justify-between sb3-items-center sb3-py-4`


  return (
    <div className="sb3-flex sb3-px-3 sb3-pb-5 sb3-flex-col sb3-flex-grow sb3-justify-between sb3-w-full sb3-max-w-full sb3-divide-y sb3-divide-gray-200">
      {/* Budget */}
      <div className="sb3-flex sb3-flex-col sb3-justify-around sb3-items-center sb3-py-4 sb3-border-t">
        <span className="sb3-text-gray-600">
          Budget <span style={{ color: primaryColor }}>{'$'.repeat(budget)}</span>
        </span>
      </div>

      {/* Tier */}
      <div className="sb3-text-white sb3-py-2 sb3-px-6 sb3-text-center sb3-bg-[#A08268]">
        {tier}
      </div>

      {/* Warranty */}
      <div className={rowClass}>
        <span className="sb3-text-gray-600">Warranty</span>
        <span style={{ color: primaryColor }} className="sb3-text-center sb3-font-medium">
          {warranty}
        </span>
      </div>

      {/* Scratch Resistance */}
      <div className={rowClass}>
        <span className="sb3-text-gray-600">Scratch Resistance</span>
        <div className="sb3-flex sb3-gap-1">
          {Array.from({ length: 5 }).map((_, index) => (
            <svg
              key={index}
              className="sb3-w-5 sb3-h-5"
              style={{ color: index < scratchResistance ? primaryColor : '#D1D5DB' }}
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
            </svg>
          ))}
        </div>
      </div>

      {/* Cost after a year */}
      <div className={rowClass}>
        <span className="sb3-text-gray-600">Cost after a year</span>
        <span style={{ color: primaryColor }} className="sb3-font-medium">
          ${costAfterYear.toLocaleString()}
        </span>
      </div>

      {/* Special Features */}
      <div className={rowClass}>
        <span className="sb3-text-gray-600">Special Features</span>
        <div className="sb3-flex sb3-items-center sb3-gap-0.5">
          <span style={{ color: primaryColor }} className="sb3-font-medium">
            {specialFeature}
          </span>
          <img src={img} className="sb3-w-8" alt="" />
        </div>
      </div>

    </div>
  );
};

export default DeckPricingCard;